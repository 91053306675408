<template>
  <div>
    <v-btn class="mr-2" @click.stop="pageBefore" color="primary" :disabled="currentPage <= 1">
      <v-icon
          dark
      >
        chevron_left
      </v-icon></v-btn>
    <v-btn class="ml-2" @click.stop="pageAfter" color="primary" :disabled="currentPage >= totalPages">
      <v-icon
        dark
    >
      chevron_right
    </v-icon></v-btn>
  </div>
</template>

<script>
export default {
  name: "pageSystem",
  data() {
    return {
      currentPage: 1
    };
  },
  props: {
    totalPages: [Number, String],
    value: [Number, String]
  },
  emits: ['input'],
  methods: {
    pageBefore(){
      if(this.currentPage > 1){
        this.currentPage -= 1;
        this.$emit('input', this.currentPage);
      }
    },
    pageAfter(){
      if(this.currentPage < this.totalPages){
        this.currentPage += 1;
        this.$emit('input', this.currentPage);
      }
    },
  },
};
</script>