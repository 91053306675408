<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-list-item>
          <v-list-item-avatar color="white" size="62" v-if="value.logo_url">
            <img alt="" :src="value.logo_url" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">{{
              value.name
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <!-- <v-col cols="12" :md="6">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Description</v-list-item-title>
                        <v-row>
                            <v-col class="grey--text text--darken-2 pt-1">{{ value.description }}</v-col>
                        </v-row>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Téléphone</v-list-item-title>
                        <v-row>
                            <v-col class="grey--text text--darken-2 pt-1">{{ value.phone }}</v-col>
                        </v-row>
                    </v-list-item-content>
                </v-list-item>

            </v-col> -->
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Sites</v-list-item-title>
          <v-row>
            <v-col>
              <ClientSites
                ref="Sites"
                :client="value"
                @closeDialogShow="$emit('closeDialogShow')"
              />
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-row>
  </div>
</template>
<script>
import ClientSites from "../components/ClientSites";
export default {
  components: {
    ClientSites,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    // this.$forceUpdate();
  },
  methods: {
    //
  },
};
</script>