<template>
  <div v-if="editable" class="ftp-form">
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="value.ftp_host"
          label="Host"
          :error="errorFtpBrower"
        ></v-text-field>

        <v-text-field
          v-model="value.ftp_user"
          label="FTP User"
          :error="errorFtpBrower"
          autocomplete="no"
        ></v-text-field>

        <v-text-field
          v-model="value.ftp_pwd"
          label="FTP Password"
          :error="errorFtpBrower"
          :type="showPwd ? 'text' : 'password'"
          :append-icon="showPwd ? 'visibility' : 'visibility_off'"
          @click:append="showPwd = !showPwd"
          autocomplete="no"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="value.ftp_path"
          label="Path"
          @click="dialogBrowseFtp = true"
          :error="errorFtpBrower"
          readonly
          :disabled="!ftpVerified"
        >
          <template v-slot:append-outer>
            <v-btn
              v-if="ftpVerified"
              @click="dialogBrowseFtp = true"
              small
              depressed
              class="secondary"
              >Parcourir</v-btn
            >
            <v-btn
              v-if="!ftpVerified"
              @click="testFtp"
              :loading="ftpLoading"
              small
              depressed
              class="error"
              >Tester la connexion</v-btn
            >
          </template>
        </v-text-field>
        <v-alert type="error" v-if="errorFtpBrower">
          {{ errorMessage }}
        </v-alert>
        <v-dialog v-model="dialogBrowseFtp" persistent scrollable>
          <v-card>
            <v-card-title class="primary white--text">
              FTP ({{ value.ftp_host }})
              <v-spacer></v-spacer>
              <v-btn small text dark @click="dialogBrowseFtp = false"
                >Annuler</v-btn
              >
            </v-card-title>
            <v-card-text>
              {{ value.ftp_path }}
              <v-treeview
                :active.sync="active"
                :open.sync="open"
                :items="files"
                :load-children="fetchDirectory"
                item-key="path"
                activatable
                dense
                @update:active="activeUpdated"
                return-object
              >
                <template v-slot:prepend="{ item, open }">
                  <v-icon v-if="item.children">
                    {{ open ? "folder_open" : "folder" }}
                  </v-icon>
                  <v-icon v-else>insert_drive_file</v-icon>
                </template>
                <template v-slot:label="{ item, active }">
                  <div :class="item.type == 'dir' ? 'cursor-pointer' : ''">
                    {{ item.name }}
                  </div>
                </template>
              </v-treeview>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="dialogBrowseFtp = false"
                >Annuler</v-btn
              >
              <v-btn
                color="primary"
                @click="getActiveFtp"
                :disabled="!active.length"
                >Sélectionner</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Informations FTP</v-list-item-title>
        <v-row>
          <v-col>
            <p>
              Host:
              <span class="grey--text text--darken-2">{{
                value.ftp_host
              }}</span>
            </p>
            <p>
              User:
              <span class="grey--text text--darken-2">{{
                value.ftp_user
              }}</span>
            </p>
            <!-- <p>Password: <span class="grey--text text--darken-2" v-if="value.ftp_pwd">*******</span></p> -->
            <p>
              Path:
              <span class="grey--text text--darken-2">{{
                value.ftp_path
              }}</span>
            </p>
            <p>
              Dernière récup. auto.:
              <span class="grey--text text--darken-2">
                {{
                  value.last_imported_file
                    ? $moment(value.last_imported_file.created_at).format(
                        "DD/MM/YYYY HH:mm"
                      )
                    : ""
                }}
              </span>
            </p>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    editable: { type: Boolean, default: false },
  },
  data() {
    return {
      active: [],
      open: [],
      files: [{ path: "/", name: "/", children: [] }],
      dialogBrowseFtp: false,
      errorFtpBrower: false,
      errorMessage: "",
      ftpVerified: false,
      ftpLoading: false,
      showPwd: false,
    };
  },
  computed: {
    // https://github.com/vuejs/vue/issues/2164#issuecomment-432872718
    computedValue: function () {
      return Object.assign({}, this.value);
    },
  },
  watch: {
    computedValue: {
      handler: function (val, oldVal) {
        if (
          oldVal.ftp_host != val.ftp_host ||
          oldVal.ftp_user != val.ftp_user ||
          oldVal.ftp_pwd != val.ftp_pwd
        ) {
          this.errorMessage = "";
          this.errorFtpBrower = false;
          this.ftpVerified = false;
          this.value.ftp_path = "";
        }
      },
      deep: true,
    },
  },
  methods: {
    async fetchDirectory(dir) {
      return new Promise((resolve) => {
        let datas = {
          site: this.value.id,
          host: this.value.ftp_host,
          user: this.value.ftp_user,
          pwd: this.value.ftp_pwd,
          path: dir.name,
        };
        this.$store
          .dispatch("ftpIndexRequest", datas)
          .then((resp) => {
            resp.forEach(function (f) {
              if (f.type == "dir") {
                f.children = [];
              }
              f.path = dir.path + f.name + "/";
              dir.children.push(f);
            });
            resolve(dir);
          })
          .catch((err) => {
            this.errorMessage = err;
            this.errorFtpBrower = true;
            this.dialogBrowseFtp = false;
            resolve(true);
          });
      });
    },
    testFtp() {
      this.ftpLoading = true;
      let datas = {
        site: this.value.id,
        host: this.value.ftp_host,
        user: this.value.ftp_user,
        pwd: this.value.ftp_pwd,
      };

      this.$store
        .dispatch("ftpTestRequest", datas)
        .then((resp) => {
          // console.log('test resp', resp)
          if (resp.success) {
            this.errorFtpBrower = false;
            this.ftpVerified = true;
            this.ftpLoading = false;
          } else {
            this.errorMessage = resp.error;
            this.errorFtpBrower = true;
            this.ftpVerified = false;
            this.ftpLoading = false;
            this.value.ftp_path = "";
          }
        })
        .catch((err) => {
          this.errorMessage = err;
          this.errorFtpBrower = true;
          this.ftpVerified = false;
          this.ftpLoading = false;
          this.value.ftp_path = "";
        });
    },
    getActiveFtp() {
      if (this.active.length) {
        this.value.ftp_path = this.active[0].path;
      }
      this.dialogBrowseFtp = false;
    },
    activeUpdated() {
      if (this.active.length)
        if (this.active[0].type != "dir") this.active = [];
    },
  },
};
</script>
