<template>
  <l-map
    :center="center"
    :zoom="zoom"
    class="map"
    ref="map"
    @update:zoom="zoomUpdated"
    @update:center="centerUpdated"
  >
    <l-tile-layer :url="url" :attribution="attribution"> </l-tile-layer>
    <l-marker v-for="marker in markers" :key="marker.id" :lat-lng="marker.pos">
      <l-icon ref="icon" :iconAnchor="iconAnchor">
        <img :src="marker.icon" width="32" height="32" />
      </l-icon>
    </l-marker>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LIcon, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

import { Icon } from "leaflet";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

function isFloat(n) {
  return Number(n) == n && n % 1 !== 0;
}

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
  },
  props: {
  },
  data() {
    // let icon = "@/assets/LOGO-MONTROSE.png";
    //latour
    // marker: [45.17557390841813, -0.7450766991135317],
    //montrose
    // let marker = [45.247558410433626, -0.7539209303668802];
    let sites = this.$store.state.profile.user.sites;
    let markers = [];

    for (let site of sites) {
      if (site.description != null || site.gps != null) {
        let gps = site.gps;
        if (gps == null) gps = site.description;
        let split = gps.split(",");
        if (split.length == 2) {
          let marker = { pos: [0, 0] };
          marker.pos[0] = parseFloat(split[0]);
          marker.pos[1] = parseFloat(split[1]);
          if (marker.pos[0] != "NaN" && marker.pos[1] != "NaN") {
            marker.icon = site.logo_url;
            marker.id = site.id;
            markers.push(marker);
          }
        }
      }
    }
    let center = [0, 0];
    if (markers.length > 0) {
      center = markers[0].pos;
    }
    return {
      zoom: 12,

      center,
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution: "&copy; WineryPlatform",

      markers,
      iconAnchor: [0, 0],
    };
  },
  mounted() {
    // document.getElementsByClassName('map')[0].invalidateSize();
    // this.center = [10.17557390841813, -0.7450766991135317];
    // this.$forceUpdate();
  },
  methods: {
    zoomUpdated(zoom) {
      this.zoom = zoom;
      // console.log(this.markers);
    },
    centerUpdated(center) {
      this.center = center;
    },
    updateMap() {
      let map = this.$refs.map;
      map.mapObject.invalidateSize(true);
    },
  },
};
</script>

<style>
.map {
  /* flex: 1; */
  /* position: relative; */
  margin: 0 auto;
  /* overflow: hidden; */
  z-index: 0;
}
.leaflet-control-attribution {
  display: none;
}
</style>
