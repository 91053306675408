<template>
  <v-card max-width="800px" min-height="200px" max-height="10000px">
    <template v-if="separateGraphs">
      <v-container v-for="(xChartData, index) in chartData.datasets" v-bind:key="index">
        <RadarChart ref="radarChartRef" :chartData="{datasets: chartData.datasets.filter((dataset, dataIndex) => dataIndex === index), labels: chartData.labels.filter((label, labIndex) => labIndex === index)[0]}" :options="chartOptions" />
      </v-container>
    </template>
    <template v-else>
      <RadarChart ref="radarChartRef" :chartData="chartData" :options="chartOptions" />
    </template>
  </v-card>
</template>

<script lang="ts">
import { RadarChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
import "chartjs-plugin-dragdata";
import {defineComponent, onMounted, ref} from "@vue/composition-api";

Chart.register(...registerables);

export default ({
  name: 'Home',
  components: { RadarChart },
  props: {
    chartData: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      required: true
    },
    separateGraphs: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {

    let customAxe = {};

    const radarChartRef = ref();

    const chartData = props.chartData;
    const chartOptions = props.chartOptions;

    return { chartData, chartOptions, customAxe };
  },
});
</script>