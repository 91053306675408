<template>
  <div class="users">
    <Navigation />
    <v-main>
      <v-container fluid fill-height>
        <v-layout v-resize="onResize">
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-row class="pb-4 text-center" align="center">
                  <v-col cols="12" md="auto">
                    <h2 class="ml-2 headline">Liste des utilisateurs</h2>
                  </v-col>

                  <v-spacer></v-spacer>

                  <v-col cols="12" md="auto">
                    <v-text-field
                      v-model="search"
                      clearable
                      hide-details
                      solo-inverted
                      flat
                      prepend-inner-icon="search"
                      label="Rechercher"
                      @input="refreshPagination"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>

                  <v-col cols="12" md="auto">
                    <!-- CREATE/EDIT DIALOG -->
                    <v-dialog
                      v-model="dialogEdit"
                      max-width="1400px"
                      persistent
                      scrollable
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          color="primary"
                          v-on="on"
                          @click.stop="createItem"
                          >Nouvel utilisateur</v-btn
                        >
                      </template>
                      <v-card>
                        <v-card-title class="primary white--text">
                          <template v-if="action == 'create'"
                            >Création</template
                          >
                          <template v-if="action == 'update'"
                            >Modification</template
                          >
                          d'un utilisateur
                          <v-spacer></v-spacer>
                          <v-btn small text dark @click="dialogEdit = false"
                            >Annuler</v-btn
                          >
                        </v-card-title>
                        <v-card-text>
                          <UserForm
                            ref="UserForm"
                            :showEditClients="true"
                            :showEditSites="true"
                            :showEditUser="true"
                            v-model="editedUser"
                            :action="action"
                            @savedForm="handleSavedUserForm"
                            @canceledForm="handleCanceledUserForm"
                          />
                        </v-card-text>
                      </v-card>
                    </v-dialog>

                    <!-- SHOW DIALOG -->
                    <v-dialog v-model="dialogShow" ref="dialog" scrollable>
                      <v-card>
                        <v-card-title class="primary py-0">
                          <v-row class="subtitle-2 align-center white--text">
                            <v-col
                              cols="auto"
                              @click="dialogShow = false"
                              class="cursor-pointer"
                              >Liste des utilisateurs</v-col
                            >
                            >
                            <v-col cols="auto">{{
                              shownUser.complete_name
                            }}</v-col>
                            <v-spacer></v-spacer>
                            <v-btn small text dark @click="dialogShow = false"
                              >Fermer</v-btn
                            >
                          </v-row>
                        </v-card-title>
                        <v-card-text>
                          <User v-model="shownUser" ref="Utilisateur" />
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="secondary"
                            text
                            @click="dialogShow = false"
                            >Fermer</v-btn
                          >
                          <v-btn color="primary" @click="editItem(shownUser)"
                            >Modifier</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>

                <v-data-table
                  :items="users"
                  :options.sync="pagination"
                  :items-per-page="rowsPerPageItems"
                  :server-items-length="total"
                  :loading="loading"
                  :headers="headers"
                  :class="{ mobile: isMobile }"
                  :footer-props="{
                    itemsPerPageText: 'Ligne par page',
                  }"
                >
                  <template v-slot:item="props">
                    <tr @click.stop="showItem(props.item)">
                      <td data-label="Nom">{{ props.item.lastname }}</td>
                      <td data-label="Prénom">{{ props.item.firstname }}</td>
                      <td data-label="Email">{{ props.item.email }}</td>
                      <td data-label="Rôle">{{ props.item.role_name }}</td>
                      <td class="text-right">
                        <v-icon
                          small
                          class="mr-2"
                          @click.stop="showItem(props.item)"
                          >remove_red_eye</v-icon
                        >
                        <v-icon
                          small
                          class="mr-2"
                          @click.stop="editItem(props.item)"
                          >edit</v-icon
                        >
                        <v-icon small @click.stop="deleteItem(props.item)"
                          >delete</v-icon
                        >
                      </td>
                    </tr>
                  </template>
                  <template v-slot:no-data> Aucun utilisateur </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import UserForm from "../components/UserForm";
import User from "../components/User";

export default {
  components: {
    UserForm,
    User,
  },
  data() {
    return {
      ready: false,
      users: [],
      allUsers: undefined,
      action: "create",
      editedUser: {},
      shownUser: {},
      defaultUser: {
        lastname: "",
        firstname: "",
        email: "",
        role: "",
        sites: [],
      },
      pagination: {
        page: 1,
      },
      search: "",
      total: 0,
      rowsPerPageItems: 10,
      headers: [
        {
          text: "Nom",
          align: "left",
          value: "lastname",
        },
        {
          text: "Prénom",
          align: "left",
          value: "firstname",
        },
        {
          text: "Email",
          align: "left",
          value: "email",
        },
        {
          text: "Rôle",
          align: "left",
          value: "role",
        },
        {
          text: "",
          value: "",
          sortable: false,
        },
      ],
      dialogEdit: false,
      dialogShow: false,
      isMobile: false,
      loading: false,
    };
  },
  mounted() {
    //
  },
  methods: {
    onResize() {
      this.isMobile = this.$vuetify.breakpoint.smAndDown;
    },
    getUsers(queries) {
      if (!this.allUsers) {
        this.$store
          .dispatch("usersRequest", queries)
          .then((result) => {
            this.allUsers = result.data;
            this.total = this.allUsers.length;
            this.users = result.data;
            const start =
              (this.pagination.page - 1) * this.pagination.itemsPerPage;
            let end = Math.min(
              this.users.length,
              this.pagination.page * this.pagination.itemsPerPage
            );
            if (this.pagination.itemsPerPage == -1) {
              end = this.users.length + 1;
            }
            this.users = this.users.slice(start, end);

            this.loading = false;
            if (!this.ready)
              this.$checkHistory("user", (user) => this.showItem(user));
            this.ready = true;
          })
          .catch(() => {
            //
          });
      } else {
        if (this.search) {
          this.users = this.allUsers.filter((user) =>
            user.complete_name.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '').includes(this.search.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, ''))
          );
        } else {
          this.users = this.allUsers;
        }
        this.total = this.users.length;
        const start = (this.pagination.page - 1) * this.pagination.itemsPerPage;
        let end = Math.min(
          this.users.length,
          this.pagination.page * this.pagination.itemsPerPage
        );
        if (this.pagination.itemsPerPage == -1) {
          end = this.users.length + 1;
        }
        this.users = this.users.slice(start, end);

        this.loading = false;
        if (!this.ready)
          this.$checkHistory("user", (user) => this.showItem(user));
        this.ready = true;
      }
    },
    handleSavedUserForm(user) {
      this.allUsers = undefined;
      this.refreshPagination();
      if (this.$store.state.profile.user.id == user.id) {
        this.$store.dispatch("profileRequest").then(() => {
          // nothing to do...
        });
      }
      this.dialogEdit = false;
      this.dialogShow = false;
    },
    handleCanceledUserForm() {
      this.dialogEdit = false;
    },
    showItem(user) {
      this.shownUser = user;
      this.dialogShow = true;
    },
    createItem() {
      this.action = "create";
      this.editedUser = Object.assign({}, this.defaultUser);
      this.dialogEdit = true;
    },
    editItem(user) {
      this.action = "update";
      this.editedUser = Object.assign({}, user);
      this.dialogEdit = true;
    },
    deleteItem(user) {
      if (confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?")) {
        this.$store
          .dispatch("userDeleteRequest", { id: user.id })
          .then((result) => {
            this.$store.commit("setSnack", {
              type: "success",
              msg: "Utilisateur supprimé !",
            });
            this.allUsers = undefined;
            this.refreshPagination();
          })
          .catch((err) => {
            this.$store.commit("setSnack", {
              type: "red",
              msg: err.response.data,
            });
          });
      }
    },
    refreshPagination() {
      const queries = {
        // page: this.pagination.page,
        // per_page: this.pagination.itemsPerPage,
        sort: this.pagination.sortBy,
        direction: this.pagination.sortDesc[0] ? "desc" : "asc",
        // search: this.search,
      };

      this.loading = true;
      this.getUsers(queries);
    },
  },
  watch: {
    pagination: {
      handler() {
        this.refreshPagination();
      },
      deep: true,
    },
    dialogShow() {
      if (this.dialogShow) this.$clicked_user = this.shownUser;
      else this.$clicked_user = null;

      if (this.dialogShow) this.$resetDialogScroll();
    },
    dialogEdit() {
      if (this.dialogEdit) this.$resetDialogScroll();
    },
  },
};
</script>
