import Vue from 'vue'

const state = {
    // 
}

const getters = {
    // 
}

const actions = {
    ftpIndexRequest: async ({commit, dispatch}, queries) => {
        return new Promise((resolve, reject) => {
            let url = '/api/sites/'+queries.site+'/ftp/index'
            Vue.axios.post(url, queries)
                .then((result) => {
                    resolve(result.data)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    ftpTestRequest: async ({commit, dispatch}, queries) => {
        return new Promise((resolve, reject) => {
            let url = '/api/sites/'+queries.site+'/ftp/test'
            Vue.axios.post(url, queries)
                .then((result) => {
                    // console.log('result', result)
                    resolve(result.data)
                })
                .catch((error) => {
                    // console.log('ftp test error', error)
                    if (error.response) {
                        reject(error.response.data.message)
                    }
                    reject('Timeout')
                })
        })
    },
}


const mutations = {
    // 
}

export default {
    state,
    getters,
    actions,
    mutations,
}