<template>
    <v-dialog v-model="isVisible" persistent max-width="1200px">
        <v-card>
            <v-card-title class="justify-center">
                <span>{{iconText}}</span>
            </v-card-title>

            <v-card-text class="justify-center" style="display: flex;">
                <v-icon :color="iconColor" size="72">{{ icon }}</v-icon>
            </v-card-text>

            <v-card-actions class="justify-center" style="display: flex;">
                <v-spacer></v-spacer>
                <v-btn style="padding: 10px;" color="primary" @click="hide" large>Voir plus de détails</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        isWarning: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data() {
        return {
            isVisible: true, // This can be dynamically set
        };
    },
    computed: {
        icon() {
            return this.isWarning ? 'warning' : 'check_circle';
        },
        iconColor() {
            return this.isWarning ? 'red darken-1' : 'green darken-1';
        },
        iconText() {
            return this.isWarning ? 'Attention!' : 'Tout est parfait!';
        }
    },
    methods: {
        hide() {
            this.isVisible = false;
        }
    }
}
</script>

<style scoped>
/* Additional custom styles if needed */
</style>
