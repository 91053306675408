<template>
  <div>
    <div ref="activatorWrapper">
      <slot name="activator"></slot>
    </div>
    <div v-if="isVisible">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    toggle() {
      this.isVisible = !this.isVisible;
    },
  },
  mounted() {
    this.$refs.activatorWrapper.addEventListener('click', this.toggle);
  },
  beforeDestroy() {
    if (this.$refs.activatorWrapper) {
      this.$refs.activatorWrapper.removeEventListener('click', this.toggle);
    }
  }
};
</script>
