<template>
  <v-flex xs12 sm6 offset-sm3>
    <v-card>
      <v-card-text>
        <v-list-item>
          <v-list-item-avatar color="white">
            <img alt="logo" src="@/assets/logo.png">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">Modification du mot de passe</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-form
          ref="form"
          @keyup.native.enter="edit"
        >
          <v-text-field
            v-model="email"
            label="E-mail"
            :rules="[rules.required]"
          ></v-text-field>

          <v-text-field
            v-model="password"
            label="Password"
            :rules="[rules.required, rules.minlength]"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            hint="8 caractères min."
            counter
          ></v-text-field>

          <v-text-field
            v-model="password_confirmation"
            label="Confirm password"
            :rules="[rules.required]"
            :append-icon="showPasswordConfirm ? 'visibility' : 'visibility_off'"
            :type="showPasswordConfirm ? 'text' : 'password'"
            @click:append="showPasswordConfirm = !showPasswordConfirm"
          ></v-text-field>

          <v-btn
            block
            depressed
            color="primary"
            @click="edit"
            :disabled="loading" 
            :loading="loading"
          >Enregistrer</v-btn>

        </v-form>

        <v-btn
          block
          text
          small
          color="secondary"
          :to="{ name: 'login' }"
          class="mt-4"
        >Se connecter</v-btn>

      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
    export default {
        data(){
            return {
                email: '',
                password: '',
                password_confirmation: '',
                showPassword: false,
                showPasswordConfirm: false,
                rules: {
                    required: value => !!value || 'Champ requis',
                    minlength: value => value.length >= 8 || '8 caractères min.',
                },
                loading: false,
            }
        },
        methods: {
            edit: function () {
              if (this.$refs.form.validate()) {
                this.loading = true
                const credentials = {
                  email: this.email,
                  token: this.$route.params.token,
                  password: this.password,
                  password_confirmation: this.password_confirmation,
                }

                this.$store.dispatch('editPassword', credentials)
                    .then(() => {
                        this.loading = false
                        this.$router.push('/login')
                    })
                    .catch((err) => {
                        this.loading = false;
                    })
              }
            }
        }
    }
</script>
