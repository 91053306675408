<template>
  <div style="transform: translate(0px,0px); width: 1062px; height: 753px;" @mousemove.capture="(ev) => {if(planMoving) parsePlanEV(ev)}" @mousedown.capture="planMoving = true;" @mouseup.capture="planMoving = false;" @mouseleave="planMoving = false;" @touchmove.capture="(ev) => {if(planMoving) parsePlanEV(ev)}" @touchstart.capture="planMoving = true;" @touchstop.capture="planMoving = false;" @touchleave="planMoving = false;">
    <img src="@/assets/PlanLatourSecurity.jpg" style="aspect-ratio: auto; pointer-events: none; user-select: none; -webkit-user-select: none;" :style="{width: $isMobile() ? '200%' : '100%'}" alt="">
    <div v-for="(sensor,index) in arrayedSensor" :key="index" class="align-center">
      <div v-for="(co2s,idx) in CO2Sites[sensor.id]" :key="idx + co2s.name" :style="{ left: calculatePosition(parseFloat(co2s.position.x), 'width') + 'px', top: calculatePosition(parseFloat(co2s.position.y), 'height') + 'px', position: 'absolute', transform: 'translate(-25%,-15%)', 'pointer-events': 'none', display: sensor.use_securityCO2_plan === 1 ? 'initial': 'none'}">
        <v-icon size="46" v-if="co2s.disabled" color="orange" style="background: black; border-radius: 50%;">report_off</v-icon>
        <v-icon size="46" v-else-if="!co2s.disabled && co2s.value >= co2s.max" class="blink" color="red" style="background: black; border-radius: 50%;">report</v-icon>
        <v-icon size="46" v-else color="green" style="background: black; border-radius: 50%;">verified</v-icon>
        <p style="background: black; color: white; transform: translate(-27.5%,25%); width: 100px; text-align: center; font-size: 12px;">{{ sensor.name }} ({{ co2s.name }})</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageSize: { width: 1062, height: 753 },
      planMoving: false,
    };
  },
  props: {
    CO2Sites: {
      type: Array,
      required: true
    },
    planUrl: {
      type: String,
      required: false
    },
    arrayedSensor: {
      type: Array,
      required: true
    },
    movingSize: {
      type: Number,
      required: true
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm
    }
  },
  mounted() {
    this.$nextTick(function () {
      //return nothing
    });
    console.log(this.CO2Sites);
  },
  methods: {
    mapRange(value, inMin, inMax, outMin, outMax) {
      return ((value - inMin) * (outMax - outMin) / (inMax - inMin)) + outMin;
    },
    clamp(value, min, max){
      return (value > max ? max : (value < min ? min : value));
    },
    calculatePosition(coordinate, dimension) {
      return this.mapRange(coordinate, 0, (dimension === "width" ? 1062 : 753), 0, (this.$isMobile() ? this.imageSize[dimension]*2 : this.imageSize[dimension]));
    },
    splitPreserveOrder(inputString, delimiter) {
      const result = [];
      let currentSubstring = '';
      for (let i = 0; i < inputString.length; i++) {
        if (inputString[i] === delimiter) {
          if (currentSubstring !== '') {
            result.push(currentSubstring);
            currentSubstring = '';
          }
        } else {
          currentSubstring += inputString[i];
        }
      }
      if (currentSubstring !== '') {
        result.push(currentSubstring);
      }
      return result;
    },
    parsePlanEV(ev){

      const regexX = /\(([^)]+)px,/;
      const regexY = /,(.*?)px\)/;

      let newX = (parseFloat(ev.srcElement.style.transform.match(regexX)[1]) + ev.movementX);
      let newY = (parseFloat(ev.srcElement.style.transform.match(regexY)[1]) + ev.movementY);

      ev.srcElement.style.transform = "translate(" + this.clamp(newX, ((this.movingSize > 0 ? ((this.$isMobile() ? this.imageSize.width*2 : this.imageSize.width) * -1) + this.movingSize : 0)), 0) + "px," + this.clamp(newY, ((this.movingSize > 0 ? ((this.$isMobile() ? this.imageSize.height*2 : this.imageSize.height) * -1) + this.movingSize : 0)), 0) + "px)";
    },
  }
};
</script>