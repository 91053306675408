<template>
  <v-form v-on:submit.prevent="onSubmit" ref="form" class="mb-5">
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="value.lastname"
          label="Nom"
          :rules="[rules.required]"
        ></v-text-field>

        <v-text-field
          v-model="value.firstname"
          label="Prénom"
          :rules="[rules.required]"
        ></v-text-field>

        <v-text-field
          v-model="value.email"
          label="E-mail"
          :rules="[rules.required]"
        ></v-text-field>

        <v-text-field v-model="value.phone" label="Téléphone"></v-text-field>

        <v-select
          v-model="value.homeType"
          label="Sélectionner le type de page d'accueil"
          :items="[
              {value: 'S1', text: 'Accueil par défaut'},
              {value: 'S2', text: 'Module de dégustations'},
              {value: 'S3', text: 'Sécurité CO2'}
          ]"
	>

        </v-select>

        <v-combobox
                v-model="value.coworkers_phones"
                :items="items"
                label="Numéros de téléphone des collègues"
                multiple
                chips
                clearable
                persistent-hint
                deletable-chips
                hint="Saisir un numéro de téléphone (sans +33)"
        ></v-combobox>

        <v-switch
          v-model="value.optin_mail"
          label="Autorise les notif. mail"
          :true-value="parseInt(1)"
          :false-value="parseInt(0)"
          flat
          inset
        ></v-switch>

        <v-switch
          v-model="value.optin_sms"
          label="Autorise les notif. sms"
          :true-value="parseInt(1)"
          :false-value="parseInt(0)"
          flat
          inset
        ></v-switch>
      </v-col>

      <v-col cols="6">
        <v-select
          v-if="$acl.check('isAdmin')"
          v-model="value.role"
          label="Rôle"
          :items="[
            { value: 'user', text: 'Utilisateur' },
            { value: 'admin', text: 'Administrateur' },
            { value: 'user_super', text: 'Utilisateur multi-client' },
            { value: 'user_admin', text: 'Utilisateur administrateur' },
            { value: 'user_conf', text: 'Utilisateur configurateur' },
          ]"
          :rules="[rules.required]"
        ></v-select>

        <div v-if="action == 'create'">
          <v-text-field
            v-model="password"
            :append-icon="show_password ? 'visibility' : 'visibility_off'"
            label="Mot de passe"
            counter
            @click:append="toggleShowPassword"
            :type="show_password ? 'text' : 'password'"
            hint="8 caractères min."
          ></v-text-field>

          <v-text-field
            v-model="confirm"
            :append-icon="show_confirm ? 'visibility' : 'visibility_off'"
            label="Confirmer le mot de passe"
            counter
            @click:append="toggleShowConfirm"
            :type="show_confirm ? 'text' : 'password'"
          ></v-text-field>
        </div>

        <div v-if="action == 'update'">
          <v-text-field
            v-model="old_password"
            :append-icon="show_old_password ? 'visibility' : 'visibility_off'"
            label="Ancien mot de passe"
            counter
            @click:append="toggleShowOldPassword"
            :type="show_old_password ? 'text' : 'password'"
          ></v-text-field>

          <v-text-field
            v-model="password"
            :append-icon="show_password ? 'visibility' : 'visibility_off'"
            label="Mot de passe"
            counter
            @click:append="toggleShowPassword"
            :type="show_password ? 'text' : 'password'"
            hint="8 caractères min."
          ></v-text-field>

          <v-text-field
            v-model="confirm"
            :append-icon="show_confirm ? 'visibility' : 'visibility_off'"
            label="Confirmer le mot de passe"
            counter
            @click:append="toggleShowConfirm"
            :type="show_confirm ? 'text' : 'password'"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="show_clients && $acl.check('isAdmin')">
      <v-col>
        <v-autocomplete
          v-model="value.clients"
          :items="allClients"
          item-text="name"
          item-value="id"
          label="Clients"
          multiple
          chips
          deletable-chips
          return-object
          hint="Veuillez sélectionner les clients auquel l'utilisateur a accès"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <!-- <v-row v-if="show_clients && $acl.check('isAdmin')">
      <v-col>
        <v-autocomplete
            v-model="value.defaultclient"
            :items="value.clients"
            item-text="name"
            item-value="id"
            label="Client par défaut"
            return-object
            hint="Veuillez sélectionner le client par défaut de l'utilisateur"
        ></v-autocomplete>
      </v-col>
    </v-row> -->

    <v-row v-if="show_sites && $acl.check('isAdmin')">
      <v-col>
        <v-autocomplete
          v-model="value.sitation"
          :items="allSites"
          item-text="complete_name"
          item-value="id"
          label="Sites"
          multiple
          chips
          deletable-chips
          return-object
          hint="Veuillez sélectionner les sites auquel l'utilisateur a accès"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row v-if="show_users && $acl.check('isAdmin')">
      <v-col>
        <v-autocomplete
          v-model="value.users"
          :items="allUsers"
          item-text="complete_name"
          item-value="id"
          label="Users"
          multiple
          chips
          deletable-chips
          return-object
          hint="Veuillez sélectionner les utilisteurs auquel l'utilisateur a accès"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <div
      class="px-5 py-3 absolute--position left right bottom white text-right"
    >
      <v-btn @click.stop="cancel" text color="secondary">Annuler</v-btn>
      <v-btn
        color="primary"
        type="submit"
        :disabled="loading"
        :loading="loading"
        >Sauvegarder</v-btn
      >
    </div>
  </v-form>
</template>

<script>
export default {
  props: {
    value: Object,
    action: {
      type: String,
      default: "update",
    },
    showEditSites: {
      type: Boolean,
      default: false,
    },
    showEditClients: {
      type: Boolean,
      default: false,
    },
    showEditUsers: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let show_sites = this.showEditSites;
    let show_clients = this.showEditClients;
    let show_users = this.showEditUsers;
    if (!this.value.role) this.value.role = "user";
    return {
      old_password: "",
      password: "",
      confirm: "",
      show_old_password: false,
      show_password: false,
      show_confirm: false,
      show_sites,
      show_clients,
      show_users,
      updatePassword: false,
      loading: false,
      rules: {
        required: (value) => !!value || "Champ requis",
        minlength: (value) => value.length >= 8 || "8 caractères min.",
      },
      allSites: [],
      allClients: [],
      allUsers: [],
    };
  },
  computed: {
    //
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.updateForm();
        this.$refs.form.resetValidation();
      },
    },
  },
  updated() {
    if (typeof this.value.coworkers_phones === "string") {
      this.value.coworkers_phones = JSON.parse(this.value.coworkers_phones);
    }
  },
  mounted() {
    // if (this.canChooseSite()) this.fetchClients();
    this.fetchClients();
    if (this.canChooseSite()) {
      this.fetchSites();
    }
    if (this.show_users) {
      this.fetchUsers();
    }
  },
  methods: {
    canChooseSite() {
      return this.value.clients != undefined && this.value.clients.length > 0;
    },
    updateForm() {
      let canChooseSite = this.canChooseSite();
      if (
        this.value.role === "user" ||
        this.value.role === "user_conf" ||
        this.value.role === "user_super"
      ) {
        this.show_sites = canChooseSite;
        this.show_clients = true;
        this.show_users = false;
      } else if (
        this.value.role === "admin" ||
        this.value.role === "user_admin"
      ) {
        this.show_sites = canChooseSite;
        this.show_clients = true;
        this.show_users = true;
      }
      if (canChooseSite) {
        this.fetchSites();
      }
      if (this.show_users) {
        this.fetchUsers();
      }
    },
    toggleShowConfirm() {
      this.show_confirm = !this.show_confirm;
    },
    toggleShowPassword() {
      this.show_password = !this.show_password;
    },
    toggleShowOldPassword() {
      this.show_old_password = !this.show_old_password;
    },

    fetchClients() {
      this.$store
        .dispatch("clientsRequest", {})
        .then((result) => {
          this.allClients = result.data;
        })
        .catch((err) => {});
    },
    fetchSites() {
      this.$store
        .dispatch("sitesRequest", {})
        .then((result) => {
          this.allSites = result.data.filter((site) => {
            return this.value.clients.map((c) => c.id).includes(site.client_id);
          });
        })
        .catch((err) => {});
    },
    fetchUsers() {
      this.$store
        .dispatch("usersRequest", {})
        .then((result) => {
          this.allUsers = result.data;
        })
        .catch((err) => {});
    },
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        if (this.old_password) {
          this.value.old_password = this.old_password;
        }

        if (this.password && this.confirm) {
          this.value.password = this.password;
          this.value.password_confirmation = this.confirm;
        }

        let data = Object.assign({}, this.value);
        if(this.value.defaultclient)
          data.defaultclient = this.value.defaultclient;
        if (this.value.clients)
          data.clients = this.value.clients.map((c) => c.id);
        if (this.value.sitation) {
          this.value.sites = this.value.sitation.filter((site) => {
            return this.value.clients.map((c) => c.id).includes(site.client_id);
          });
          data.sites = this.value.sitation.map((s) => s.id);
        }
        if (this.value.users) data.users = this.value.users.map((u) => u.id);

        let request = "userCreateRequest";
        let queries = data;

        if (this.action === "update") {
          request = "userEditRequest";
          queries = { id: this.value.id, datas: data };
        }

        this.$store
          .dispatch(request, queries)
          .then((user) => {
            this.$refs.form.resetValidation();
            this.loading = false;
            this.$store.commit("setSnack", {
              type: "success",
              msg: "Utilisateur enregistré !",
            });
            this.$emit("savedForm", user);
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.status != 403)
              this.$store.commit("setSnack", {
                type: "red",
                msg: err.response.data,
              });
          });
      }
    },
    cancel() {
      this.$refs.form.reset();
      this.$emit("canceledForm");
    },
  },
};
</script>
