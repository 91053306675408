<template>
  <v-form v-on:submit.prevent="submit" ref="form">
    <v-card>
      <v-card-title>
        <span class="headline">Créer / modifier une visualisation</span>
      </v-card-title>
      <v-card-text>
        <v-col>
          <v-row>
            <v-text-field label="Intitulé" v-model="visualization.name"></v-text-field>
          </v-row>
          <VisualizationAxes
            :axes="visualization.axes.axes"
            :mesures="availableMesures"
            :childIdsToName="childIdsToName"
            :site="site"
            @added="handleAddedAxe"
          />
          <v-row>
            <v-select
              label="Evénements"
              :items="this.events"
              v-model="visualization.events"
              item-value="id"
              item-text="name"
              multiple
              chips
            ></v-select>
          </v-row>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-btn depressed @click="cancel">Annuler</v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" @click="submit">Enregistrer</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import VisualizationAxes from "@/components/VisualizationAxes";
import { getSitePromise } from "../vuex/modules/site";

export default {
  components: {
    VisualizationAxes,
  },
  props: {
    value: Object,
    site: Object,
    clientSites: Array,
    childIdsToName: Object,
    //action: String
  },
  data() {
    let events = [];
    if (this.site.events && this.site.events.names) {
      events = this.site.events.names;
    }
    let availableMesures = [];
    // let axes = [];
    return {
      events,
      availableMesures,
      // axes,
    };
  },
  computed: {
    visualization() {
      return this.value;
    },
  },
  watch: {},
  async mounted() {
    // console.log("VisualizationForm", this.visualization);
    this.availableMesures = await this.getAvailableMesures();
  },
  methods: {
    async getChildSite(child_id) {
      let childSite;
      let filteredSites = this.clientSites.filter((s) => s.id == child_id);
      if (filteredSites.length == 1) {
        childSite = filteredSites[0];
      } else if (filteredSites == 0 && this.$store.state.profile.user.role == "admin") {
        childSite = await getSitePromise(this.$store, child_id);
      } else {
        console.log("Error : filter give ", filteredSites);
      }
      return childSite;
    },
    
    async getAvailableMesures() {
      let availableMesures = [];
      if (this.site.sensors) {
        availableMesures = [...this.site.sensors];
        if (this.childIdsToName) {
          for (let child of Object.keys(this.childIdsToName)) {
            let childSite = await this.getChildSite(child);
            availableMesures = [...availableMesures, ...childSite.sensors];
          }
        }
      }
      return availableMesures;
    },

    showErr(err) {
      this.$store.commit("setSnack", {
        type: "red",
        err,
      });
    },
    handleAddedAxe(axe) {
      // this.value.sensors.push(sensor);
      this.$forceUpdate();
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    save() {
      if (this.visualization.id) {
        this.$store
          .dispatch("visualizationEditRequest", {
            id: this.visualization.id,
            datas: this.visualization,
          })
          .then(() => {
            this.$store.commit("setSnack", {
              type: "success",
              msg: "Visualisation correctement enregistrée",
            });
            this.$refs.form.reset();
            let index = this.site.visualizations.indexOf(this.visualization);
            if (index > -1 && index < this.site.visualizations.length) {
              this.site.visualizations[index] = this.visualization;
            } else {
              console.log("ERROR : visu not found !!?");
            }
            this.$emit("updated", this.visualization);
          })
          .catch((err) => {
            console.log("err", err);
            this.$store.commit("setSnack", { type: "error", msg: err });
          });
      } else {
        this.$store
          .dispatch("visualizationCreateRequest", {
            ...this.visualization,
            site_id: this.site.id,
          })
          .then(() => {
            this.$store.commit("setSnack", {
              type: "success",
              msg: "Visualisation correctement enregistrée",
            });
            this.$emit("created", this.visualization);
          })
          .catch((err) => {
            console.log("err", err);
            this.$store.commit("setSnack", { type: "error", msg: err });
          });
      }
    },
    cancel() {
      // this.$refs.form.reset();
      this.$emit("cancel");
    },
  },
};
</script>
