<template>
  <div>
    <Navigation/>
    <v-main>
      <v-container fluid fill-height>
        <v-layout>
          <v-col cols="12">
            <v-card v-if="ready">
              <v-card-title class="primary py-0">
                <BreadcrumbDashboard :current="site.name" />
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="8" :xs="12" :sm="8" :md="8" :lg="6">
                    <v-list-item>
                      <v-list-item-avatar
                          color="white"
                          size="62"
                          v-if="site.logo_url"
                      >
                        <img alt="" :src="site.logo_url" />
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title class="headline">{{
                            site.complete_name
                          }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="6" :xs="12">
                    <v-list-item>
                      <v-btn
                          color="primary"
                          depressed
                          class="align-self-center ml-4 dashboard-btn-mesure"
                          @click.stop="addInputEventDataModal = true"
                      >
                        Saisir une mesure
                      </v-btn>
                      <v-tooltip bottom>
                        <template v-if="!$isMobile() && (userrole === 'admin' || userrole === 'user_admin')"  v-slot:activator="{ on, attrs }">
                          <v-icon
                              color="primary"
                              depressed
                              class="align-self-center ml-4 dashboard-btn"
                              @click.stop="importValues"
                              size="36"
                              v-bind="attrs"
                              v-on="on"
                          >
                            file_download
                          </v-icon>
                        </template>
                        <span>Importer des mesures</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-if="!$isMobile() && (userrole === 'admin' || userrole === 'user_admin')"  v-slot:activator="{ on, attrs }">
                          <v-icon
                              color="primary"
                              depressed
                              class="align-self-center ml-4 dashboard-btn"
                              @click.stop="importEvent"
                              size="36"
                              v-bind="attrs"
                              v-on="on"
                          >
                            file_download
                            event
                          </v-icon>
                        </template>
                        <span>Importer des évènements</span>
                      </v-tooltip>

                      <template v-if="!$isMobile()">
                        <ExportExcelButton
                            :site="site"
                            :clientSites="clientSites"
                            :childIdsToName="childIdsToName"
                        />
                      </template>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-card-text>
              <!-- <br /> -->
              <v-dialog v-model="addInputEventDataModal" max-width="500px">
                <InputEventDataForm
                  :site="site"
                  :key="inputEventModalKey"
                  @saved="onSaveInputEventData"
                  @cancel="onCancelInputEventData"
                />
              </v-dialog>
              <v-dialog v-model="addImportDataModal" max-width="500px">
                <ImportDataForm
                  :site="site"
                  :key="ImportDataModalKey"
                  @saved="onSaveImportData"
                  @cancel="onCancelImportData"
                  :action="importAction"
                />
              </v-dialog>

              <v-tabs v-model="activeTab" show-arrows>
                <v-tabs-slider></v-tabs-slider>
                <v-tab> Historique des mesures </v-tab>
                <v-tab v-if="site.is_allow_year_compare">
                  Comparer les années
                </v-tab>
                <v-tab v-if="site.is_allow_input_value">
                  Valeurs saisies
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="activeTab">
                <v-tab-item>
                  <SiteVisualizations
                    :reload="reload"
                    :updateKey="updateKey"
                    :site="site"
                    :clientSites="clientSites"
                    :childIdsToName="childIdsToName"
                    @visualizationsChanged="onVisualizationsChanged"
                    ref="svisual"
                  />
                </v-tab-item>
                <v-tab-item v-if="site.is_allow_year_compare">
                  <SiteVisualizations
                    :reload="reload"
                    :updateKey="updateKey"
                    :site="site"
                    :clientSites="clientSites"
                    :yearCompare="true"
                    :childIdsToName="childIdsToName"
                    @visualizationsChanged="onVisualizationsChanged"
                  />
                </v-tab-item>
                <v-tab-item v-if="site.is_allow_input_value">
                  <InputEventData :site="site" :reload="reload" />
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-layout>
      </v-container>
    </v-main>
    <template v-if="importAction == 'import_values'">
      <v-dialog v-model="dialogHeadersShow" scrollable height="100%">
        <v-card height="100%">
          <v-card-title>
            <span class="headline">Sélection automatique des mesures</span>
          </v-card-title>
          <v-container fluid>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Mesures</v-list-item-title>
                <v-row>
                  <v-col v-if="site && site !== null">
                    <SiteSensors
                      @deleted="handleDeletedSensor"
                      :editable="true"
                      v-bind:sensors="site.sensors"
                    />
                  </v-col>
                </v-row>

                <v-card-actions>
                  <v-btn depressed @click="cancel">Annuler</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn depressed color="primary" @click="saveSite"
                    >Enregistrer les mesures</v-btn
                  >
                </v-card-actions>
              </v-list-item-content>
            </v-list-item>
          </v-container>
        </v-card>
      </v-dialog>
    </template>
    <template v-else>
      <v-dialog v-model="dialogHeadersShow" scrollable height="100%">
        <v-card height="100%">
          <v-card-title>
            <span class="headline">Sélection automatique des événements</span>
          </v-card-title>
          <v-container fluid>
            <v-list>
              <v-list-item
                v-if="site.events && site.events.names.length > 0"
                two-line
              >
                <v-list-item-content>
                  <v-list-item-title>Événements</v-list-item-title>
                  <v-row>
                    <v-col>
                      <v-simple-table height="300">
                        <template v-slot:default>
                          <tbody>
                            <tr
                              v-for="(event, i) in site.events.names"
                              :key="i"
                            >
                              <td>
                                {{ event }}
                              </td>
                              <!-- <td class="text-right" v-if="editable">
                          <v-icon small @click.stop="deleteEvent(event)"
                            >delete</v-icon
                          >
                        </td> -->
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-btn depressed @click="cancel">Annuler</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn depressed color="primary" @click="saveSite"
                      >Enregistrer les événements</v-btn
                    >
                  </v-card-actions>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-container>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import BreadcrumbDashboard from "@/components/BreadcrumbDashboard";
import SiteVisualizations from "@/components/SiteVisualizations";
import InputEventData from "@/components/InputEventData";
import InputEventDataForm from "@/components/InputEventDataForm";
import ImportDataForm from "@/components/ImportDataForm";
import SiteSensors from "@/components/SiteSensors";
import Vue from 'vue';

import {
  buildSaveSiteRequest,
  getSite,
  getSitePromise,
  getUserSite,
} from "../vuex/modules/site";
import ExportExcelButton from "@/components/ExportExcelButton";

export default {
  components: {
    BreadcrumbDashboard,
    SiteVisualizations,
    InputEventData,
    InputEventDataForm,
    ImportDataForm,
    SiteSensors,
    ExportExcelButton,
  },
  props: {
    site_id: { type: [String, Number], required: true },
  },
  data() {
    return {
      userrole: this.$store.state.profile.user.role,
      ready: false,
      site: null,
      clientSites: null,
      childIdsToName: null,
      reload: false,
      activeTab: null,
      addInputEventDataModal: false,
      addImportDataModal: false,
      importAction: "import_values",
      inputEventModalKey: Math.random()
        .toString(36)
        .substring(7),
      ImportDataModalKey: Math.random()
        .toString(36)
        .substring(7),
      dialogHeadersShow: false,
      updateKey: 0,
    };
  },
  async mounted() {
    let user = this.$store.state.profile.user;
    this.site = getUserSite(user, this.site_id);
    if (!this.site && user.role == "admin") {
      this.site = await getSitePromise(this.$store, this.site_id);
    }
    this.clientSites = this.getClientSites(this.site.client_id);
    this.childIdsToName = await this.getChildIdsToName();

    this.ready = true;
  },

  methods: {
    onVisualizationsChanged() {
      this.ready = false;
      this.getSite().then((response) => {
        this.site = response.data;
        this.ready = true;
      });
    },
    showErr(err) {
      console.log(err);
      this.$store.commit("setSnack", {
        type: "red",
        err,
      });
    },
    updateSite(callback) {
      this.getSite().then((result) => {
        this.site = result.data;
        console.log("Got site.", this.site);
        this.fetchClientSites(this.site.client_id, () => {
          if (callback) {
            callback();
          }
        });
      });
    },
    getClientSites(client_id) {
      return this.$store.state.profile.user.sites.filter((site) => {
        return site.client_id == client_id;
      });
    },
    fetchClientSites(client_id, callback) {
      this.updateUser(() => {
        this.clientSites = this.getClientSites();
        this.ready = true;
        if (callback) {
          callback();
        }
      });
    },
    updateUser(callback) {
      this.$store.dispatch("profileRequest").then(() => {
        console.log("Profile updated");
        if (callback) {
          callback();
        }
      });
    },
    getSite() {
      return getSite(this.$store, this.site_id);
    },

    async getChildSite(child_id) {
      let childSite;
      let filteredSites = this.clientSites.filter((s) => s.id == child_id);
      if (filteredSites.length == 1) {
        childSite = filteredSites[0];
      } else if (
        filteredSites == 0 &&
        this.$store.state.profile.user.role == "admin"
      ) {
        childSite = await getSitePromise(this.$store, child_id);
      } else {
        console.log("Error : filter give ", filteredSites);
      }
      return childSite;
    },
    async getChildIdsToName() {
      let childIdsToName = {};
      if (this.site.relations && this.site.relations.children)
        for (let child of this.site.relations.children) {
          let childSite = await this.getChildSite(child.id);
          childIdsToName[child.id] = childSite.name;
        }
      return childIdsToName;
    },
    onCancelInputEventData() {
      this.addInputEventDataModal = false;
      this.inputEventModalKey = Math.random()
        .toString(36)
        .substring(7); //refresh form component
      this.reload = !this.reload;
    },
    onSaveInputEventData() {
      this.addInputEventDataModal = false;
      this.inputEventModalKey = Math.random()
        .toString(36)
        .substring(7); //refresh form component
      this.reload = !this.reload;
    },
    onCancelImportData() {
      this.addImportDataModal = false;
      this.ImportDataModalKey = Math.random()
        .toString(36)
        .substring(7); //refresh form component
      this.reload = !this.reload;
    },
    onSaveImportData() {
      this.updateSite(() => {
        this.addImportDataModal = false;
        this.ImportDataModalKey = Math.random()
          .toString(36)
          .substring(7); //refresh form component

        this.reload = !this.reload;

        console.log(this.site.sensors);
        this.dialogHeadersShow = true;
      });
    },
    cancel() {
      this.dialogHeadersShow = false;
    },
    importEvent() {
      this.importAction = "import_events";
      this.addImportDataModal = true;
    },
    importValues() {
      this.importAction = "import_values";
      this.addImportDataModal = true;
    },
    handleDeletedSensor(sensor) {
      this.site.sensors = this.site.sensors.filter((row) => row != sensor);
    },
    saveSite() {
      let build = buildSaveSiteRequest(this.site, false);
      console.log("save", this.site);
      this.$store
        .dispatch(build.request, build.queries)
        .then((site) => {
          this.loading = false;
          this.$store.commit("setSnack", {
            type: "success",
            msg: "Site enregistré !",
          });
          this.dialogHeadersShow = false;
          this.updateSite();
        })
        .catch((err) => {
          this.loading = false;
          this.dialogHeadersShow = false;
          if (err.response && err.response.status != 403)
            this.showErr(err.response.data);
          else {
            this.showErr(err);
          }
        });
    },
  },
};
</script>
