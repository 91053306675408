<template>
  <div class="client-sites">
    <!-- CREATE/EDIT DIALOG -->
    <v-dialog v-model="dialogShow" max-width="1400px" persistent scrollable>
     
          <SiteForm
            :editable="dialogEdit"
            ref="SiteForm"
            v-model="site"
            action="update"
            @savedForm="handleSavedSiteForm"
            @canceledForm="handleCanceledSiteForm"
            @editForm="editItem"
          />
        
    </v-dialog>

    <!-- <v-dialog v-model="radarGraphShow" max-width="1400px" persistent scrollable>
      <RadarChart ref="RadarChart"/>
    </v-dialog> -->

    <!-- <v-icon small class="" @click.stop="showRadarGraph">bar_chart</v-icon> -->

    <v-data-table
      :items="sites"
      :items-per-page="sites.length"
      :server-items-length="sites.length"
      :loading="loading"
      :headers="headers"
      :class="{ mobile: isMobile }"
      hide-default-footer
      :options.sync="pagination"
    >
      <template v-slot:item="props">
        <tr @click.stop="showItem(props.item)">
          <td data-label="Nom">{{ props.item.name }}</td>
          <td data-label="Utilisateurs">
            <v-chip
              v-for="user in props.item.users"
              :key="user.id"
              class="mr-1 mb-1"
              color="primary"
              >{{ user.complete_name }}</v-chip
            >
          </td>
          <td class="text-right">
            <v-icon small class="mr-2" @click.stop="showItem(props.item)"
              >remove_red_eye</v-icon
            >
            <v-icon small class="mr-2" @click.stop="dashboardItem(props.item)"
              >bar_chart</v-icon
            >
            <v-icon small class="mr-2" @click.stop="editItem(props.item)"
              >edit</v-icon
            >
          </td>
        </tr>
      </template>
      <template v-slot:no-data> Aucun site </template>
    </v-data-table>
  </div>
</template>

<script>
import SiteForm from "../components/SiteForm";
import RadarChart from "../components/Charts/Radar.vue";

export default {
  components: {
    SiteForm,
    //RadarChart
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
      site: {},
      headers: [
        {
          text: "Nom",
          align: "left",
          value: "name",
        },
        {
          text: "Utilisateurs",
          align: "left",
          value: "user",
          sortable: false,
        },
        {
          text: "",
          value: "",
          sortable: false,
        },
      ],
      pagination: {},
      dialogEdit: false,
      dialogShow: false,
      radarGraphShow: false,
      isMobile: false,
      loading: false,
      sites: [],
    };
  },
  watch: {
    client() {
      this.refreshPagination();
    },
    loading() {
      if (this.loading) {
        this.sites = [];
      }
    },
    pagination: {
      handler() {
        this.refreshPagination();
      },
      deep: true,
    },
    dialogShow() {
      if (this.dialogShow) {
        this.$clicked_site = this.shownSite;
      } else this.$clicked_site = null;

      if (this.dialogShow) this.$resetDialogScroll();
    },
    dialogEdit() {
      if (this.dialogEdit) this.$resetDialogScroll();
    },
  },
  mounted() {
    this.refreshPagination();
  },
  methods: {
    onResize() {
      this.isMobile = this.$vuetify.breakpoint.smAndDown;
    },
    handleSavedSiteForm(site) {
      this.refreshPagination();
      this.dialogShow = false;
      this.dialogEdit = false;
    },
    handleCanceledSiteForm() {
      this.dialogShow = false;
      this.dialogEdit = false;
    },
    showRadarGraph(){
      this.radarGraphShow = true;
    },
    editItem(site) {
      //   this.site = Object.assign({}, site);
      if (site) this.site = site;
      this.dialogEdit = true;
      this.dialogShow = true;
    },
    showItem(site) {
      this.site = site;
      this.dialogEdit = false;
      this.dialogShow = true;
    },
    dashboardItem(site) {
      this.$router.push({ name: "Dashboard", params: { site_id: site.id } });
    },
    getSites(queries) {
      this.loading = true;
      this.$store
        .dispatch("sitesRequest", queries)
        .then((result) => {
          this.sites = result.data;
          this.loading = false;
          if (!this.ready)
            this.$checkHistory("site", (site) => this.showItem(site));
          this.ready = true;
        })
        .catch(() => {
          //
        });
    },
    refreshPagination() {
      const queries = {
        sort: this.pagination.sortBy,
        direction:
          this.pagination.sortDesc && this.pagination.sortDesc[0]
            ? "desc"
            : "asc",
        client_id: this.client.id,
      };

      this.loading = true;
      this.getSites(queries);
    },
  },
};
</script>
