import Vue from 'vue'

const state = {
    status: '',
    intConfs: [],
}

const getters = {}

const actions = {
    // clientRequest: async ({commit}, queries) => {
    //     let url = `/api/clients/${queries.id}`;

    //     return new Promise((resolve, reject) => {
    //         commit('clientRequest')
    //         Vue.axios.get(url)
    //             .then(result => {
    //                 commit('clientSuccess', result.data)
    //                 resolve(result.data)
    //             })
    //             .catch(err => {
    //                 commit('clientError');
    //                 reject(err)
    //             })
    //     })
    // },
    intConfEditRequest: async ({commit}, queries) => {
        let url = `/api/integrations_conf/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('intConfRequest')
            Vue.axios.post(url, queries.datas)
                .then(result => {
                    commit('intConfSuccess', result.data.data)
                    resolve(result.data.data)
                })
                .catch(err => {
                    commit('intConfError');
                    reject(err)
                })
        })
    },
    intConfCreateRequest: async ({commit}, queries) => {
        let url = `/api/integrations_conf`;

        return new Promise((resolve, reject) => {
            commit('intConfRequest')
            Vue.axios.post(url, queries)
                .then(result => {
                    commit('intConfSuccess', result.data.data)
                    resolve(result.data.data)
                })
                .catch(err => {
                    commit('intConfError');
                    reject(err)
                })
        })
    },
    intConfDeleteRequest: async ({commit}, queries) => {
        let url = `/api/integrations_conf/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('intConfRequest')
            Vue.axios.delete(url)
                .then(result => {
                    commit('intConfDeleteSuccess')
                    resolve(result)
                })
                .catch(err => {
                    commit('intConfError');
                    reject(err)
                })
        })
    },
    intConfsRequest: async ({commit, dispatch}, queries) => {
        //remove null values
        queries = Object.entries(queries).reduce((a,[k,v]) => (v ? {...a, [k]:v} : a), {})
        //build query url
        let url = '/api/integrations_conf?'+Object.entries(queries).map(e => e.join('=')).join('&')


        return new Promise((resolve, reject) => {
            commit('intConfRequest')
            Vue.axios.get(url)
                .then(result => {
                    commit('intConfsSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('intConfError');
                    reject(err)
                })
        })
    },
    integrationsRequest: async ({commit, dispatch}, queries) => {
        //build query url
        let url = '/api/integrations'


        return new Promise((resolve, reject) => {
            commit('clientRequest')
            Vue.axios.post(url, queries.datas)
                .then(result => {
                    commit('integrationsSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('clientError');
                    reject(err)
                })
        })
    },
}

const mutations = {
    intConfRequest: (state) => {
        state.status = 'loading';
    },
    intConfsSuccess: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'intConfs', resp);
    },
    intConfSuccess: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'intConf', resp);
    },
    intConfDeleteSuccess: (state) => {
        state.status = 'success';
    },
    intConfError: (state) => {
        state.status = 'error';
    },
    integrationsSuccess: (state) => {
        state.status = 'success';
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}