import Vue from 'vue'

const state = {
    status: '',
    users: [],
}

const getters = {}

const actions = {
    userRequest: async ({ commit }, queries) => {
        let url = `/api/users/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('userRequest')
            Vue.axios.get(url)
                .then(result => {
                    commit('userSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('userError');
                    reject(err)
                })
        })
    },
    userEditRequest: async ({ commit }, queries) => {
        let url = `/api/users/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('userRequest')
            Vue.axios.put(url, queries.datas)
                .then(result => {
                    commit('userSuccess', result.data.data)
                    resolve(result.data.data)
                })
                .catch(err => {
                    commit('userError');
                    reject(err)
                })
        })
    },
    userCreateRequest: async ({ commit }, queries) => {
        let url = `/api/users`;

        return new Promise((resolve, reject) => {
            commit('userRequest')
            Vue.axios.post(url, queries)
                .then(result => {
                    commit('userSuccess', result.data.data)
                    resolve(result.data.data)
                })
                .catch(err => {
                    commit('userError');
                    reject(err)
                })
        })
    },
    userGetDefaultSiteColor: async ({ commit }, queries) => {
        let url = `/api/users/color`;

        return new Promise((resolve, reject) => {
            commit('userRequest')
            Vue.axios.post(url, queries)
                .then(result => {
                    commit('userSuccess', result.data.data)
                    resolve(result.data.data)
                })
                .catch(err => {
                    commit('userError');
                    reject(err)
                })
        })
    },
    userDeleteRequest: async ({ commit }, queries) => {
        let url = `/api/users/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('userRequest')
            Vue.axios.delete(url)
                .then(result => {
                    commit('userDeleteSuccess')
                    resolve(result)
                })
                .catch(err => {
                    commit('userError');
                    reject(err)
                })
        })
    },
    usersRequest: async ({ commit }, queries) => {
        //remove null values
        queries = Object.entries(queries).reduce((a, [k, v]) => (v ? { ...a, [k]: v } : a), {})
        //build query url
        let url = '/api/users?' + Object.entries(queries).map(e => e.join('=')).join('&')

        return new Promise((resolve, reject) => {
            commit('userRequest')
            Vue.axios.get(url)
                .then(result => {
                    commit('usersSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('userError');
                    reject(err)
                })
        })
    },
}

const mutations = {
    userRequest: (state) => {
        state.status = 'loading';
    },
    usersSuccess: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'users', resp);
    },
    userSuccess: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'user', resp);
    },
    userDeleteSuccess: (state) => {
        state.status = 'success';
    },
    userError: (state) => {
        state.status = 'error';
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}