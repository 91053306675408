<template>
  <div v-if="chartReady" class="chart-container" id="graphChart">
    <slot></slot>
    <!-- <v-container v-if="this.visualization.component == null || (this.visualization.component != null && !this.visualization.component.includes('RadarChart'))" style="display: flex; flex-direction: row; justify-content: center; align-items: center;" color="bg-primary">
      <span style="display: flex; justify-content: center; align-items: center; flex-direction: column; border: 2px solid black; border-radius: 20px; width: max-content; padding: 0 10px; margin: 0 15px;">
        <p style="transform: translateY(50%);">Sélection <br> Multiple</p>
        <v-switch
            v-model=selectionMode
            color="primary"
            v-on:change=setSelectionMode()
        />
      </span>
    </v-container> -->
    <template v-if="this.$store.state.profile.user.role === 'srAdmin'" >
      <v-text-field v-model="custN" @keyup.enter="logVariableByName(custN)" placeholder="Variable name to search for"></v-text-field>
      <v-textarea readonly v-model="custNLog"></v-textarea>
    </template>
    <RadarChart v-if="this.visualization.component != null && this.visualization.component.includes('RadarChart')" :chartData="chartData" :options="chartOptions" />
    <Bar v-else :chartData="chartData" :options="chartOptions" />
  </div>
  <div v-else class="chart-container text-center">
    <slot></slot>
    <v-progress-circular indeterminate></v-progress-circular>
  </div>
</template>
<script>
import Bar from "./Charts/Bar";
//import chartJsPluginAnnotation from "chartjs-plugin-annotation";

import {
  dateFormatFrenchPretty,
  getDates,
  convertDate,
  toDate,
  convertDatePickerToDate,
  convertDateToStringWithMinutes,
  convertDateFullRange,
} from "@/utils/dates";
import { getRandomColor, getAlteredColor } from "@/utils/colors";
import { getColor } from "@/const/stadepheno";
import { buildTimeRangeQuery, buildQueryMust } from "@/elastic/queries";
import { getChildrenIds } from "@/vuex/modules/site";
import "chartjs-plugin-dragdata";
import {RadarChart} from "vue-chart-3";

export default {
  components: {
    Bar,
    RadarChart
  },
  props: {
    site: { type: Object, required: true },
    reload: { type: Boolean, required: false },
    visualization: { type: Object, required: true },
    query: { type: Object, required: true },
    filters: { type: Object, required: false },
    yearCompareQuery: { type: Object, required: true },
    yearCompare: { type: Boolean, required: false },
    uniqueYearCompare: {type: Boolean, required: false},
    currentYearIndex: {type: Number, required: false},
    childIdsToName: Object,
  },
  data() {
    let children_site_ids = getChildrenIds(this.site);
    let intervalFormat = this.query.interval;
    if (intervalFormat == "data") {
      intervalFormat = "minute";
    }
    let xAxe = {
      x: {
        // type: "category",

        type: "time",
        time: {
          parser: "DD-MM-YYYY HH:mm",
          unit: intervalFormat,
          displayFormats: {
            // year: "YYYY",
            // month: "DD-MM-YYYY",
            // week: "DD-MM-YYYY",
            // day: "DD-MM-YYYY",
            hour: "MMM D, h A",
            minute: "h:mm A",
          },
        },

        ticks: {
          source: "auto",
          // callback: function (label) {
          //   return label;
          // },
        },
      },
      xLabel: {
        type: "linear",
        min: 0,
        max: 3,
        display: false,
      },
      yVegetal: {
        type: "linear",
        position: 'right',
        yMin: 0,
        yMax: 100,
        display: false,
      },
      calcFormAxe: {
        type: "linear",
        ticks: {
          color: "#FF0000",
          textStrokeColor: "#FF0000",
        },
        position: "left",
      },
    };
    return {
      updateId: 0,
      custN: null,
      xAxe,
      myChart: undefined,
      eventIndex: 0,
      children_site_ids,
      elastic_winery_mesures_index: "winery_mesures_" + this.site.client_id,
      elastic_winery_events_index: "winery_events_" + this.site.client_id,
      eventLegendCount: {},
      chartReady: false,
      custNLog: null,
      min: Number.MAX_VALUE,
      max: -Number.MAX_VALUE,
      selectionMode: false,
      chartData: {
        labels: [],
        datasets: [],
      },
      colors: [
        "#1f77b4", // muted blue
        "#ff7f0e", // safety orange
        "#2ca02c", // cooked asparagus green
        "#d62728", // brick red
        "#9467bd", // muted purple
        "#8c564b", // chestnut brown
        "#e377c2", // raspberry yogurt pink
        "#7f7f7f", // middle gray
        "#bcbd22", // curry yellow-green
        "#17becf", // blue-teal]
      ],
      colorIndex: -1,
      chartOptions: {
        // http://www.java2s.com/example/javascript/chart.js/chartjs-to-draw-vertical-line-on-integer-x-axis-value-for-line-chart.html
        // tooltips: {
        //   mode: "x",
        // },
        // spanGaps: true,
        barValueSpacing: 20,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxe
        },
        interaction: {
          intersect: false,
          mode: 'point',
        },
        plugins: {
          annotation: {
            annotations: {

            },
          },
          legend: {
            labels: {
              font: { size: 22 },
              // filter: function (legendItem, chartData) {
              //   if (typeof legendItem.text == "undefined") {
              //     return false;
              //   } else if (
              //     this.eventLegendCount[legendItem.text] !== undefined
              //   ) {
              //     return false;
              //   }
              //   this.eventLegendCount[legendItem.text] = 1;
              //   return true;
              // }.bind(this),
            },
          },
        },
      },
    };
  },
  watch: {
    reload: {
      handler() {
        this.updateGraphicData();
      },
    },
    query: {
      deep: true,
      handler() {
        this.updateGraphicData();
      },
    },
    filters: {
      deep: true,
      handler() {
        this.updateGraphicData();
      },
    },
  },
  mounted() {
    this.updateGraphicData();
  },
  methods: {
    formattedLog(dataLog) {
      try {
        // Return the beautifully formatted JSON string
        return JSON.stringify(dataLog, null, 2);
      } catch {
        // If parsing fails, just return the original data
        return dataLog;
      }
    },
    logVariableByName(variablePath) {
      const pathSegments = variablePath.split('.');
      let currentObject = this; // Assuming `this` is the Vue instance
      for (const segment of pathSegments) {
        if (segment in currentObject) {
          currentObject = currentObject[segment];
        } else {
          console.log(`Variable path ${variablePath} does not exist.`);
          return;
        }
      }
      console.log(currentObject);
      this.custNLog = this.formattedLog(currentObject);
    },
    isIntervalAllowEndDate() {
      return (
        this.query.interval != "data" &&
        this.query.interval != "hour" &&
        this.query.interval != "minute"
      );
    },
    removeSubstringBetweenSameChars(str, charToMatch) {
      // Check if the first character matches charToMatch
      if (str.charAt(0) === charToMatch) {
        // Find the index of the second occurrence of charToMatch
        let secondIndex = str.indexOf(charToMatch, 1);

        // If the second occurrence is found
        if (secondIndex !== -1) {
          // Extract the substring between the two occurrences
          let substring = str.substring(1, secondIndex);

          // Log the extracted substring

          // Remove the whole part from the original string and return it
          return [substring, str.slice(secondIndex + 1)];
        }
      }
      // If the first character does not match or second occurrence is not found,
      // return the original string
      return str;
    },
    setSelectionMode(){
      this.chartOptions.interaction.mode = (this.selectionMode === true ? 'index' : 'point');
    },
    getMesureText(item) {
      let name = "";
      if (item.site_id != this.site.id) {
        name += this.childIdsToName[item.site_id] + " : ";
      }
      name += item.name;
      return name;
    },
    async updateGraphicData() {
      this.updateId = this.updateId + 1;
      let updateId = this.updateId;
      this.eventLegendCount = {};
      this.curveId = 0;
      this.chartReady = false;
      this.chartData = {
        datasets: [],
        labels: [],
      };

      if (this.site.ftp_path != null && this.site.ftp_path.includes("/Vegetal Signal/")) {
        this.chartOptions.plugins.annotation.annotations = {
          label1: {
            drawTime: "beforeDraw",
            display: true,
            type: 'label',
            xValue: 0.1,
            xScaleID: "xLabel",
            yValue: 82.5,
            yScaleID: "yVegetal",
            padding: 20,
            backgroundColor: 'rgba(245,245,245,0)',
            color: 'rgba(125,125,125)',
            textStrokeColor: 'rgba(125,125,125)',
            textStrokeWidth: 1.5,
            textAlign: 'center',
            content: ['Confort'],
            font: {
              size: 42
            }
          },
          label2: {
            drawTime: "beforeDraw",
            display: true,
            type: 'label',
            xValue: 0.1,
            xScaleID: "xLabel",
            yValue: 49.5,
            yScaleID: "yVegetal",
            padding: 20,
            backgroundColor: 'rgba(245,245,245,0)',
            color: 'rgba(125,125,125)',
            textStrokeColor: 'rgba(125,125,125)',
            textStrokeWidth: 1.5,
            textAlign: 'center',
            content: ['Contrainte'],
            font: {
              size: 42
            }
          },
          label3: {
            drawTime: "beforeDraw",
            display: true,
            type: 'label',
            xValue: 0.1,
            xScaleID: "xLabel",
            yValue: 16.5,
            yScaleID: "yVegetal",
            padding: 20,
            backgroundColor: 'rgba(245,245,245,0)',
            color: 'rgba(125,125,125)',
            textStrokeColor: 'rgba(125,125,125)',
            textStrokeWidth: 1.5,
            textAlign: 'center',
            content: ['Stress'],
            font: {
              size: 42
            }
          },
          box1: {
            drawTime: "beforeDraw",
            display: true,
            type: "box",
            yMin: 66,
            yMax: 100,
            backgroundColor: "rgba(101, 233, 171,0.25)",
            yScaleID: "yVegetal",
            //value: date,
          },
          box2: {
            drawTime: "beforeDraw",
            display: true,
            type: "box",
            yMin: 33,
            yMax: 66,
            backgroundColor: "rgba(255,154,0,0.25)",
            yScaleID: "yVegetal",
            //value: date,
          },
          box3: {
            drawTime: "beforeDraw",
            display: true,
            type: "box",
            yMin: 0,
            yMax: 33,
            backgroundColor: "rgba(229,22,22,0.25)",
            yScaleID: "yVegetal",
            //value: date,
          },
        };
      }
      this.chartOptions.scales = this.xAxe;

      let startDate = new Date();
      startDate.setFullYear(1900);
      let endDate = new Date();

      let interval = this.query.interval;

      if (this.yearCompare) {

        if ((this.yearCompareQuery.from || this.yearCompareQuery.to) && !this.uniqueYearCompare) {
          if(this.yearCompareQuery.years != null){
            if (this.yearCompareQuery.from) {
              for(let year of this.yearCompareQuery.years){
                startDate[year] = convertDatePickerToDate(this.yearCompareQuery.from[year]);
                startDate[year].setHours(0);
                startDate[year].setMinutes(0);
              }
            }
            if (this.yearCompareQuery.to) {
              for(let year of this.yearCompareQuery.years){
                endDate[year] = convertDatePickerToDate(this.yearCompareQuery.to[year]);
                endDate[year].setHours(0);
                endDate[year].setMinutes(0);
              }
            }
          }
          interval = this.yearCompareQuery.interval;
        }

        let i = 0;
        if (this.visualization.axes && this.visualization.axes.axes.length > 0 && this.yearCompareQuery.years !== null) {

          if(this.uniqueYearCompare && this.uniqueYearCompare == true){
            let year = this.yearCompareQuery.uniqueYear.year;            
            if (this.yearCompareQuery.fromUnique) {
              for(let i = 0; i < this.yearCompareQuery.uniqueYear.count; i++){
                startDate[year + "_" + this.currentYearIndex] = convertDatePickerToDate(this.yearCompareQuery.fromUnique[year + "_" + this.currentYearIndex]);
                startDate[year + "_" + this.currentYearIndex].setHours(0);
                startDate[year + "_" + this.currentYearIndex].setMinutes(0);
              }
            }
            if (this.yearCompareQuery.toUnique) {
              for(let i = 0; i < this.yearCompareQuery.uniqueYear.count; i++){
                endDate[year + "_" + this.currentYearIndex] = convertDatePickerToDate(this.yearCompareQuery.toUnique[year + "_" + this.currentYearIndex]);
                endDate[year + "_" + this.currentYearIndex].setHours(0);
                endDate[year + "_" + this.currentYearIndex].setMinutes(0);
              }
            }

            interval = this.yearCompareQuery.interval;

            startDate[year + "_" + this.currentYearIndex].setFullYear(year);
            endDate[year + "_" + this.currentYearIndex].setFullYear(year);
            await this.updateGraphicMesuresData(
              updateId,
              startDate[year + "_" + this.currentYearIndex],
              endDate[year + "_" + this.currentYearIndex],
              interval,
              false,
              i
            ).catch((err) => {
              this.showErrMesuresDataLoading(err);
            });
            i++;
            if (updateId != this.updateId) {
              return;
            }
          } else {
            for(let year of this.yearCompareQuery.years){
              startDate[year].setFullYear(year);
              endDate[year].setFullYear(year);
              await this.updateGraphicMesuresData(
                updateId,
                startDate[year],
                endDate[year],
                interval,
                this.yearCompare,
                i
              ).catch((err) => {
                this.showErrMesuresDataLoading(err);
              });
              i++;
              if (updateId != this.updateId) {
                return;
              }
            }
          }

          
        }
        if (
          this.visualization.events &&
          this.visualization.events.length > 0 &&
          !(
            this.yearCompare &&
            (interval == "year" || interval == "month" || interval == "week")
          )
        ) {
          i = 0;
          for (let year of this.yearCompareQuery.years) {
            startDate.setFullYear(year);
            endDate.setFullYear(year);
            await this.initGraphicEventData(
              updateId,
              startDate,
              endDate,
              interval,
              true,
              i
            ).catch((err) => {
              this.showErrEventDataLoading(err);
            });

            i++;
          }
        }
      } else {
        if (this.query.from || this.query.to) {
          if (this.query.from) {
            startDate = new Date(this.query.from);
            startDate.setHours(0);
            startDate.setMinutes(0);
          }
          if (this.query.to) {
            endDate = new Date(this.query.to);
            endDate.setHours(23);
            endDate.setMinutes(59);
          }
          if (!this.isIntervalAllowEndDate()) {
            startDate = new Date(this.query.from);
            startDate.setHours(this.query.timerange[0]);
            endDate = new Date(this.query.from);
            endDate.setHours(this.query.timerange[1]);
          }
        }
        // console.log("visualization : " + JSON.stringify(this.visualization));

        if (this.visualization.axes && this.visualization.axes.axes.length > 0) {
          await this.updateGraphicMesuresData(
            updateId,
            startDate,
            endDate,
            interval,
            this.yearCompare,
            0
          ).catch((err) => {
            this.showErrMesuresDataLoading(err);
          });
        }
        if (updateId != this.updateId) {
          return;
        }
        if (
          this.visualization.events &&
          this.visualization.events.length > 0 &&
          !(this.yearCompare && interval == "year")
        ) {
          this.eventIndex = 0;
          // console.log("initGraphicEventData");
          await this.initGraphicEventData(
            updateId,
            startDate,
            endDate,
            interval,
            false,
            0
          ).catch((err) => {
            this.showErrEventDataLoading(err);
          });
          // console.log("initGraphicEventDataFormSaisiesPromise");
          await this.initGraphicEventDataFormSaisiesPromise(
            updateId,
            startDate,
            endDate,
            interval,
            false
          ).catch((err) => {
            this.showErrEventDataLoading(err);
          });
          if (updateId != this.updateId) {
            return;
          }
          if (!this.yearCompare) {
            if(this.visualization.component != null && this.visualization.component.includes("RadarChart")){
              let voidvar = 0;
            } else {
              for (let event of this.visualization.events) {
                let borderColor = getColor(event);
                let legend = {
                  label: event,
                  borderColor,
                  backgroundColor: borderColor,
                  data: [{}, {}],
                  order: -999999999,
                };
                this.chartData.datasets.push(legend);
              }
            }
          }
        }
      }
      // console.log(this.query);
      if (updateId != this.updateId) {
        return;
      }

      if(this.visualization.component != null && this.visualization.component.includes("RadarChart")){
        this.chartData.labels = this.visualization.events;
        this.chartOptions = {
          maintainAspectRatio: false,
          plugins: {
            dragData: {
              round: 0,
              magnet: {
                //to: Math.round()
              }
            }
          },
          scales: {
            r: {
              stepSize: 1,
              min: 0,
              max: 10,
              angleLines: {
                color: 'red',
                lineWidth: 4,
              }
            }
          },
          elements: {
            point: {
              radius: 6,
            }
          },
          legend: {
            labels: {
              font: {
                size: 36
              }
            }
          }
        };

        for(let key of Object.keys(this.chartData.datasets)) {
          delete this.chartData.datasets[key].order;
          delete this.chartData.datasets[key].yAxisID;
          for(let nkey of Object.keys(this.chartData.datasets[key].data)){
            this.chartData.datasets[key].data[nkey] = this.chartData.datasets[key].data[nkey].y;
            //delete this.chartData.datasets[key].data[nkey].y;
            //delete this.chartData.datasets[key].data[nkey].x;
          }
        }
      } else {
        if(this.yearCompare){

          if(!this.uniqueYearCompare && !this.uniqueYearCompare == true){
            let minDate = convertDatePickerToDate(this.yearCompareQuery.minDate.mn);
            minDate.setHours(0);
            minDate.setMinutes(0);
            minDate.setFullYear(this.yearCompareQuery.minDate.year);

            let maxDate = convertDatePickerToDate(this.yearCompareQuery.maxDate.mx);
            maxDate.setHours(0);
            maxDate.setMinutes(0);
            maxDate.setFullYear(this.yearCompareQuery.maxDate.year);

            this.setGraphicLabels(minDate, maxDate, interval, this.yearCompare);
          } else {
            let year = this.yearCompareQuery.uniqueYear.year;
            this.setGraphicLabels(startDate[year + "_" + this.currentYearIndex], endDate[year + "_" + this.currentYearIndex], interval, false);
          }
          
        } else {
          let newStartDate = startDate;
          let currentHours = newStartDate.getHours();
          let adjustedHours = currentHours + this.site['data_timeoffset'];
          if (adjustedHours < 0) {
              newStartDate.setDate(newStartDate.getDate() - 1);  // Move to the previous day
              adjustedHours = 24 + adjustedHours;  // Adjust hours to the 0-23 range
          }
          newStartDate.setHours(adjustedHours);

          let newEndDate = endDate;
          let currentEndHours = newEndDate.getHours();
          let adjustedEndHours = currentEndHours - 0;
          if (adjustedEndHours < 0) {
              newEndDate.setDate(newEndDate.getDate() - 1);  // Move to the previous day
              adjustedEndHours = 24 + adjustedEndHours;  // Adjust hours to the 0-23 range
          }
            newEndDate.setHours(adjustedEndHours);

          this.setGraphicLabels(newStartDate, newEndDate, interval, this.yearCompare);
        }
      }

      this.chartReady = true;
    },

    async getMesureData(startDate, endDate, interval, site_id, mesure) {
      let mesures = [];
      mesures.push(mesure);
      return await new Promise((resolve, reject) => {

        let newStartDate = startDate;
        let currentHours = newStartDate.getHours();
        let adjustedHours = currentHours - this.site['data_timeoffset'];
        if (adjustedHours < 0) {
            newStartDate.setDate(newStartDate.getDate() - 1);  // Move to the previous day
            adjustedHours = 24 + adjustedHours;  // Adjust hours to the 0-23 range
        }
        newStartDate.setHours(adjustedHours);

        let elasticQuery = buildTimeRangeQuery(
          newStartDate,
          endDate,
          interval,
          site_id,
          mesures
        );

        let size = 0;
        if (interval == "data") {
          size = 1000000;
        }

        this.$store
          .dispatch("elasticSearchRequest", {
            index: this.elastic_winery_mesures_index,
            body: elasticQuery,
            size: size,
            site: site_id,
          })
          .then((result) => {
            let rows;
            if (
              interval != "data" &&
              (!result.data.aggregations.time_buckets.buckets ||
                result.data.aggregations.time_buckets.buckets.length <= 0)
            ) {
              resolve(rows);
              return;
            }

            if (interval && interval == "data") {
              if (result.data["hits"]["hits"].length > 0) {
                rows = result.data["hits"]["hits"].map((row) => row._source);
              }
            } else {
              rows = result.data.aggregations.time_buckets.buckets;
              rows = rows.filter((row) => row["doc_count"] > 0);
            }
            resolve(rows);
          });
      });
    },

    async getMesuresData(
      startDate,
      endDate,
      interval,
      mesures,
      filters,
      site_id,
      children_site_ids
    ) {
      return await new Promise((resolve, reject) => {

        let newStartDate = startDate;
        let currentHours = newStartDate.getHours();
        let adjustedHours = currentHours - this.site['data_timeoffset'];
        if (adjustedHours < 0) {
            newStartDate.setDate(newStartDate.getDate() - 1);  // Move to the previous day
            adjustedHours = 24 + adjustedHours;  // Adjust hours to the 0-23 range
        }
        newStartDate.setHours(adjustedHours);

        let elasticQuery = buildTimeRangeQuery(
          newStartDate,
          endDate,
          interval,
          site_id,
          mesures,
          filters,
          children_site_ids
        );

        let size = 0;
        if (interval == "data") {
          size = 10000;
        }
        this.$store
          .dispatch("elasticSearchRequest", {
            index: this.elastic_winery_mesures_index,
            body: elasticQuery,
            size: size,
            site: this.visualization.site_id,
          })
          .catch((err) => {
            reject(err);
          })
          .then((result) => {
            let rows = [];
            if (
              interval != "data" &&
              (!result.data.aggregations.time_buckets.buckets ||
                result.data.aggregations.time_buckets.buckets.length <= 0)
            ) {
              resolve(rows);
              return;
            }

            if (interval && interval == "data") {
              if (result.data["hits"]["hits"].length > 0) {
                rows = result.data["hits"]["hits"].map((row) => row._source);
              }
            } else {
              rows = result.data.aggregations.time_buckets.buckets;
              rows = rows.filter((row) => row["doc_count"] > 0);
            }
            if (!rows || rows.length == 0) {
              resolve(rows);
              return;
            }
            resolve(rows);
          });
      });
    },

    async updateGraphicMesuresData(
      updateId,
      startDate,
      endDate,
      interval,
      yearCompare,
      yearId
    ) {
      let mesuresToLoad = [];
      // console.log(this.visualization.axes.axes);
      for (let axe of this.visualization.axes.axes) {
        for (let mesure of axe.mesures) {
          let m = { ...mesure };
          m.aggregationsType = axe.aggregationsType;
          if (m.site_id == this.visualization.site_id) mesuresToLoad.push(m);
        }
      }
      let rows = [];
      //don't change that again !
      if (mesuresToLoad && mesuresToLoad.length > 0) {
        rows = await this.getMesuresData(
          startDate,
          endDate,
          interval,
          mesuresToLoad,
          this.filters,
          this.visualization.site_id,
          this.children_site_ids
        );
      }
      if (updateId != this.updateId) {
        return;
      }

      let minDate;
      if (rows.length > 0) {
        if (interval && interval == "data") {
          minDate = toDate(rows[0]["@timestamp"]);
          /*let currentHours = minDate.getHours();
          let adjustedHours = currentHours + this.site['data_timeoffset'];
          if (adjustedHours < 0) {
              minDate.setDate(minDate.getDate() - 1);  // Move to the previous day
              adjustedHours = 24 + adjustedHours;  // Adjust hours to the 0-23 range
          }
          minDate.setHours(adjustedHours);*/
        } else {
          minDate = toDate(rows[0].key_as_string, dateFormatFrenchPretty);
          /*let currentHours = minDate.getHours();
          let adjustedHours = currentHours + this.site['data_timeoffset'];
          if (adjustedHours < 0) {
              minDate.setDate(minDate.getDate() - 1);  // Move to the previous day
              adjustedHours = 24 + adjustedHours;  // Adjust hours to the 0-23 range
          }
          minDate.setHours(adjustedHours);*/
        }
        if (!startDate && minDate) {
          startDate = minDate;
        }
      }
      let position = "left";

      for (let k in this.visualization.axes.axes) {
        let axe = this.visualization.axes.axes[k];

        // console.log("axe", axe);
        if (!yearCompare || yearId == 0) {
          let yAxe = {
            type: "linear",
            position: position,
            ticks: {
              color: axe.color.hex,
              textStrokeColor: axe.color.hex,
            },
            display: (Object.hasOwnProperty(axe.display) ? true : axe.display)
          };

          if (axe.min && axe.min != null && axe.min != "") {
            yAxe.min = parseFloat(axe.min);
          }
          if (axe.max && axe.max != null && axe.max != "") {
            yAxe.max = parseFloat(axe.max);
          }
          this.chartOptions.scales[k] = yAxe;
        }
        let m = 0;
        for (let mesure of axe.mesures) {
          let color = axe.color.hex;
          let label = this.getMesureText(mesure);
          if (yearCompare && !this.uniqueYearCompare) {
            label += " " + this.yearCompareQuery.years[yearId];
            color = getAlteredColor(
                axe.color.hex,
                (yearId * 100) / this.yearCompareQuery.years.length
            );
          } else if(yearCompare && this.uniqueYearCompare && this.uniqueYearCompare == true){
            label += " " + this.yearCompareQuery.uniqueYear.year;
            color = getAlteredColor(
                axe.color.hex,
                (yearId * 100) / this.yearCompareQuery.years.length
            );
          }
          let dataset = {
            yAxisID: k,
            label,
            borderColor: color,
            backgroundColor: color,
            borderWidth: 2,
            data: [],
            order: -1 * (100 * m + k),
            // order: -1 * (1000 * yearId + 100 * m + k),
          };

          let mesureRows = rows;

          if (mesure.site_id != this.visualization.site_id) {
            mesureRows = await this.getMesureData(
                startDate,
                endDate,
                interval,
                mesure.site_id,
                mesure
            );
            if (updateId != this.updateId) {
              return;
            }
            if (!mesureRows || mesureRows.length <= 0) {
              console.log("mesures empty for mesure : " + mesure);
              continue;
            }

            let minDate;

            if (interval && interval == "data") {
              minDate = toDate(mesureRows[0]["@timestamp"]);
            } else {
              minDate = toDate(mesureRows[0].key_as_string, dateFormatFrenchPretty);
            }
            if (!startDate && minDate) {
              startDate = minDate;
            }
          }

          let data;

          let values;
          if (interval != "data") {
            data = mesureRows.filter(
                (row) =>
                    row[mesure.csv_column] &&
                    row[mesure.csv_column].value != undefined &&
                    row[mesure.csv_column].value != null
            );
            values = data.map((row) => row[mesure.csv_column].value);
          } else {
            data = mesureRows.filter(
                (row) =>
                    row[mesure.csv_column] != undefined && row[mesure.csv_column] != null
            );

            values = data.map((row) => row[mesure.csv_column]);
          }
          this.min = Math.min(this.min, Math.min(...values));
          this.max = Math.max(this.max, Math.max(...values));

          //console.log(mesure);

          let datas = [];
          let times;
          if (yearCompare) {
            if (interval == "year") {
              times = [this.yearCompareQuery.years[yearId]];
            } else {
              times = data.filter((row) => {
                let timestr = row.key_as_string;
                let timedate = toDate(timestr);
                return timedate >= startDate && timedate <= endDate;
              });
              times = times.map((row) => {
                let timestr = row.key_as_string;
                return timestr.substring(0, timestr.length - 5);
              });
            }
          } else {
              const timeOffset = this.site['data_timeoffset']; // Get the time offset

              if (interval == "data") {
                  times = data.map((row) => {
                      let date = toDate(row["@timestamp"]); // Convert the string to Date object
                      date.setHours(date.getHours() + timeOffset); // Add dynamic number of hours
                      return convertDateToStringWithMinutes(date); // Return the formatted string
                  });
              } else if(interval == "minute" || interval == "hour") {
                  times = data.map((row) => {
                      // Extract date and time from the row.key_as_string, which is in format "DD-MM-YYYY HH:MM"
                      let [datePart, timePart] = row.key_as_string.split(' '); // Split into date and time parts
                      let [hours, minutes] = timePart.split(':'); // Extract hours and minutes

                      // Convert datePart into a JavaScript Date object
                      let [day, month, year] = datePart.split('-'); // Split the date part (DD-MM-YYYY)
                      let date = new Date(`${year}-${month}-${day}T${timePart}:00`); // Create Date object from the string

                      // Add the time offset to the date's hours
                      date.setHours(date.getHours() + timeOffset);

                      // Reconstruct the updated date and time back into the original format "DD-MM-YYYY HH:MM"
                      let newDatePart = date.toLocaleDateString('en-GB'); // Format the date part as "DD-MM-YYYY"
                      let newTimePart = date.toTimeString().slice(0, 5); // Extract "HH:MM" from the time part

                      // Return the adjusted date and time in the format "DD-MM-YYYY HH:MM"
                      return `${newDatePart} ${newTimePart}`;
                  });
              } else {
                  times = data.map((row) => row.key_as_string);
              }
          }

          let i = 0;

          let calculator = null;
          if(mesure.calcForm != null){
            calculator = mesure.calcForm.split('!');
            calculator = calculator.filter((value) => value !== "");
          }

          for (let time of times) {

            let finalValue = values[i];
            let realValue = values[i];
            let allowCalc = true;
            let show = true;

            if(calculator != null){
              for(let j = 0; j < calculator.length - 1; j = j + 2){

                if(calculator[j] == ">") {
                  allowCalc = realValue > calculator[j + 1];
                }
                else if(calculator[j] == ">="){
                  allowCalc = realValue >= calculator[j + 1];
                }
                else if(calculator[j] == "<"){
                  allowCalc = realValue < calculator[j + 1];
                }
                else if(calculator[j] == "<="){
                  allowCalc = realValue <= calculator[j + 1];
                }
                else if(calculator[j] == "sh>") {
                  allowCalc = realValue > calculator[j + 1];
                  show = allowCalc;
                }
                else if(calculator[j] == "sh>="){
                  allowCalc = realValue >= calculator[j + 1];
                  show = allowCalc;
                }
                else if(calculator[j] == "sh<"){
                  allowCalc = realValue < calculator[j + 1];
                  show = allowCalc;
                }
                else if(calculator[j] == "sh<="){
                  allowCalc = realValue <= calculator[j + 1];
                  show = allowCalc;
                }
                else if(calculator[j] == "f>") {
                  allowCalc = finalValue > calculator[j + 1];
                }
                else if(calculator[j] == "f>="){
                  allowCalc = finalValue >= calculator[j + 1];
                }
                else if(calculator[j] == "f<"){
                  allowCalc = finalValue < calculator[j + 1];
                }
                else if(calculator[j] == "f<="){
                  allowCalc = finalValue <= calculator[j + 1];
                }

                if(allowCalc){
                  if(calculator[j] == "+"){
                    finalValue += parseFloat(calculator[j+1]);
                  } else if(calculator[j] == "-"){
                    finalValue -= parseFloat(calculator[j+1]);
                  } else if(calculator[j] == "/"){
                    finalValue /= parseFloat(calculator[j+1]);
                  } else if(calculator[j] == "*"){
                    finalValue *= parseFloat(calculator[j+1]);
                  }
                }
              }
            }

            if(show){
              datas.push({ x: time, y: this.roundDecimal(finalValue, 1) });
            }
            i++;
          }

          dataset.data = [... datas];

          if (m > 0) {
            dataset.borderDash = [m, m];
          }
          if (!yearCompare && this.isBarChart(axe)) {
            dataset.borderColor = getAlteredColor(axe.color.hex, m * 30);
            dataset.type = "bar";
          } else if (this.isLineChart(axe)) {
            dataset.showLine = true;
            //dataset.backgroundColor = (axe.color.hex.length === 8 ? axe.color.hex.substr(0,6) + '80' : axe.color.hex + '80');
            dataset.fill = false;
            dataset.type = "line";
            dataset.lineTension = 0.5;
          } else if (this.isRadarChart(axe)){
            dataset.type = "radar";
          }

          this.chartData.datasets.push(dataset);

          console.log(axe.hasOwnProperty("allowMinMaxAxes"));

          if (mesure.min && !yearCompare) {
            if(axe.hasOwnProperty("allowMinMaxAxes")) {
                if(axe.allowMinMaxAxes === true){
                    this.initHorizontalMin(k, startDate, endDate, mesure);
                }
            } else {
                this.initHorizontalMin(k, startDate, endDate, mesure);
            }
          }

          if (mesure.max && !yearCompare) {
            if(axe.hasOwnProperty("allowMinMaxAxes")) {
                if(axe.allowMinMaxAxes === true){
                    this.initHorizontalMax(k, startDate, endDate, mesure);
                }
            } else {
                this.initHorizontalMax(k, startDate, endDate, mesure);
            }
          }

          this.curveId++;
          m++;
        }
      }

      if(this.site.calcForm != null){

        let finalForm = this.removeSubstringBetweenSameChars(this.site.calcForm, "^");
        let readableFinalForm = "";
        let calcFormName = "Formule de calcul";

        if(Array.isArray(finalForm)){
          readableFinalForm = finalForm[1];
          calcFormName = finalForm[0];
        } else {
          readableFinalForm = finalForm;
        }

        let splitCalcForm = readableFinalForm.split(";");
        let filteredDataset = this.chartData.datasets.filter(item => item.label === splitCalcForm[0]);
        let newDataset = {"yAxisID":"calcFormAxe","label": calcFormName,"borderColor":"#7A0313","backgroundColor":"#7A0313","borderWidth":2,"data":[],"order":(this.chartData.datasets[this.chartData.datasets.length-1].order) - 1,"showLine":true,"fill":false,"type":"line","lineTension":0.5};

        for(let j = 0; j < filteredDataset[0].data.length; j++){
          let currentData = filteredDataset[0].data[j];
          let calcResult = 0
          for(let i = 0; i < splitCalcForm.length; i++){

            //console.log("--- debug CalcForm: " + i + " ---");

            if(splitCalcForm[i] !== "+" && splitCalcForm[i] !== "-" && splitCalcForm[i] !== "/" && splitCalcForm[i] !== "*"){

              let checkerValue = parseFloat(splitCalcForm[i])

              if(!isNaN(checkerValue)){
                switch(splitCalcForm[i-1]){
                  case "+":
                    calcResult = calcResult + checkerValue;
                    break;
                  case "-":
                    calcResult = calcResult - checkerValue;
                    break;
                  case "/":
                    calcResult = calcResult / checkerValue;
                    break;
                  case "*":
                    calcResult = calcResult * checkerValue;
                    break;
                }
              } else {
                for (let nextData of this.chartData.datasets.filter(item => item.label === splitCalcForm[i])) {
                  let valuesData = nextData.data.filter(item => item.x === currentData.x);
                  let currentValue = parseFloat(valuesData[0].y);

                  if (i === 0) {
                    calcResult = currentValue;
                  } else {
                    switch (splitCalcForm[i - 1]) {
                      case "+":
                        calcResult = calcResult + currentValue;
                        break;
                      case "-":
                        calcResult = calcResult - currentValue;
                        break;
                      case "/":
                        calcResult = calcResult / currentValue;
                        break;
                      case "*":
                        calcResult = calcResult * currentValue;
                        break;
                    }
                  }
                }
              }
            }
          }
          newDataset.data.push({"x": currentData.x, "y": calcResult});
        }
        this.chartData.datasets.push(newDataset);
      }
    },
    isBarChart(axe) {
      return axe.type == "Bars" || axe.type == "BarChart";
    },
    isLineChart(axe) {
      return axe.type == "Lignes" || axe.type == "LineChart";
    },
    isRadarChart(axe) {
      return axe.type == "Radar" || axe.type == "RadarChart";
    },

    initHorizontalMax(k, startDate, endDate, mesure) {
      this.max = Math.max(this.max, mesure.max);
      let maxDataset = {
        yAxisID: k,
        label: "Max " + mesure.csv_column,
        borderColor: "#F44336", //red
        data: [
          { x: convertDate(startDate), y: mesure.max },
          {
            x: convertDate(endDate),
            y: mesure.max,
          },
        ],
        type: "line",
        showLine: true,
        fill: false,
        pointRadius: 0,
      };
      this.chartData.datasets.push(maxDataset);
    },

    initHorizontalMin(k, startDate, endDate, mesure) {
      this.min = Math.min(this.min, mesure.min);
      let minDataset = {
        yAxisID: k,
        label: "Min " + mesure.csv_column,
        borderColor: "#00EDFF", //light blue
        data: [
          { x: convertDate(startDate), y: mesure.min },
          {
            x: convertDate(endDate),
            y: mesure.min,
          },
        ],
        type: "line",
        showLine: true,
        fill: false,
        pointRadius: 0,
      };
      this.chartData.datasets.push(minDataset);
    },

    initGraphicEventData(updateId, startDate, endDate, interval, yearCompare, yearId) {
      if (this.min == Number.MAX_VALUE) {
        this.min = 0;
      }
      if (this.max == -Number.MAX_VALUE) {
        this.max = 1;
      }
      let elasticQueryMust = buildQueryMust(
        this.visualization.site_id,
        this.children_site_ids,
        this.filters
      );
      let promise = new Promise((resolve, reject) => {
        let elasticQueryEvents = {
          from: 0,
          size: 1000,
          query: {
            bool: {
              must: elasticQueryMust,
            },
          },
        };

        this.$store
          .dispatch("elasticSearchRequest", {
            index: this.elastic_winery_events_index,
            site: this.visualization.site_id,
            body: elasticQueryEvents,
          })
          .then((result) => {
            if (updateId != this.updateId) {
              resolve();
            }
            let minDate = startDate;
            for (let hit of result.data.hits.hits) {
              let eventsData = hit._source;
              for (const [key, value] of Object.entries(eventsData)) {
                this.addGraphicEvent(
                  key,
                  value,
                  startDate,
                  endDate,
                  interval,
                  minDate,
                  yearCompare
                );
              }
            }
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
      return promise;
    },

    initGraphicEventDataFormSaisiesPromise(
      updateId,
      startDate,
      endDate,
      interval,
      yearCompare,
      yearId
    ) {
      let promise = new Promise((resolve, reject) => {
        //READ events from mesures saisies
        let elasticQuery = buildTimeRangeQuery(
          startDate,
          endDate,
          null,
          this.visualization.site_id,
          ["events"],
          this.filters,
          this.children_site_ids,
          true
        );

        this.$store
          .dispatch("elasticSearchRequest", {
            index: this.elastic_winery_mesures_index,
            body: elasticQuery,
            size: 1000000,
            site: this.visualization.site_id,
          })
          .then((result) => {
            if (updateId != this.updateId) {
              resolve();
            }
            if (
              result.data &&
              result.data.hits &&
              result.data.hits.hits &&
              result.data.hits.hits.length > 0
            ) {
              let minDate = startDate;
              for (let hit of result.data.hits.hits) {
                let source = hit._source;
                if (source.events && source["@timestamp"]) {
                  for (let event of source.events) {
                    this.addGraphicEvent(
                      event,
                      source["@timestamp"],
                      startDate,
                      endDate,
                      interval,
                      minDate,
                      yearCompare
                    );
                  }
                }
              }
            }
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
      return promise;
    },
    addGraphicEvent(key, value, startDate, endDate, interval, minDate, yearCompare) {
      if (!this.visualization.events.includes(key)) {
        return;
      }
      let valueDate = toDate(value);
      if (!valueDate) {
        return;
      }
      if (
        (startDate && startDate.getTime() > valueDate.getTime()) ||
        (endDate && endDate.getTime() < valueDate.getTime())
      ) {
        return;
      }
      if (!minDate || valueDate.getTime() < minDate.getTime()) {
        minDate = valueDate;
      }
      let date = value;
      if (interval != "hour" && interval != "minute" && interval != "data") {
        date = convertDate(valueDate);
      } else {
        date = convertDateToStringWithMinutes(valueDate);
        //TODO  elastic timestamp to dd-mm-yyyy hh:mm graphic label format !
      }

      let label = key;
      let borderColor = getColor(key);

      if (yearCompare) {
        date = convertDate(valueDate);
        label += " " + startDate.getFullYear();
        let year = this.dateYear(date);
        borderColor = getColor(year);
        date = this.dateWithoutYear(date);
      }

      let annotation = {
        type: "line",
        // id: "event" + this.eventIndex,
        // mode: "vertical",
        scaleID: "x",
        value: date,
        borderWidth: 3,
        borderColor,
        label: {
          content: convertDate(valueDate) + " : " + key,
          enabled: false,
          position: "top",
        },
        enter(context) {
          const element = context.element;
          element.options.label.enabled = true;
          context.chart.draw();
          // context.chart.canvas.style.cursor = "pointer";
        },
        leave(context) {
          const element = context.element;
          element.options.label.enabled = false;
          context.chart.draw();
          // context.chart.canvas.style.cursor = "initial";
        },
      };

      /*if (!this.chartOptions.plugins.annotation) {
        this.chartOptions.plugins.annotation = {
          annotations: [],
        };
      }*/
      this.chartOptions.plugins.annotation.annotations = {... this.chartOptions.plugins.annotation.annotations, annotation};

      this.eventIndex++;
    },

    setGraphicLabels(minDate, maxDate, interval, yearCompare) {
      let chartUnit = "minute";
      if (interval != "data") {
        chartUnit = interval;
      }
      if (
        interval != "data" &&
        this.visualization.events &&
        (interval == "month" || interval == "week")
      ) {
        chartUnit = "day";
      }
      let scales = this.chartOptions.scales;
      scales.x.type = "time";
      if (yearCompare) {
        let minD = Object.assign(minDate);
        let maxD = Object.assign(maxDate);
        minD.setFullYear(2000);
        maxD.setFullYear(2000);

        let parser = "DD-MM";
        scales.x.type = "category";
        if (interval == "year") {
          this.chartData.labels = [...this.yearCompareQuery.years];
        } else if (interval == "month") {
          this.chartData.labels = getDates(minD, maxD, interval).map((date) => {
            let datestr = convertDate(date);
            return this.dateWithoutYear(datestr);
          });
          // console.log(this.chartData.labels);
        } else {
          this.chartData.labels = getDates(minD, maxD, "").map((date) => {
            let datestr = convertDate(date);
            return this.dateWithoutYear(datestr);
          });
        }

        scales.x.time = {
          parser,
          unit: chartUnit,
          displayFormats: {
            year: "YYYY",
            month: "MMM",
            week: "DD-MM",
            day: "DD-MM",
          },
        };
      } else {
        if (interval == "minute" || interval == "hour") {
          this.chartData.labels = getDates(minDate, maxDate, interval);
        } else if (interval != "data") {
          this.chartData.labels = getDates(minDate, maxDate, interval).map((date) => {
            return convertDate(date);
          });
        }
      }
      if (!yearCompare)
        scales.x = {
          ...scales.x,
          min: convertDateToStringWithMinutes(minDate),
          max: convertDateToStringWithMinutes(maxDate),
        };

      // if (interval == "data") {
      //   // scales[0].ticks.source = "auto";
      // }
      scales.x.time.unit = chartUnit;
    },

    dateWithoutYear(datestr) {
      return datestr.substring(0, datestr.length - 5);
    },

    dateYear(datestr) {
      return datestr.substring(datestr.length - 4, datestr.length);
    },

    getNextColor() {
      this.colorIndex = (this.colorIndex + 1) % this.colors.length;
      return this.colors[this.colorIndex];
    },
    roundDecimal(nombre, precision = 2) {
      let tmp = Math.pow(10, precision);
      return Math.round(nombre * tmp) / tmp;
    },
    showErrEventDataLoading(err) {
      {
        if(this.$route.query.logging === "true"){
          let err2 = "Event data loading error : " + err;
          console.log(err2);
          if (this.$store.state.profile.user.role === 'Admin' || this.$store.state.profile.user.role === 'admin') {
            this.showErr(err2);
          }
        }
      }
    },
    showErrMesuresDataLoading(err) {
      {
        if(this.$route.query.logging === "true"){
          let err2 = "Mesure data loading error : " + err;
          console.log(err2);
          if (this.$store.state.profile.user.role === 'Admin' || this.$store.state.profile.user.role === 'admin') {
            this.showErr(err2);
          }
        }
      }
    },
    showErr(err) {
      {
        this.$store.commit("setSnack", {
          type: "red",
          msg: err,
        });
      }
    },
  },
};
</script>
