import Vue from 'vue'

const state = {
    status: '',
}

const getters = {}

const actions = {
    sensorTypeRequest: async ({commit}, queries) => {
        let url = `/api/sensor-types/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('sensorTypeRequest')
            Vue.axios.get(url)
                .then(result => {
                    commit('sensorTypeSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('sensorTypeError');
                    reject(err)
                })
        })
    },
    sensorTypeEditRequest: async ({commit}, queries) => {
        let url = `/api/sensor-types/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('sensorTypeRequest')
            Vue.axios.put(url, queries.datas)
                .then(result => {
                    commit('sensorTypeSuccess', result.data.data)
                    resolve(result.data.data)
                })
                .catch(err => {
                    commit('sensorTypeError');
                    reject(err)
                })
        })
    },
    sensorTypeCreateRequest: async ({commit}, queries) => {
        let url = `/api/sensor-types`;
 
        return new Promise((resolve, reject) => {
            commit('sensorTypeRequest')
            Vue.axios.post(url, queries)
                .then(result => {
                    commit('sensorTypeSuccess', result.data.data)
                    resolve(result.data.data)
                })
                .catch(err => {
                    commit('sensorTypeError');
                    reject(err)
                })
        })
    },
    sensorTypeDeleteRequest: async ({commit}, queries) => {
        let url = `/api/sensor-types/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('sensorTypeRequest')
            Vue.axios.delete(url)
                .then(result => {
                    commit('sensorTypeDeleteSuccess')
                    resolve(result)
                })
                .catch(err => {
                    commit('sensorTypeError');
                    reject(err)
                })
        })
    },
    sensorTypesRequest: async ({commit, dispatch}, queries) => {
        //remove null values
        queries = Object.entries(queries).reduce((a,[k,v]) => (v ? {...a, [k]:v} : a), {})
        //build query url
        let url = '/api/sensor-types?'+Object.entries(queries).map(e => e.join('=')).join('&')

        return new Promise((resolve, reject) => {
            commit('sensorTypeRequest')
            Vue.axios.get(url)
                .then(result => {
                    commit('sensorTypesSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('sensorTypeError');
                    reject(err)
                })
        })
    },
}



const mutations = {
    sensorTypeRequest: (state) => {
        state.status = 'loading';
    },
    sensorTypesSuccess: (state, resp) => {
        state.status = 'success';
    },
    sensorTypeSuccess: (state, resp) => {
        state.status = 'success';
    },
    sensorTypeDeleteSuccess: (state) => {
        state.status = 'success';
    },
    sensorTypeError: (state) => {
        state.status = 'error';
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}