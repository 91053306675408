import { dateFormatFrenchPretty, convertDateToElasticTimeStamp, dateFormatFrenchPrettyWithMinutes } from "@/utils/dates";

export const buildQuery = (site_id, children_site_ids, timesort) => {

    let elasticQueryMust = buildQueryMust(site_id, children_site_ids);

    let elasticQuery = { query: { bool: { must: elasticQueryMust } } };

    if (timesort) {
        let elasticQuerySort = {
            sort: [
                {
                    "@timestamp": {
                        order: timesort
                    }
                }
            ]
        }
        elasticQuery = { ...elasticQuery, ...elasticQuerySort };
    }

    return elasticQuery;
}

export const buildSortSizeMoreQuery = (site_id, children_site_ids, timesort, mesure, maxValue) => {

    let elasticQueryMust = buildQueryMust(site_id, children_site_ids);
    let elasticQuery = { query: { bool: { must: elasticQueryMust } } };
    let elasticDateFormat = dateFormatFrenchPretty;

    if (timesort) {
        let elasticQuerySort = {
            sort: [
                {
                    "@timestamp": {
                        order: timesort
                    }
                }
            ],
            _source: mesure
        }

        elasticQuery.query.bool.filter = [{
            range: {}
        }];

        elasticQuery.query.bool.filter[0].range[mesure] = {
            gt: maxValue
        };

        elasticQuery = { ...elasticQuery, ...elasticQuerySort };
    }

    return elasticQuery;
}

export const buildSortSizeQuery = (site_id, children_site_ids, timesort, mesure) => {

    let elasticQueryMust = buildQueryMust(site_id, children_site_ids);
    let elasticQuery = { query: { bool: { must: elasticQueryMust } } };
    let elasticDateFormat = dateFormatFrenchPretty;

    if (timesort) {
        let elasticQuerySort = {
            sort: [
                {
                    "@timestamp": {
                        order: timesort
                    }
                }
            ],
            _source: mesure
        }

        elasticQuery = { ...elasticQuery, ...elasticQuerySort };
    }

    return elasticQuery;
}

export const buildQueryMust = (site_id, children_site_ids, filters) => {
    let elasticQueryMust = [];

    if (children_site_ids && children_site_ids.length > 0) {
        let site_ids = [...children_site_ids, site_id];
        elasticQueryMust.push({ terms: { Site: site_ids } });
    } else {
        elasticQueryMust = [{ term: { Site: site_id } }]
    }

    if (filters && filters.parcelles && filters.parcelles.length > 0) {
        elasticQueryMust.push(
            {
                bool: {should: [
                    {
                        "terms": {
                            "Parcelle_name.keyword": filters.parcelles
                        }
                    },
                    {
                        "terms": {
                            "Parcelle.keyword": filters.parcelles
                        }
                    }
                ]}
            }
        );
    }

    if (filters && filters.cepages && filters.cepages.length > 0) {
        elasticQueryMust.push({
            "terms": {
                "Cepage.keyword": filters.cepages
            }
        });
    }

    if (filters && filters.traitements && filters.traitements.length > 0) {
        elasticQueryMust.push({
            "terms": {
                "TypeMaladie.keyword": filters.traitements
            }
        });
    }

    return elasticQueryMust;
}

export const buildTimeRangeQuery = (startDate, endDate, interval, site_id, columns, filters, children_site_ids, eventsOnly = false) => {
    let elasticQuery = {};
    let elasticQueryMust = buildQueryMust(site_id, children_site_ids, filters);

    let elasticQueryFilter = [];
    if (eventsOnly) {
        let exists = {};
        exists["field"] = "events";
        elasticQueryFilter.push({
            exists,
        });
    }

    let dateField = "@timestamp";


    if (startDate || endDate) {
        //elasticQueryFilter['range'] = { "Datetime": {} }
        if (startDate) {
            let start = Object.assign(startDate);
            // start.setHours(start.getHours() - 1);
            let gte = getElasticTimestamp(start, dateField)
            let range = {};
            range[dateField] = { gte };
            elasticQueryFilter.push({
                range,
            });
        }

        if (endDate) {
            let end = Object.assign(endDate);
            // end.setHours(end.getHours() + 1);
            let lte = getElasticTimestamp(end, dateField);
            let range = {};
            range[dateField] = { lte };
            elasticQueryFilter.push({
                range,
            });
        } else endDate = new Date();
    }

    let elasticQueryAggs;
    if (interval && interval != "data") {

        let elasticInterval = interval;
        if (elasticInterval === "hour") {
            elasticInterval = "1h";
        } else if (elasticInterval === "minute") {
            elasticInterval = "1m";
        }


        let elasticDateFormat = dateFormatFrenchPretty;
        if (interval === "hour") {
            elasticDateFormat = dateFormatFrenchPrettyWithMinutes;
        } else if (interval === "minute") {
            elasticDateFormat = dateFormatFrenchPrettyWithMinutes;
        }

        elasticQueryAggs = {
            time_buckets: {
                date_histogram: {
                    field: dateField,
                    calendar_interval: elasticInterval,
                    format: elasticDateFormat,
                },
                aggs: {},
            },
        };
        for (let k in columns) {
            let displayedSensor = columns[k];
            let aggregationsType = "avg";
            if (displayedSensor.aggregationsType == 'Moyenne') aggregationsType = 'avg';
            else if (displayedSensor.aggregationsType == 'Cumul') aggregationsType = 'sum';

            elasticQueryAggs.time_buckets.aggs[displayedSensor.csv_column] = {
            };
            elasticQueryAggs.time_buckets.aggs[displayedSensor.csv_column][aggregationsType] = {
                field: displayedSensor.csv_column,
            }
        }
    }

    elasticQuery = {
        ...elasticQuery,
        query: { bool: { must: elasticQueryMust, filter: elasticQueryFilter } },
    };
    if (elasticQueryAggs) {
        elasticQuery = { ...elasticQuery, aggs: elasticQueryAggs };
    }
    let elasticQuerySort = {};
    elasticQuerySort['@timestamp'] = "asc";
    return { ...elasticQuery, sort: elasticQuerySort };
}



const getElasticTimestamp = (date, format) => {
    let d = new Date(date);
    //GMT+1...
    // d.setHours(d.getHours() + 1);
    let timestamp;
    if (format == "@timestamp") {
        timestamp = convertDateToElasticTimeStamp(d);
        // timestamp = d.toISOString().split(".")[0].split("T")[0] + "T00:00:00.000Z";

    } else {
        timestamp = d.getTime() / 1000;
    }
    return timestamp;
}

