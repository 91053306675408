<template>
  <BarChart v-bind="barChartProps" />
</template>

<script>
import { BarChart, useBarChart } from "vue-chart-3";
import { ref, reactive, computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Chart3",
  components: {
    BarChart,
  },
  props: {
    chartData: {
      type: Object,
      required: false,
    },
    options: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    // const { barChartProps, barChartRef } = useBarChart({
    //   chartData: props.chartData,
    //   options,
    //   height:500,
    //   // styles: {height: "100%"}
    // });
    const { barChartProps, barChartRef } = useBarChart({
      chartData: props.chartData,
      options: props.options,
      height:500,
      // styles: {height: "100%"}
    });
    return { barChartProps, barChartRef };
  },
});
</script>
