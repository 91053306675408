import { getRandomColor } from "../utils/colors";

export const STADES_PHENO = [
    "Debourrement",
    "Nouaison",
    "PointeVerte",
    "MiFloraison",
    "SortieFeuilles",
    "DeuxTroisFeuillesEtalees",
    "PleineFleur",
    "FermetureGrappes",
    "Maturite",
    "GrappesVisibles",
    "GrappesSeparees",
    "DebutVeraison",
    "MiVeraison",
    "DebutFloraison",
    "Veraison",
    "DateDebutCS",
    "BoutonsFlorauxSeparees",
    "DateDebutVendanges",
    "DateFinVendanges",
]
// {"names": ["Debourrement", "Nouaison", "PointeVerte", "MiFloraison", "SortieFeuilles", "DeuxTroisFeuillesEtalees", "PleineFleur", "FermetureGrappes", "Maturite", "GrappesVisibles", "GrappesSeparees", "DebutVeraison", "MiVeraison", "DebutFloraison", "Veraison", "DateDebutCS", "BoutonsFlorauxSeparees", "DateDebutVendanges", "DateFinVendanges"]}

const STADES_PHENO_COLORS = [
    "#F89B02",
    "#ECA007",
    "#DFA50C",
    "#D3AA11",
    "#C7AF16",
    "#BAB41B",
    "#AEB920",
    "#A1BE25",
    "#95C32A",
    "#88C82F",
    "#7CCD34",
    "#6FD239",
    "#63D73E",
    "#57DC43",
    "#4AE148",
    "#3EE64D",
    "#31EB52",
    "#25F057",
    "#18F55C",
    "#0CFA61"
]

let session_colors = {};


export function getColor(stadepheno) {
    let i = STADES_PHENO.indexOf(stadepheno);
    if (i >= 0 && i < STADES_PHENO_COLORS.length) {
        return STADES_PHENO_COLORS[i]
    } else {
        let hasColorInSession = session_colors.hasOwnProperty(stadepheno);
        if (hasColorInSession) {
            return session_colors[stadepheno];
        } else {
            let color = getRandomColor();
            session_colors[stadepheno] = color;
            return color;
        }
    }

}

