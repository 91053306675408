import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from './../router'
import store from './../vuex/store'
 
Vue.use(AclInstaller)

export default new AclCreate({
    initial: 'public',
    notfound: {
        path: '/unauthorized'
    },
    router,
    acceptLocalRules: true,
    globalRules: {
        isAdmin: new AclRule('admin').generate(),
        isUser: new AclRule('user').or('admin').or('user_admin').or('user_super').or('user_conf').generate(),
        isPublic: new AclRule('public').generate(),
    },
    middleware: async acl => {
        let profile = store.getters.getProfile
        if (profile) {
            return acl.change(profile.role)
        }
        return acl.change('public')
    }
})