import Vue from 'vue'
import { convertAxes } from '@/utils/axes';


const getUserInLocalStorage = () => {
    let user = '';
    if (localStorage.getItem('profile')) {
        try {
            user = JSON.parse(localStorage.getItem('profile'));
            if(!user.hasOwnProperty('homeType')){
                user.homeType = 'S1';
                localStorage.setItem('profile', JSON.stringify(user));
            }
        } catch (err) {
            console.log(err);
            user = '';
        }
    }
    return user;
}
const state = {
    status: '',
    user: getUserInLocalStorage(),
}

const getters = {
    getProfile: state => state.user,
    isProfileLoaded: state => !!state.user.name,
}

const actions = {
    profileRequest: async ({ commit, dispatch }) => {
        commit('profileRequest')
        await Vue.axios.get('/api/user')
            .then((resp) => {
                try {
                    if(!resp.data.data.hasOwnProperty('homeType')){
                        resp.data.data.homeType = 'S1';
                    }
                    if (resp.data.data.visualizations) {
                        resp.data.data.visualizations = JSON.parse(resp.data.data.visualizations);
                    }
                    if (resp.data.data.sites) {
                        for (let site of resp.data.data.sites) {
                            if (site.events) {
                                site.events = JSON.parse(site.events);
                            }
                            else if(!site.events || !site.events.names){
                                site.events = {names: []}
                            }
                            if (site.categories) {
                                site.categories = JSON.parse(site.categories);
                            }
                            else if(!site.categories || !site.categories.names){
                                site.categories = {names: []}
                            }
                            if (site.visualizations) {
                                for (let v of site.visualizations) {
                                    if (v.axes) {
                                        v.axes = JSON.parse(v.axes);
                                        convertAxes(v.axes.axes);
                                    }
                                    if (v.events) {
                                        v.events = JSON.parse(v.events);
                                    }
                                }
                            }
                            if (site.relations) {
                                site.relations = JSON.parse(site.relations);
                            }
                            if (site.ftp_pwd) {
                                site.ftp_pwd = "";

                            }
                        }
                    }

                    localStorage.setItem('profile', JSON.stringify(resp.data.data));
                    commit('profileSuccess', resp.data);
                } catch (err) {
                    console.log(err);
                    commit('profileError');
                    // if resp is unauthorized, logout, to
                    dispatch('authLogout');
                }
            })
            .catch(() => {
                commit('profileError')
                // if resp is unauthorized, logout, to
                dispatch('authLogout')
            })
    },
}

const mutations = {
    profileRequest: (state) => {
        state.status = 'loading'
    },
    profileSuccess: (state, resp) => {
        state.status = 'success'
        Vue.set(state, 'user', resp.data)
    },
    profileRemove: (state) => {
        localStorage.removeItem('profile')
        Vue.set(state, 'profile', null)
    },
    profileError: (state) => {
        state.status = 'error'
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
