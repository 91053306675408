<template>
  <div>
    <v-row>
      <v-col cols="6">
        <span class="subtitle black--text font-size-20">Événements</span>
        <v-text-field v-model="newEvent" label="Nouvel événement" clearable></v-text-field>
        <v-col cols="6" class="d-flex flex-column align-start pl-0">
        <v-btn v-if="editable" small depressed @click.stop="addEvent">
          <v-icon left>check</v-icon> Valider l'événement
        </v-btn>
      </v-col>
        <v-row v-if="events && events.length > 0">
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <p>Liste des événements :</p>
                  <tr v-for="(event, i) in events" :key="i">
                    <td>
                      {{ event }}
                    </td>
                    <td class="text-right" v-if="editable">
                      <v-icon @click.stop="deleteEvent(event)"
                        >close</v-icon
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    events: Array,
    editable: { type: Boolean, default: false },
  },
  data() {
    return {
      newEvent: "",
    };
  },
  methods: {
    deleteEvent(event) {
      if (confirm("Êtes-vous sûr de vouloir supprimer cet événement ?")) {
        this.$emit("delete", event);
      }
    },
    addEvent() {
      if (this.newEvent.length > 0) {
      this.$emit("add", this.newEvent)
      this.newEvent = '';
      }
      else {
        window.alert("L'événement doit être rempli");
      }
    },
  },
};
</script>
