import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import Vuetify from 'vuetify'
// import 'vuetify/dist/vuetify.min.css'

Vue.use( Vuetify )
 
export default new Vuetify({
    icons: {
        iconfont: 'md'
    },
    theme: {
        themes: {
            light: {
                primary: '#972b34',
                secondary: '#88c546'
            },
            dark: {
                primary: '#972b34',
                secondary: '#88c546'
            }
        }
    }
})
