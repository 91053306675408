import Vue from 'vue'

const MESSAGE_MAX_LENGTH = 300;
const state = {
    // 
}

const getters = {
    // 
}

const rejectError = (reject, error) => {
    let message = error;
    if (error.response) {
        message = error.response;
        if (error.response.data) {
            message = error.response.data;
        }
        if (error.response.data.message) {
            message = error.response.data.message;
        }
    }

    if (message.length > MESSAGE_MAX_LENGTH) {
        message = message.substr(0, MESSAGE_MAX_LENGTH) + '...';
    }
    reject(message)
}

const actions = {
    elasticSearchRequest: async ({ commit, dispatch }, queries) => {
        return new Promise((resolve, reject) => {
            let url = '/api/elastic/search'
            Vue.axios.post(url, queries)
                .then((result) => {
                    resolve(result)
                })
                .catch((error) => {
                    rejectError(reject, error);
                })
        })
    },
    elasticDownloadRequest: async ({ commit, dispatch }, queries) => {
        return new Promise((resolve, reject) => {
            let url = '/api/elastic/download'
            Vue.axios.post(url, queries)
                .then((result) => {
                    resolve(result)
                })
                .catch((error) => {
                    rejectError(reject, error);
                })
        })
    },
    elasticCreateRequest: async ({ commit, dispatch }, queries) => {
        return new Promise((resolve, reject) => {
            let url = '/api/elastic/create'
            Vue.axios.post(url, queries)
                .then((result) => {
                    resolve(result)
                })
                .catch((error) => {
                    rejectError(reject, error);
                })
        })
    },
    elasticGetRequest: async ({ commit, dispatch }, queries) => {
        return new Promise((resolve, reject) => {
            let url = '/api/elastic/get'
            Vue.axios.get(url, { params: queries })
                .then((result) => {
                    resolve(result)
                })
                .catch((error) => {
                    rejectError(reject, error);

                })
        })
    },
    elasticDeleteRequest: async ({ commit, dispatch }, queries) => {
        return new Promise((resolve, reject) => {
            let url = `/api/elastic/delete`;
            Vue.axios.delete(url, { params: queries })
                .then((result) => {
                    resolve(result)
                })
                .catch((error) => {
                    rejectError(reject, error);
                })
        })
    },
    /**
     * 
     * @param {*} payload let payload = {
        clientId: "5",
        site: "23",
        startDay: "01-01",
        endDay: "12-31",
        operations: [
          {
            op_type_day: "max",
            op_type_year: "sum",
            field_name: "TemperatureMaxiCelsius",
          },
          { op_type_day: "sum", op_type_year: "sum", field_name: "Precipitation_mm" },
          {
            op_type_day: "avg",
            op_type_year: "sum10",
            field_name: "TemperatureMoyCelsius",
          },
        ],
        cepage: "MN",
        events: ["MiFloraison", "MiVeraison"],
      }; 
     * @returns 
     */
    elasticNearestYearsRequest: async ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            let url = `/api/elastic/nearestyears`;
            Vue.axios.post(url, payload)
                .then((result) => {
                    resolve(result)
                })
                .catch((error) => {
                    rejectError(reject, error);
                })
        })
    },
}


const mutations = {
    // 
}

export default {
    state,
    getters,
    actions,
    mutations,
}