<template>
  <v-list-item
    class="clickable-rows"
    @click="
      $router.push({
        name: 'Dashboard',
        params: { site_id: value.id },
      })
    "
    v-if="$isMobile()"
  >
    <v-row>
      <v-col class="site_icon">
          <v-list-item-avatar color="white" size="46" v-if="value.logo_url">
              <img alt="" :src="value.logo_url" />
          </v-list-item-avatar>
      </v-col>
      <v-col data-label="Nom" class="text-center div-centered" id="site_row_name">{{
          value.name
          }}</v-col>
      <v-responsive width="100%"></v-responsive>
      <v-col class="text-right">
        <v-icon
                class="mr-2"
                @click.stop="
                  $router.push({
                    name: 'Dashboard',
                    params: { site_id: value.id },
                  })
                "
        >dashboard</v-icon
        >
        <v-icon class="mr-2" @click.stop="showItem()">info</v-icon>
        <v-icon class="mr-2" @click.stop="applySiteID()">preview</v-icon>
        <template v-if="user.role == 'admin' || user.role == 'user_admin'">
            <v-icon class="mr-2" @click.stop="editItem()">edit</v-icon>
            <v-icon @click.stop="deleteItem()">delete</v-icon>
        </template>
      </v-col>
    </v-row>
  </v-list-item>
  <v-list-item
          class="clickable-rows-desktop"
          @click="
            $router.push({
              name: 'Dashboard',
              params: { site_id: value.id },
            })
          "
          v-else
  >
    <v-row>
      <v-col class="site_icon">
        <v-list-item-avatar color="white" size="46" v-if="value.logo_url">
            <img alt="" :src="value.logo_url" />
        </v-list-item-avatar>
      </v-col>
      <v-col data-label="Nom" class="text-center div-centered" id="site_row_name">{{
          value.name
          }}</v-col>
      <v-col class="text-right">
        <v-icon
                class="mr-2"
                @click.stop="
                $router.push({
                    name: 'Dashboard',
                    params: { site_id: value.id },
                  })
                "
        >dashboard</v-icon
        >
        <v-icon class="mr-2" @click.stop="showItem()">info</v-icon>
        <v-icon class="mr-2" @click.stop="applySiteID()">preview</v-icon>
        <template v-if="user.role == 'admin' || user.role == 'user_admin'">
            <v-icon class="mr-2" @click.stop="editItem()">edit</v-icon>
            <v-icon @click.stop="deleteItem()">delete</v-icon>
        </template>
      </v-col>
    </v-row>
  </v-list-item>
</template>
<style scoped>
.clickable-rows {
  min-height: 140px;
  max-height: 140px;
  cursor: pointer;
}
.clickable-rows-desktop {
    min-height: 70px;
    max-height: 70px;
    cursor: pointer;
}
.listRow {
    max-height: 70px;
}
#site_row_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 20%;
}

#site_row_name {
  justify-content: left;
}
.div-centered {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.site_icon {
  max-width: 70px;
  min-width: 50px;
}
</style>
<script>
import {EventBus} from "@/event-bus";

export default {
  components: {},
  props: {
    value: Object,
  },
  data() {
    let profile = this.$store.state.profile;
    return {
      user: profile.user,
    };
  },
  computed: {
      mobile() {
          return this.$vuetify.breakpoint.sm
      }
  },
  mounted() {
    // console.log("mount site row with props site : ", this.value);
  },
  methods: {
    editItem() {
      this.$emit("editItem", this.value);
    },
    showItem() {
      this.$emit("showItem", this.value);
    },
    deleteItem() {
      this.$emit("deleteItem", this.value);
    },
    applySiteID(){
      EventBus.$emit('applySiteID', this.value.id);
    }
  },
};
</script>
