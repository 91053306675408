export const getRandomColor = () => {
    return "#" + (Math.random().toString(16) + "0000000").slice(2, 8);
};

//return rgb altered color
export const getAlteredColor = (colorhex, alter = 30) => {
    let color = hexToRGB(colorhex);
    let r = (color.rgba.r * (100 - alter % 100) / 100) % 256;
    let g = (color.rgba.g * (100 - alter % 100) / 100) % 256;
    let b = (color.rgba.b * (100 - alter % 100) / 100) % 256;
    return "rgb(" + r + "," + g + "," + b + ")";
}

function hexToRGB(hex) {
    hex = hex.replace(/#/g, '');
    if (hex.length === 3) {
        hex = hex.split('').map(function (hex) {
            return hex + hex;
        }).join('');
    }
    let result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})[\da-z]{0,0}$/i.exec(hex);
    let color = {};
    color.rgba = {};
    if (result) {
        color.rgba.r = parseInt(result[1], 16);
        color.rgba.g = parseInt(result[2], 16);
        color.rgba.b = parseInt(result[3], 16);
    }
    return color;

}