<template>
  <div class="clients">
    <Navigation />
    <v-main>
      <v-container fluid fill-height>
        <v-layout v-resize="onResize">
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-row class="pb-4 text-center" align="center">
                  <v-col cols="12" md="auto">
                    <h2 class="ml-2 headline">Liste des intégrations configurées</h2>
                  </v-col>

                  <v-spacer></v-spacer>

                  <v-col cols="12" md="auto">
                    <v-text-field
                        v-model="search"
                        clearable
                        hide-details
                        solo-inverted
                        flat
                        prepend-inner-icon="search"
                        label="Rechercher"
                        @input="refreshPagination"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>

                  <v-col cols="12" md="auto">
                    <!-- CREATE/EDIT DIALOG -->
                    <v-dialog
                        v-model="dialogEdit"
                        max-width="1400px"
                        persistent
                        scrollable
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                            color="primary"
                            v-on="on"
                            @click.stop="createItem"
                        >Nouvelle intégration</v-btn
                        >
                      </template>
                      <v-card>
                        <v-card-title class="primary white--text">
                          <template v-if="action == 'create'"
                          >Création</template
                          >
                          <template v-if="action == 'update'"
                          >Modification</template
                          >
                          d'une intégration
                          <v-spacer></v-spacer>
                          <v-btn small text dark @click="dialogEdit = false"
                          >Annuler</v-btn
                          >
                        </v-card-title>
                        <v-card-text>
                          <IntegrationForm
                              ref="SencropForm"
                              v-model="editedIntConf"
                              :action="action"
                              @savedForm="handleSavedIntConfForm"
                              @canceledForm="handleCanceledIntConfForm"
                          />
                        </v-card-text>
                      </v-card>
                    </v-dialog>

                    <!-- SHOW DIALOG -->
                    <v-dialog v-model="dialogShow" ref="dialog" scrollable>
                      <v-card>
                        <v-card-title class="primary py-0">
                          <v-row class="subtitle-2 align-center white--text">
                            <v-col
                                cols="auto"
                                @click="dialogShow = false"
                                class="cursor-pointer"
                            >Liste des configurations sencrop</v-col
                            >
                            >
                            <v-col cols="auto">{{ shownIntConf.name }}</v-col>
                            <v-spacer></v-spacer>
                            <v-btn small text dark @click="dialogShow = false"
                            >Fermer</v-btn
                            >
                          </v-row>
                        </v-card-title>
                        <v-card-text>
                          <Client
                              ref="Client"
                              v-model="shownIntConf"
                              @closeDialogShow="dialogShow = false"
                          />
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              color="secondary"
                              text
                              @click="dialogShow = false"
                          >Fermer</v-btn
                          >
                          <v-btn color="primary" @click="editItem(shownIntConf)"
                          >Modifier</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-data-table
                    :items="intConfs"
                    :options.sync="pagination"
                    :items-per-page="rowsPerPageItems"
                    :server-items-length="total"
                    :loading="loading"
                    :headers="headers"
                    :class="{ mobile: isMobile }"
                    :footer-props="{
                    itemsPerPageText: 'Ligne par page',
                  }"
                >
                  <template v-slot:item="props">
                    <tr>
                      <td data-label="Nom">{{ props.item.name }}</td>
                      <td data-label="Type d'intégration">{{ props.item.integration.name }}</td>
                      <td data-label="Date de création">{{ moment(props.item.created_at).format("DD-MM-YYYY") }}</td>
                      <td data-label="Date mise à jour">{{ moment(props.item.updated_at).format("DD-MM-YYYY HH:m:ss") }}</td>
                      <td class="text-right">
                        <v-icon
                            small
                            class="mr-2"
                            @click.stop="editItem(props.item)"
                            title="Editer"
                        >edit</v-icon
                        >
                        <v-icon small @click.stop="deleteItem(props.item)" title="Supprimer"
                        >delete</v-icon
                        >
                      </td>
                    </tr>
                  </template>
                  <template v-slot:no-data> Aucune configuration sencrop </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import IntegrationForm from "../components/IntegrationForm";
import Client from "../components/Client";
import moment from "moment";

export default {
  components: {
    IntegrationForm,
    Client,
  },
  data() {
    return {
      ready: false,
      moment: moment,
      intConfs: [],
      allIntConfs: undefined,
      action: "create",
      shownIntConf: {},
      editedIntConf: {},
      defaultIntConf: {
        connection_settings: {"appID": "", "appSecret": "", "partnerID": ""},
        //connection_settings: {"username": "", "password": ""},
        params: {},
      },
      pagination: {
        page: 1,
      },
      search: "",
      total: 0,
      rowsPerPageItems: 10,
      headers: [
        {
          text: 'Nom',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Type d\'intégration',
          value: 'integration.name',
        },
        {
          text: 'Date de création',
          value: 'created_at',
        },
        {
          text: 'Date de mise à jour',
          value: 'updated_at',
        },
        {},
      ],
      dialogEdit: false,
      dialogShow: false,
      isMobile: false,
      loading: false,
    };
  },
  mounted() {
    //
  },
  methods: {
    onResize() {
      this.isMobile = this.$vuetify.breakpoint.smAndDown;
    },
    getSencropConfigs(queries) {
      if (!this.allIntConfs) {
        this.$store
            .dispatch("intConfsRequest", queries)
            .then((result) => {
              this.allIntConfs = result.data;
              this.total = this.allIntConfs.length;
              this.intConfs = result.data;
              const start =
                  (this.pagination.page - 1) * this.pagination.itemsPerPage;
              let end = Math.min(
                  this.intConfs.length,
                  this.pagination.page * this.pagination.itemsPerPage
              );
              if (this.pagination.itemsPerPage == -1) {
                end = this.intConfs.length + 1;
              }
              this.intConfs = this.intConfs.slice(start, end);

              this.loading = false;
              if (!this.ready)
                this.$checkHistory("intConf", (intConf) => this.showItem(intConf));
              this.ready = true;
            })
            .catch(() => {
              //
            });
      } else {
        if (this.search) {
          this.intConfs = this.allIntConfs.filter((intConf) =>
              intConf.name.toLowerCase().includes(this.search.toLowerCase())
          );
        } else {
          this.intConfs = this.allIntConfs;
        }
        this.total = this.intConfs.length;
        const start = (this.pagination.page - 1) * this.pagination.itemsPerPage;
        let end = Math.min(
            this.intConfs.length,
            this.pagination.page * this.pagination.itemsPerPage
        );
        if (this.pagination.itemsPerPage == -1) {
          end = this.intConfs.length + 1;
        }
        this.intConfs = this.intConfs.slice(start, end);

        this.loading = false;
        if (!this.ready)
          this.$checkHistory("intConf", (intConf) => this.showItem(intConf));
        this.ready = true;
      }
    },
    handleSavedIntConfForm(intConf) {
      this.shownIntConf = intConf;
      this.refreshPagination();
      this.dialogEdit = false;
    },
    handleCanceledIntConfForm() {
      this.dialogEdit = false;
    },
    showItem(intConf) {
      // console.log('clients showItem', client)
      this.shownIntConf = intConf;
      this.dialogShow = true;
    },
    createItem() {
      this.action = "create";

      this.editedIntConf = Object.assign({}, this.defaultIntConf);
      this.dialogEdit = true;
    },
    IsJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    editItem(intConf) {
      this.action = "update";

      let params;

      if(this.IsJsonString(intConf.params)){
        params = JSON.parse(intConf.params);
      } else {
        params = intConf.params;
      }
      intConf.params = params;

      if(params !== {}){
        intConf.connection_settings = params;
      }

      this.editedIntConf = Object.assign({}, intConf);
      this.dialogEdit = true;
    },
    deleteItem(intConf) {
      if (confirm("Êtes-vous sûr de vouloir supprimer cette configuration ?")) {
        this.$store
            .dispatch("intConfDeleteRequest", { id: intConf.id })
            .then((result) => {
              this.$store.commit("setSnack", {
                type: "success",
                msg: "Configuration supprimé !",
              });
              this.refreshPagination();
            })
            .catch((err) => {
              this.$store.commit("setSnack", {
                type: "red",
                msg: err.response.data,
              });
            });
      }
    },
    refreshPagination() {
      const queries = {
        // page: this.pagination.page,
        // per_page: this.pagination.itemsPerPage,
        sort: this.pagination.sortBy,
        direction: this.pagination.sortDesc[0] ? "desc" : "asc",
        // search: this.search,
      };

      this.loading = true;
      this.getSencropConfigs(queries);
    },
  },
  watch: {
    pagination: {
      handler() {
        this.refreshPagination();
      },
      deep: true,
    },
    dialogShow() {
      if (this.dialogShow) {
        this.$clicked_client = this.shownIntConf;
      } else this.$clicked_client = null;

      if (this.dialogShow) this.$resetDialogScroll();
    },
    dialogEdit() {
      if (this.dialogEdit) this.$resetDialogScroll();
    },
  },
};
</script>
