<template>
    <div>
        <Navigation />
        <v-main>
            <v-container fluid>
                <v-row>
                    <!-- User Activity Section -->
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-card-title>👥 User Activity</v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="6">
                                        <v-statistic
                                            :value="totalUsers || 'Loading...'"
                                            label="Total Users"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-statistic
                                            :value="activeUsers || 'Loading...'"
                                            label="Active Users"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-statistic
                                            :value="retentionRate ? retentionRate + '%' : 'Loading...'"
                                            label="Retention Rate"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-statistic
                                            :value="newSignups"
                                            label="New Signups"
                                        />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- App Performance Section -->
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-card-title>⚡️ Performance Metrics</v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="6">
                                        <v-statistic
                                            :value="apiResponseTime ? apiResponseTime + 'ms' : 'Loading...'"
                                            label="API Response Time"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-statistic
                                            :value="uptimePercentage + '%'"
                                            label="Uptime"
                                        />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- Error Tracking Section -->
                    <v-col cols="12">
                        <v-card>
                            <v-card-title>🛠 Error & Warning Tracking</v-card-title>
                            <v-card-text>
                                <v-data-table
                                    :headers="errorHeaders"
                                    :items="notifications"
                                    :items-per-page="itemsPerPage"
                                    :page.sync="page"
                                    :items-per-page-options="[5, 10, 25]"
                                    :item-class="setRowClass"
                                    :expanded.sync="expanded"
                                    show-expand
                                    class="elevation-1"
                                >
                                    <!-- <template v-slot:item.short_description="{ item }">
                                        <div>
                                            {{ item.short_description }}
                                            <v-btn icon small @click="item.expanded = !item.expanded">
                                                <v-icon>{{ item.expanded ? 'arrow_upward' : 'arrow_downward' }}</v-icon>
                                            </v-btn>
                                        </div>
                                    </template> -->

                                    <template v-slot:expanded-item="{ headers, item }">
                                        <td :colspan="headers.length" class="error-details-card">
                                            <div class="error-details">
                                                <div class="error-header">
                                                    <strong>Event Date:</strong> {{ item.updated_at }}
                                                </div>
                                                <div class="error-body">
                                                    <strong>Full Description:</strong>
                                                    <pre class="preformatted-text">{{ item.full_description }}</pre>
                                                </div>
                                            </div>
                                        </td>
                                    </template>
                                    <template v-slot:item="props">
                                        <tr :style="setRowStyle(props.item)">
                                            <td>
                                                <v-icon @click="toggleExpand(props.item)">
                                                    {{ isExpanded(props.item) ? 'arrow_upward' : 'arrow_downward' }}
                                                </v-icon>
                                            </td>
                                            <td>{{ props.item.short_description }}</td>
                                            <td>{{ props.item.type }}</td>
                                            <td>{{ props.item.source }}</td>
                                            <td>{{ formatDate(props.item.updated_at) }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Vue from 'vue';
import VStatistic from '@/components/v-statistic.vue';

export default {
    components: {
        VStatistic
    },
    data() {
        return {
            newSignups: 120,
            apiResponseTime: null,
            uptimePercentage: null,
            expanded: [],
            itemsPerPage: 5,
            serverMessage: "",
            page: 1,

            // Error Logs Data
            errorHeaders: [
                { text: 'Short Description', value: 'short_description' },
                { text: 'Type', value: 'type' },
                { text: 'Source', value: 'source' },
                { text: 'Event Date', value: 'updated_at' },
            ],
            notifications: [],
        };
    },
    computed: {
        ...mapGetters('admin', ['getTotalUsers', 'getActiveUsers', 'getRetentionRate']),
        totalUsers() {
            return this.getTotalUsers;
        },
        activeUsers() {
            return this.getActiveUsers;
        },
        retentionRate() {
            return this.getRetentionRate;
        },
    },
    mounted() {
        //this.fetchTotalUsers();
        //this.fetchActiveUsers();
        //this.fetchRetentionRate();
        this.fetchApiResponseTime();
        this.fetchUptimePercentage();
        this.fetchNotifications();
    },
    methods: {
        ...mapActions('admin', ['fetchTotalUsers', 'fetchActiveUsers', 'fetchRetentionRate']),

        fetchApiResponseTime() {
            setTimeout(() => {
                const startTime = performance.now(); // Capture the start time

                Vue.axios.get('/api/ping')
                    .then(response => {
                        const endTime = performance.now(); // Capture the end time
                        this.apiResponseTime = (endTime - startTime).toFixed(2); // Calculate and store the response time in ms

                        // Store the response message
                        this.serverMessage = response.data.message;
                    })
                    .catch(error => {
                        console.error('Error fetching ping:', error);
                    });
            }, 3000);
        },
        formatDate(dateString) {
            const date = new Date(dateString); // Convert the string to a Date object
            return date.toLocaleString('en-GB', {
                timeZone: 'Europe/Paris', // Replace with your timezone
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            });
        },
        toggleExpand(item) {
            const index = this.expanded.indexOf(item);
            if (index > -1) {
                this.expanded.splice(index, 1); // Collapse if already expanded
            } else {
                this.expanded.push(item); // Expand if not yet expanded
            }
        },
        isExpanded(item) {
            return this.expanded.includes(item);
        },
        setRowStyle(item) {
            /*if (item.type === 'Warning') {
                return { backgroundColor: 'yellow' }; // Warning style
            } else if (item.type === 'Error') {
                return { backgroundColor: 'red' }; // Error style
            } else {
                return {}; // Default style
            }*/

            if (item.type === 'Error') {
                return { backgroundColor: 'red' }; // Error style
            }

        },
        fetchUptimePercentage() {
            Vue.axios.get('/api/uptime')
                .then(response => {
                    this.uptimePercentage = response.data.uptime;
                })
                .catch(error => {
                    console.error('Error fetching uptime percentage:', error);
                });
        },
        fetchNotifications() {
            Vue.axios.get('/api/notifications')
                .then(response => {
                    this.notifications = response.data.data;
                })
                .catch(error => {
                    console.error('Error fetching notifications:', error);
                });
        },
    },
};
</script>

<style scoped>
/* Add custom styling for better readability */

.error-details-card {
    background-color: #f9f9f9;
}

.error-details {
    background-color: #f9f9f9;
}

.error-header {
    font-weight: bold;
    margin-bottom: 8px;
}

.error-body {
    white-space: pre-line; /* To preserve formatting */
    font-family: monospace; /* Use a monospaced font for better error readability */
    font-size: 14px;
}

.preformatted-text {
    white-space: pre-wrap; /* Preserve both white spaces and line breaks */
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
    max-height: 400px; /* Limit the height of the text */
    overflow-y: auto; /* Scroll if text exceeds height */
}
</style>