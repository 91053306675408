<template>
  <v-card flat>
    <v-card-text class="pt-0">
      <v-row>
        <v-col cols="12" lg="4" md="6" sm="8" class="pt-0">
          <v-card flat>
            <v-card-text class="d-flex flex-sm-nowrap flex-wrap">
              <v-menu
                ref="queryFromPicker"
                v-model="queryFromPicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="query.from"
                    label="Date début"
                    prepend-icon="event"
                    readonly
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  locale="fr-FR"
                  v-model="query.from"
                  no-title
                  @input="queryFromPicker = false"
                ></v-date-picker>
              </v-menu>

              <v-menu
                ref="queryToPicker"
                v-model="queryToPicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="query.to"
                    label="Date fin"
                    prepend-icon="event"
                    readonly
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  locale="fr-FR"
                  v-model="query.to"
                  no-title
                  @input="queryToPicker = false"
                ></v-date-picker>
              </v-menu>

              <!-- <v-select
                label="Intervalle"
                :items="intervalChooser"
                item-text="label"
                item-value="value"
                v-model="query.interval"
              ></v-select> -->
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card flat>
            <!-- <v-card-text class="d-flex justify-end">
              <v-btn-toggle> </v-btn-toggle>
            </v-card-text> -->
            <v-card-text class="pt-0">
              <v-tabs v-model="activeTab">
                <v-tabs-slider></v-tabs-slider>
                <v-tab>Liste</v-tab>
              </v-tabs>
              <v-tabs-items v-model="activeTab">
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-data-table
                        :items="listData"
                        :loading="!listReady"
                        :headers="headers"
                        :sort-by="['_source.Datetime', '_source.createdBy.name', '_source.location', '_source.mesures.unit_of_measure', '_source.mesures.commentary', '_source.events']"
                        :sort-desc="[false, true]"
                        style="white-space: pre-line;"
                        must-sort
                        :footer-props="{
                          itemsPerPageText: `Nombre de lignes par page`,
                        }"
                      >
                        <template v-slot:item="props">
                          <tr
                            :style="{
                              color: checkIfValueIsOutOfLimit(
                                props.item._source
                              )
                                ? '#F00'
                                : '#000',
                            }"
                          >
                            <td>
                              {{
                                new Date(
                                  props.item._source["Datetime"] * 1000
                                ).toLocaleString()
                              }}
                            </td>
                            <td>
                              {{
                                props.item._source.createdBy
                                  ? props.item._source.createdBy.name
                                  : ""
                              }}
                            </td>
                            <td>
                              {{
                                props.item._source.location
                                  ? props.item._source.location
                                  : ""
                              }}
                            </td>
                            <template v-if="isCuve()">
                              <td>
                                {{
                                  props.item._source.parcelleSource
                                    ? props.item._source.parcelleSource.name
                                    : ""
                                }}
                              </td>
                            </template>
                            <td>
                              {{ printMesures(props.item._source.mesures) }}
                            </td>
                            <td style="max-width: 400px ">
                              {{ printCommentaries(props.item._source.mesures) }}
                            </td>
                            <td>
                              {{ printEvents(props.item._source.events) }}
                            </td>

                            <td>
                              <v-btn
                                v-if="props.item._source['hasFile']"
                                depressed
                                small
                                color="primary"
                                @click="getDocument(props.item)"
                              >
                                <v-icon>get_app</v-icon>
                              </v-btn>
                            </td>

                            <td v-if="user().role !== 'user'">
                              <v-icon small @click.stop="deleteItem(props.item)"
                                >close</v-icon
                              >
                            </td>
                          </tr>
                        </template>
                        <template v-slot:no-data> Aucune donnée </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
  components: {},
  props: {
    site: { type: Object, required: true },
    reload: { type: Boolean, required: false },
  },

  data() {
    let headerDate = {
      text: "Date",
      value: "_source.Datetime",
      dataType: "Date",
    };
    let headerUser = {
      text: "Utilisateur",
      value: "_source.createdBy.name",
    };
    let headerLocation = {
      text: "Location",
      value: "_source.location",
    };
    let headerMesures = {
      text: "Mesures",
      value: "_source.mesures",
      dataType: "Array",
    };
    let headerEvents = {
      text: "Evenements",
      value: "_source.events",
      dataType: "Array",
    };
    let headerCommentary = {
      text: "Commentaire",
      value: "_source.mesures.commentary",
      dataType: "Array",
    };
    let headerParcelle = {
      text: "Source",
      value: "_source.parcelleSource.name",
    };
    let headers = [
      headerDate,
      headerUser,
      headerLocation,
      headerMesures,
      headerCommentary,
      headerEvents,
      {},
      {},
    ];
    if (this.isCuve()) {
      headers = [
        headerDate,
        headerUser,
        headerLocation,
        headerParcelle,
        headerMesures,
        headerCommentary,
        headerEvents,
        {},
        {},
      ];
    }
    return {
      elastic_winery_mesures_index: "winery_mesures_" + this.site.client_id,
      elastic_winery_events_index: "winery_events_" + this.site.client_id,
      activeTab: 0,
      query: {
        from: null,
        to: null,
      },
      queryFromPicker: false,
      queryToPicker: false,

      listReady: false,
      listData: [],
      headers,
    };
  },
  watch: {
    query: {
      deep: true,
      handler() {
        this.getElasticData();
      },
    },
    reload: {
      handler() {
        this.getElasticData();
      },
    },
    activeTab() {},
  },
  mounted() {
    this.getElasticData();
  },
  methods: {
    isCuve() {
      return this.site.type == "Cuve";
    },
    user() {
      let user = this.$store.state.profile.user;
      return user;
    },
    getElasticData() {
      this.listReady = false;
      this.listData = [];

      let elasticQuery = {
        _source: {
          excludes: ["fileContent"],
        },
      };

      let elasticQueryMust = [
        { term: { Site: this.site.id } },
        { term: { handmade: true } },
      ];
      let elasticQueryFilter = [];

      if (this.query.from || this.query.to) {
        if (this.query.from) {
          elasticQueryFilter.push({
            range: {
              Datetime: { gte: new Date(this.query.from).getTime() / 1000 },
            },
          });
        }

        if (this.query.to) {
          elasticQueryFilter.push({
            range: {
              Datetime: { lte: new Date(this.query.to).getTime() / 1000 },
            },
          });
        }
      }

      elasticQuery = {
        ...elasticQuery,
        query: { bool: { must: elasticQueryMust, filter: elasticQueryFilter } },
      };

      console.log("elastic query", elasticQuery);

      this.$store
        .dispatch("elasticSearchRequest", {
          index: this.elastic_winery_mesures_index,
          body: elasticQuery,
          size: 1000,
          site: this.site.id,
        })
        .then((result) => {
          console.log("Got datas", result.data);

          if (result.data["hits"]["hits"].length > 0) {
            this.listData.push(
              ...result.data["hits"]["hits"].map((row) => row)
            );
          }
          this.listReady = true;
        })
        .catch((err) => {
          this.$store.commit("setSnack", {
            type: "red",
            err,
          });
          this.listReady = true;
        });
    },
    getRandomColor() {
      return "#" + (Math.random().toString(16) + "0000000").slice(2, 8);
    },

    getDocument(document) {
      console.log("getDocument", document);

      this.$store
        .dispatch("elasticGetRequest", {
          index: this.elastic_winery_mesures_index,
          id: document["_id"],
          site: this.site.id,
        })
        .then((result) => {
          if (result.data && result.data._source["fileName"]) {
            this.forceFileDownload(result.data);
          }
        })
        .catch((error) => {
          this.$store.commit("setSnack", {
            type: "red",
            error,
          });
        });
    },
    forceFileDownload(esDocument) {
      const link = document.createElement("a");
      link.href = esDocument._source["fileContent"];
      link.setAttribute("download", esDocument._source["fileName"]);
      document.body.appendChild(link);
      link.click();
    },
    async deleteItem(item) {
      if (confirm("Êtes-vous sûr de vouloir supprimer cet enregistement ?")) {
        this.$store
          .dispatch("elasticDeleteRequest", {
            index: this.elastic_winery_mesures_index,
            site: this.site.id,
            id: item._id,
          })
          .then((result) => {
            sleep(1000).then((result) => {
              this.$store.commit("setSnack", {
                type: "success",
                msg: "Enregistrment supprimé !",
              });
              this.getElasticData();
            });
          })
          .catch((err) => {
            console.log("error :" + err);
          });
      }
    },
    //TODO
    checkIfValueIsOutOfLimit(row) {
      let isOutofLimit = false;
      if (!row.value) {
        return;
      }
      let value = parseFloat(row["value"].replace(",", "."));
      let type = row["type"];

      if (
        type == "DCO" &&
        ((this.site.dco_min && value <= this.site.dco_min) ||
          (this.site.dco_max && value >= this.site.dco_max))
      ) {
        isOutofLimit = true;
      }
      if (
        type == "DBO" &&
        ((this.site.dbo_min && value <= this.site.dbo_min) ||
          (this.site.dbo_max && value >= this.site.dbo_max))
      ) {
        isOutofLimit = true;
      }
      if (
        type == "MES" &&
        ((this.site.mes_min && value <= this.site.mes_min) ||
          (this.site.mes_max && value >= this.site.mes_max))
      ) {
        isOutofLimit = true;
      }
      return isOutofLimit;
    },
    printMesures(mesures) {
      let str = "";
      if (!mesures || mesures == null) {
        return str;
      }
      let i = 0;

      for (let mesure of mesures) {
        if (mesure.category) {
          str += mesure.category;
          str += " => ";
        }
        str += mesure.name;
        str += ":";
        str += " ";
        str += mesure.value;
        if (mesure.unit_of_measure) {
          str += " ";
          str += mesure.unit_of_measure;
        }
        if (i != mesures.length - 1) {
          str += "\n";
        }
        i++;
      }
      return str;
    },
    printEvents(events) {
      let str = "";
      if (!events || events == null) {
        return str;
      }
      let i = 0;
      for (let event of events) {
        str += event;
        if (i != events.length - 1) {
          str += ", ";
        }
        i++;
      }
      return str;
    },



    printCommentaries(mesures) {
      let str = "";
      if (!mesures || mesures == null) {
        return str;
      }

      for (let mesure of mesures) {
        let i = 0;
        if (mesure.commentary) {
          str += mesure.commentary;
          if(i !== mesures.length - 1){
            str += "\n";
            i++;
          }
        }
      }
      return str;
    },

  },
};
</script>
