import Vue from 'vue'
import { convertAxes } from '../../utils/axes';

const state = {
    status: '',
}

const getters = {}

const actions = {
    visualizationRequest: async ({ commit }, queries) => {
        let url = `/api/visualizations/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('visualizationRequest')
            Vue.axios.get(url)
                .then(result => {
                    if (result.data.events) result.data.events = JSON.parse(result.data.events);
                    if (result.data.axes && result.data.axes != null) {
                        result.data.axes = JSON.parse(result.data.axes);
                        convertAxes(result.data.axes.axes);
                    }
                    else result.data.axes = { axes: [] }
                    commit('visualizationSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('visualizationError');
                    reject(err)
                })
        })
    },
    visualizationEditRequest: async ({ commit }, queries) => {
        let url = `/api/visualizations/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('visualizationRequest')
            Vue.axios.put(url, queries.datas)
                .then(result => {
                    commit('visualizationSuccess', result.data.data)
                    resolve(result.data.data)
                })
                .catch(err => {
                    commit('visualizationError');
                    reject(err)
                })
        })
    },
    visualizationCreateRequest: async ({ commit }, queries) => {
        let url = `/api/visualizations`;

        return new Promise((resolve, reject) => {
            commit('visualizationRequest')
            Vue.axios.post(url, queries)
                .then(result => {
                    commit('visualizationSuccess', result.data.data)
                    resolve(result.data.data)
                })
                .catch(err => {
                    commit('visualizationError');
                    reject(err)
                })
        })
    },
    visualizationDeleteRequest: async ({ commit }, queries) => {
        let url = `/api/visualizations/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('visualizationRequest')
            Vue.axios.delete(url)
                .then(result => {
                    commit('visualizationDeleteSuccess')
                    resolve(result)
                })
                .catch(err => {
                    commit('visualizationError');
                    reject(err)
                })
        })
    },
    visualizationsRequest: async ({ commit, dispatch }, queries) => {
        //remove null values
        queries = Object.entries(queries).reduce((a, [k, v]) => (v ? { ...a, [k]: v } : a), {})
        //build query url
        let url = '/api/visualizations?' + Object.entries(queries).map(e => e.join('=')).join('&')

        return new Promise((resolve, reject) => {
            commit('visualizationRequest')
            Vue.axios.get(url)
                .then(result => {
                    if (result.data) {
                        for (let viz of result.data.data) {
                            if (viz.events) viz.events = JSON.parse(viz.events);
                            if (viz.axes && viz.axes != null) viz.axes = JSON.parse(viz.axes);
                            else viz.axes = { axes: [] }
                        }
                    }
                    commit('visualizationsSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('visualizationError');
                    reject(err)
                })
        })
    },
}



const mutations = {
    visualizationRequest: (state) => {
        state.status = 'loading';
    },
    visualizationsSuccess: (state, resp) => {
        state.status = 'success';
    },
    visualizationSuccess: (state, resp) => {
        state.status = 'success';
    },
    visualizationDeleteSuccess: (state) => {
        state.status = 'success';
    },
    visualizationError: (state) => {
        state.status = 'error';
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}