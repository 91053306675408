import Vue from 'vue'

const state = {
    status: '',
}

const getters = {
}

const actions = {
    resetPassword: ({commit}, credentials) => {
        let data = {'email': credentials.email}

        return new Promise((resolve, reject) => {
            commit('resetPasswordRequest')
            Vue.axios.post('api/password/email', data)
                .then(result => {
                    commit('resetPasswordSuccess')
                    commit('setSnack', {'type': 'success', 'msg': 'Demande de réinitialisation envoyée'})
                    resolve(result)
                })
                .catch(err => {
                    commit('resetPasswordError')
                    commit('setSnack', {'type': 'error', 'msg': err.response.data.message || err.response.data})
                    reject(err)
                })
        })
    },
    editPassword: ({commit}, credentials) => {
        // eslint-disable-next-line no-console
        // console.log(credentials)

        return new Promise((resolve, reject) => {
            commit('resetPasswordRequest')
            Vue.axios.post('api/password/reset', credentials)
                .then(result => {
                    commit('resetPasswordSuccess')
                    commit('setSnack', {'type': 'success', 'msg': 'Mot de passe modifié'})
                    resolve(result)
                })
                .catch(err => {
                    commit('resetPasswordError')
                    // console.log(err.response)
                    commit('setSnack', {'type': 'error', 'msg': err.response.data.message || err.response.data})
                    reject(err)
                })
        })
    }
}

const mutations = {
    resetPasswordRequest: (state) => {
        state.status = 'loading'
    },
    resetPasswordSuccess: (state) => {
        state.status = 'success'
    },
    resetPasswordError: (state) => {
        state.status = 'error'
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}