<template>
  <div>
    <Navigation />
    <v-main>
      <v-container fluid fill-height v-if="showSelectMenu">
        <v-layout v-resize="onResize">
          <v-col cols="12">
            <v-card
                class="mx-auto my-6 rounded-xl"
                max-width="360"
                outlined
                rounded
                color="primary"
                v-on:click.stop="degustViz"
            >
              <v-img
                  src="https://i.imgur.com/rxJEAn6.jpg"
                  height="200px"
              ></v-img>
              <p align="center" class="my-auto pa-4 text-center text-h6 font-weight-bold">Visualiser une dégustation</p>
            </v-card>
            <v-card
                class="mx-auto my-6 rounded-xl"
                max-width="360"
                outlined
                rounded
                color="primary"
                v-on:click.stop="degustCreate"
            >
              <v-img
                  src="https://i.imgur.com/Qa7H3A7.jpg"
                  height="200px"
              ></v-img>
              <p align="center" class="my-auto pa-4 text-center text-h6 font-weight-bold">Nouvelle dégustation</p>
            </v-card>
            <v-card
                class="mx-auto my-6 rounded-xl"
                max-width="360"
                outlined
                rounded
                color="primary"
                v-on:click.stop="degustConf"
            >
              <v-img
                  src="https://i.imgur.com/n4NUNDC.jpg"
                  height="200px"
              ></v-img>
              <p align="center" class="my-auto pa-4 text-center text-h6 font-weight-bold">Nouvelle configuration de dégustation</p>
            </v-card>
          </v-col>
        </v-layout>
      </v-container>
      <v-container fluid fill-height v-if="!showSelectMenu">
        <v-layout v-resize="onResize">
          <v-col cols="12">
            <v-card
              class="mx-auto px-5"
              max-width="360"
              v-if="showDegustConfigCreate"
            >
              <div class="py-3 white text-right">
                <v-btn @click.stop="goBack" color="primary">
                  <v-icon
                    dark
                    left
                  >
                    chevron_left
                </v-icon>Retour</v-btn>
              </div>
              <p align="center" class="my-auto pa-2 text-center text-h6 font-weight-bold">Nouvelle configuration de dégustation</p>
              <v-form  v-on:submit.prevent="addDegustConf" ref="form" class="mb-5">
                <v-combobox
                    clearable
                    filled
                    hide-selected
                    outlined
                    persistent-hint
                    chips
                    :items="this.$store.getters.getProfile.clients"
                    item-text="name"
                    item-value="id"
                    v-model="selectedSite"
                    v-on:change="selectedConfig = null"
                    label="Sélectionner le client associé à cette configuration"
                ></v-combobox>
                <v-select
                    filled
                    outlined
                    persistent-hint
                    :items="[1,2,3,4,5,6,7,8,9,10]"
                    v-model="totalAxes"
                    label="Sélectionner le nombre d'axes à visualizer"
                ></v-select>
                <v-text-field
                    v-for="i in totalAxes"
                    :key="i"
                    v-model="eventsNames[i]"
                    outlined
                    filled
                    style="border-width: 6px !important;"
                    :label="'Axe ' + i + ': Nommer le paramètre à observer'"
                />
                <v-text-field
                  v-model="configName"
                  outlined
                  filled
                  label="Nom de la configuration"
                />
                <div class="py-3 white flex">
                  <v-btn block color="primary" type="submit" :disabled="loading" :loading="loading">Sauvegarder</v-btn>
                </div>
              </v-form>
            </v-card>
            <v-card
                class="mx-auto px-5"
                max-width="360"
                v-if="showDegustCreate"
            >
              <div class="py-2 white text-right">
                <v-btn @click.stop="goBack" color="primary">
                  <v-icon
                      dark
                      left
                  >
                    chevron_left
                  </v-icon>Retour</v-btn>
              </div>
              <p align="center" class="my-auto pa-2 text-center text-h6 font-weight-bold" v-if="!ShowDegustCompBtn">Nouvelle dégustation</p>
              <p align="center" class="my-auto pa-2 text-center text-h6 font-weight-bold" v-if="ShowDegustCompBtn">Votre dégustation</p>
              <v-form  v-on:submit.prevent="addDegust" ref="form" class="mb-5">
                <v-container v-if="degustConfPage === 1 && !ShowDegustCompBtn">
                  <p align="center" class="my-auto pa-2">Date de la dégustation</p>
                  <v-date-picker v-model="dateSelected"></v-date-picker>
                  <v-text-field v-model="configName" outlined filled label="Nom de la dégustation"/>
                </v-container>
                <v-container v-if="degustConfPage === 2 && !ShowDegustCompBtn">
                  <v-combobox
                      clearable
                      filled
                      hide-selected
                      outlined
                      persistent-hint
                      chips
                      :items="this.$store.getters.getProfile.clients"
                      item-text="name"
                      item-value="id"
                      v-model="selectedSite"
                      label="Sélectionner le client"
                      v-on:change="updateDegustCreationData"
                  ></v-combobox>
                  <v-combobox
                      v-if="selectedSite != null"
                      clearable
                      filled
                      hide-selected
                      outlined
                      persistent-hint
                      chips
                      :items="this.$store.getters.getProfile.sites.filter((site) => site.client_id === this.selectedSite.id)"
                      item-text="name"
                      item-value="id"
                      v-model="selectedSubSite"
                      label="Sélectionner l'univers"
                      v-on:change="selectedConfig = null"
                  ></v-combobox>
                  <v-dialog v-model="dialogEdit" max-width="360px" persistent scrollable v-if="selectedSite != null && (this.$store.getters.getProfile.role === 'admin' || this.$store.getters.getProfile.role === 'user_admin')">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click.stop="createSite" small rounded depressed class="mb-5 grey lighten-2">Nouvel univers</v-btn>
                    </template>
                    <LightSiteForm
                            :editable="dialogEdit"
                            ref="SiteForm"
                            v-model="editedSite"
                            action="create"
                            @savedForm="handleSavedSiteForm"
                            @canceledForm="handleCanceledSiteForm"
                            :key="localKey"
                    />
                  </v-dialog>
                  <v-combobox
                      v-if="selectedSubSite != null"
                      clearable
                      filled
                      hide-selected
                      outlined
                      persistent-hint
                      chips
                      :items="visualizations"
                      item-text="name"
                      item-value="id"
                      v-model="selectedConfig"
                      label="Sélectionner la configuration"
                      v-on:change="onUpdateConfig"
                  ></v-combobox>
                  <Radar ref="radarChartRef" :chartData="chartData" :chartOptions="chartOptions" v-if="selectedConfig != null"/>
                  <v-text-field class="my-2 white flex" v-model="commentaire" label="Commentaire" filled outlined persistent-hint></v-text-field>
                  <v-text-field class="my-2 white flex" v-model="note" label="Note /10" filled outlined persistent-hint></v-text-field>
                  <div class="py-2 white flex">
                    <v-btn block color="primary" type="submit" :disabled="loading" :loading="loading">Sauvegarder</v-btn>
                  </div>
                </v-container>
                <v-container block v-if="degustConfPage === 1 && ShowDegustCompBtn">
                  <v-combobox
                      v-if="showDegustSelection"
                      v-model="selectedItems"
                      :items="degustators"
                      multiple
                      clearable
                      filled
                      hide-selected
                      outlined
                      persistent-hint
                      chips
                      item-text="firstname"
                      item-value="id"
                      label="Sélectionner les dégustateurs"
                      v-on:change="updateDatasets(false)"
                  >
                    <v-list-item
                        slot="prepend-item"
                        ripple
                        @click="selectAllOrNone(false)"
                    >
                      <v-list-item-title>{{ degustatorsText }}</v-list-item-title>
                    </v-list-item>
                    <v-divider
                        slot="prepend-item"
                        class="mt-2"
                    />
                  </v-combobox>
                  <Radar ref="radarChartRef" :chartData="chartData" :chartOptions="chartOptions"/>
                  <v-btn block fill color="primary" class="my-2" v-on:click.stop="onShowDegustSelection(false)">Comparer avec <br /> d'autres dégustateurs</v-btn>
                  <v-btn block fill color="primary" class="my-2" v-on:click.stop="createDegustCode()">Créer un code de <br /> dégustation partageable</v-btn>
                  <p class="my-2 white flex" style="margin: 0 auto;" v-html='"Lien partageable: <a href=\"https://app.wineryplatform.com/#/degustLink/" + sharedCode + "\">https://app.wineryplatform.com/#/degustLink/" + sharedCode + "</a>"' v-if="sharedCode !== ''"></p>
                </v-container>
                <div class="py-3 white text-right" v-if="!ShowDegustCompBtn">
                  <pageSystem total-pages="2" v-model="degustConfPage"></pageSystem>
                </div>
              </v-form>
            </v-card>
            <v-card
              class="mx-auto px-5"
              max-width="360"
              v-if="showDegustViz"
            >
              <div class="py-3 white text-right">
                <v-btn @click.stop="goBack" color="primary">
                  <v-icon
                      dark
                      left
                  >
                    chevron_left
                  </v-icon>Retour</v-btn>
              </div>
              <p align="center" class="my-auto pa-2 text-center text-h6 font-weight-bold">Visualisation d'une dégustation</p>
              <v-combobox
                  clearable
                  filled
                  hide-selected
                  outlined
                  persistent-hint
                  chips
                  :items="this.$store.getters.getProfile.clients"
                  item-text="name"
                  item-value="id"
                  v-model="selectedSite"
                  label="Sélectionner le client"
                  v-on:change="selectedSubSite = null; selectedConfig = null; resetConfig()"
              ></v-combobox>
              <v-combobox
                  v-if="selectedSite != null"
                  clearable
                  filled
                  :hide-selected="!showUniversalDegustSelection"
                  outlined
                  :persistent-hint="!showUniversalDegustSelection"
                  chips
                  :multiple="showUniversalDegustSelection"
                  :items="this.$store.getters.getProfile.sites.filter((site) => site.client_id === this.selectedSite.id)"
                  item-text="name"
                  item-value="id"
                  v-model="selectedSubSite"
                  label="Sélectionner le site"
                  v-on:change="onUpdateSiteForView"
              ></v-combobox>
              <v-container block v-if="selectedSubSite != null">
                <v-combobox
                    v-if="!showDegustSelector"
                    clearable
                    filled
                    hide-selected
                    outlined
                    persistent-hint
                    chips
                    :items="degustations"
                    item-text="name"
                    item-value="id"
                    v-model="selectedConfig"
                    label="Sélectionner la dégustation"
                    v-on:change="onUpdateConfigForView"
                ></v-combobox>
                <v-combobox
                    v-if="showDegustSelection"
                    v-model="selectedItems"
                    :items="degustators"
                    multiple
                    filled
                    outlined
                    persistent-hint
                    chips
                    clearable
                    item-text="firstname"
                    item-value="id"
                    label="Sélectionner les dégustateurs"
                    v-on:change="updateDatasets(false)"
                >
                  <v-list-item
                      slot="prepend-item"
                      ripple
                      @click="selectAllOrNone(false)"
                  >
                    <v-list-item-title>{{ degustatorsText }}</v-list-item-title>
                  </v-list-item>
                  <v-divider
                      slot="prepend-item"
                      class="mt-2"
                  />
                </v-combobox>
                <v-combobox
                    v-if="showDegustSelector"
                    v-model="selectedItems"
                    :items="degustations"
                    multiple
                    filled
                    outlined
                    persistent-hint
                    chips
                    clearable
                    item-text="name"
                    item-value="id"
                    label="Sélectionner les dégustations"
                    v-on:change="updateMulti(showUniversalDegustSelection)"
                >
                  <v-list-item
                      slot="prepend-item"
                      ripple
                      @click="selectAllOrNone(true)"
                  >
                    <v-list-item-title>{{ degustatorsText }}</v-list-item-title>
                  </v-list-item>
                  <v-divider
                      slot="prepend-item"
                      class="mt-2"
                  />
                </v-combobox>
                <template v-if="canShowDegust">
                  <Radar ref="radarChartRef" :chartData="chartData" :chartOptions="chartOptions" :separateGraphs="showUniversalDegustSelection"/>
                  <template v-if="selectedConfig !== null && selectedConfig.note !== null && selectedConfig.note !== undefined">
                    <p class="my-2 white flex" style="margin: 0 auto;" v-html="'Note <br><strong>' + selectedConfig.note + '/10 </strong>'"><br></p>
                  </template>
                  <template v-if="selectedConfig !== null && selectedConfig.commentaire !== null && selectedConfig.commentaire !== undefined">
                    <p class="my-2 white flex" style="margin: 0 auto;" v-html="'Commentaire <br><strong>' + selectedConfig.commentaire + '</strong>'"><br></p>
                  </template>
                  <v-btn color="primary" class="my-2" width="100%" v-on:click.stop="onShowDegustSelection(true)">Comparer avec <br /> d'autres dégustateurs</v-btn>
                  <v-btn color="primary" class="my-2" width="100%" v-on:click.stop="onShowDegustSelector()">Comparer avec <br /> d'autres dégustations</v-btn>
                  <v-btn color="primary" class="my-2 py-2" width="100%" v-on:click.stop="onShowDegustSelectorForUnivers()">Comparaison <br /> multi-univers</v-btn>
                </template>
              </v-container>
            </v-card>
          </v-col>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>
<script>


import Radar from "@/components/Charts/Radar";
import pageSystem from "@/components/pageSystem";
import Vue from "vue";
import LightSiteForm from "../components/LightSiteForm.vue";
import {SITE_DEFAULT} from "../const/sitetypes";

Vue.use(require('vue-moment'));

export default {
  components: {
      LightSiteForm,
    Radar,
    pageSystem
  },
  data() {
    return {
      showDegustCreate: false,
      showDegustConfigCreate: false,
      showDegustViz: false,
      showSelectMenu: true,
      ShowDegustCompBtn: false,
      showDegustSelection: false,
      showDegustSelector: false,
      showUniversalDegustSelection: false,
      degustatorsText: "Sélectionner tous les dégustateurs",
      selectedSite: null,
      selectedConfig: null,
      selectedSubSite: null,
      canShowDegust: false,
      selectedItems: [],
      degustators: [],
      eventsNames: [],
      degustConfPage: 1,
      totalAxes: 5,
      localKey: 1,
      dialogEdit: false,
      editedSite: {},
      dateSelected: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      configName: "",
      sharedCode: "",
      visualizations: [],
      degustations: [],
      commentaire: null,
      note: null,
      loading: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          dragData: {
            round: 0,
            magnet: {
              //to: Math.round()
            }
          }
        },
        scales: {
          r: {
            stepSize: 1,
            min: 0,
            max: 10,
            angleLines: {
              color: 'red',
              lineWidth: 4,
            }
          }
        },
        elements: {
          point: {
            radius: 12,
          }
        },
        legend: {
          labels: {
            font: {
              size: 36
            }
          }
        }
      },
      chartData: {
        labels: ['Limpidité', 'Vénéneusité', 'Couleur', 'Amertume', 'Attaque'],
        datasets: [
          {
            label: "Dégustation " + this.$store.getters.getProfile.firstname + " " + Vue.moment().format("DD/MM/YYYY"),
            data: [10,10,10,10,10],
            backgroundColor: ["#C9F99DAA","#C9F99DAA","#C9F99DAA","#C9F99DAA","#C9F99DAA"]
          },
        ],
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.getProfile;
    },
  },
  props: {
      degustParams: {
          type: Object,
          required: false
      },
  },
  mounted(){
      if(this.degustParams != null){
          this.degustCreate();
          this.selectedSite = this.degustParams.client;
          this.selectedSubSite = this.degustParams.site;
          this.configName = this.degustParams.degustName;
          this.dateSelected = this.degustParams.date;
          this.selectedConfig = this.degustParams.visualization;
          this.degustConfPage = 2;
          this.chartData.labels = JSON.parse(this.selectedConfig.events);
          this.chartData.datasets[0].data = Array(this.chartData.labels.length).fill(10);
          this.chartData.datasets[0].label = this.configName + " " + "(" + this.$store.getters.getProfile.firstname + ")";
      }
      this.$store.dispatch("profileRequest");
  },
  methods: {
    resetConfig(){
      this.selectedConfig = null;
      this.selectedSubSite = null;
      this.showDegustSelection = false;
      this.showUniversalDegustSelection = false;
      this.canShowDegust = false;
      this.degustatorsText = "Sélectionner tous les dégustateurs";
      this.selectedItems = [];
      this.eventsNames = [];
      this.configName = "";
      this.sharedCode = "";
      this.visualizations = [];
      this.degustations = [];
    },
    createSite () {
        this.editedSite = Object.assign({}, SITE_DEFAULT);
        this.editedSite.client_id = this.selectedSite.id;
        this.dialogEdit = true;
        this.localKey += 1;
    },
    handleSavedSiteForm (site) {
        this.selectedSubSite = site;
        this.$store.dispatch("profileRequest");
        this.dialogEdit = false
    },
    handleCanceledSiteForm () {
        this.dialogEdit = false
    },
    onResize() {
      this.isMobile = this.$vuetify.breakpoint.smAndDown;
    },
    onUpdateConfig() {
      this.chartData.labels = this.selectedConfig.events;
      this.chartData.datasets[0].data = Array(this.chartData.labels.length).fill(10);
      this.chartData.datasets[0].label = this.configName + " " + "(" + this.$store.getters.getProfile.firstname + ")";
      this.sharedCode = "";
    },
    showDegustCompPage(){
      this.ShowDegustCompBtn = true;
      this.degustConfPage = 1;
      this.chartOptions.plugins = {};
      this.chartOptions.elements = {
        point: {
          radius: 8,
        }
      };
    },
    goBack(){
      this.showDegustCreate = false;
      this.showDegustConfigCreate = false;
      this.showDegustViz = false;
      this.showSelectMenu = true;
      this.selectedSite = null;
      this.resetConfig();
      this.degustConfPage = 1;
      this.chartOptions.plugins = {
        dragData: {
          round: 0,
          magnet: {
            //to: Math.round()
          }
        }
      };
    },
    setCanvasHeight(cHeight){
      const canvas = document.getElementsByTagName("canvas");
      canvas[0].parentNode.style.height = (cHeight + 200) + 'px';
    },
    selectAllOrNone(DorD = false){
      if(DorD){
        if(!this.showUniversalDegustSelection){
          if(this.selectedItems === this.degustations){
            this.degustatorsText = "Sélectionner toutes les dégustations";
            this.selectedItems = [];
          } else {
            this.degustatorsText = "Dé-sélectionner tout";
            this.selectedItems = this.degustations;
          }
        } else {
          if(this.selectedItems === this.degustations){
            this.degustatorsText = "Sélectionner toutes les dégustations (multi-univers)";
            this.selectedItems = [];
          } else {
            this.degustatorsText = "Dé-sélectionner tout";
            this.selectedItems = this.degustations;
          }
        }
      } else {
        if(this.selectedItems === this.degustators){
          this.degustatorsText = "Sélectionner tous les dégustateurs";
          this.selectedItems = [];
        } else {
          this.degustatorsText = "Dé-sélectionner tout";
          this.selectedItems = this.degustators;
        }
      }
      this.updateDatasets(DorD);
    },
    updateDatasetsForMultiView(){
      this.chartData.datasets = [];
      this.chartData.labels = [];
      let labArray = {};
      this.selectedItems.forEach((selectedItem, ind) => {
        labArray = {
          /* eslint-disable-next-line */
          ind: selectedItem.visualization.events.replace(/[\[\]\\"]/g,'').split(','),
        }
        this.chartData.labels.push(labArray.ind);

        let randColor = "#" + Math.floor(Math.random()*16).toString(16) + Math.floor(Math.random()*16).toString(16) + Math.floor(Math.random()*16).toString(16) + Math.floor(Math.random()*16).toString(16) + Math.floor(Math.random()*16).toString(16) + Math.floor(Math.random()*16).toString(16) + "77";
        let fineDataset = {
          label: selectedItem.name + " (" + selectedItem.user.firstname + ")",
          /* eslint-disable-next-line */
          data: selectedItem.mesures.replace(/[\[\]\\"]/g,'').split(',').map(Number),
          backgroundColor: Array(labArray.length).fill(randColor),
        };
        this.chartData.datasets.push(fineDataset);
      });
    },
    updateMulti(bUpdate = false){
      if(bUpdate){
        this.updateDatasetsForMultiView();
      } else {
        this.updateDatasets(true);
      }
    },
    updateDatasets(cBool = false){
      if(cBool){
        this.chartData.datasets = [];
        let defaultAxes = [];
        if(this.selectedItems.length > 0){
          /* eslint-disable-next-line */
          let replace = this.selectedItems[0].visualization.events.replace(/[\[\]\\"]/g,'');
          defaultAxes = replace.split(',');
          //this.chartData.labels = defaultAxes;
        }

        //console.log("-------------------------------------\n");

        this.selectedItems.forEach(selectedItem => {

          /* eslint-disable-next-line */
          let replace = selectedItem.visualization.events.replace(/[\[\]\\"]/g,'');
          let usedAxes = replace.split(',');
          /* eslint-disable-next-line */
          replace = selectedItem.mesures.replace(/[\[\]\\"]/g,'');
          let usedMesures = replace.split(',').map(function(item) { return parseInt(item, 10); });
          let localMesures = [];
          defaultAxes.forEach(function(axe, axeIndex, axeArray) {
            let localIndex = usedAxes.findIndex(e => e === axe);
            if(localIndex !== -1){
              localMesures.push(usedMesures[localIndex]);
            } else {
              delete axeArray[axeIndex];
            }
          });
          let daCopy = defaultAxes;
          for(let i=0; i < defaultAxes.length; i++){
            let index = daCopy.findIndex(e => e === undefined);
            //console.log("*\nUndefined value found: " + (index !== -1) + "\n*\n");
            if(index !== -1){
              daCopy.splice(index, 1);
            }
          }
          defaultAxes = daCopy;
          this.chartData.labels = defaultAxes;

          //console.log("Mesures: " + localMesures + "\nAxes: " + daCopy + "\nOld Axes: " + defaultAxes + "\nLength of Axes: " + this.chartData.labels.length);

          let randColor = "#" + Math.floor(Math.random()*16).toString(16) + Math.floor(Math.random()*16).toString(16) + Math.floor(Math.random()*16).toString(16) + Math.floor(Math.random()*16).toString(16) + Math.floor(Math.random()*16).toString(16) + Math.floor(Math.random()*16).toString(16) + "77";
          let fineDataset = {
            label: selectedItem.name + " (" + selectedItem.user.firstname + ")",
            data: localMesures,
            backgroundColor: Array(this.chartData.labels.length).fill(randColor),
          };
          this.chartData.datasets.push(fineDataset);
        });

        if(this.selectedItems[0] != null){
          let selectedItem = this.selectedItems[0];
          /* eslint-disable-next-line */
          let replace = selectedItem.visualization.events.replace(/[\[\]\\"]/g,'');
          let usedAxes = replace.split(',');
          /* eslint-disable-next-line */
          replace = selectedItem.mesures.replace(/[\[\]\\"]/g,'');
          let usedMesures = replace.split(',').map(function(item) { return parseInt(item, 10); });
          let localMesures = [];
          this.chartData.labels.forEach(function(axe, axeIndex, axeArray) {
            let localIndex = usedAxes.findIndex(e => e === axe);
            if(localIndex !== -1){
              localMesures.push(usedMesures[localIndex]);
            }
          });
          this.chartData.datasets[0].data = localMesures;
          let randColor = this.chartData.datasets[0].backgroundColor[0];
          this.chartData.datasets[0].backgroundColor = Array(this.chartData.labels.length).fill(randColor);
        }

        //console.log("-------------------------------------\n");

      } else {
        this.chartData.datasets = [];
        this.selectedItems.forEach((selectedItem) => {
          let currentDegustations = this.degustations.filter((degust) => degust.user.id === selectedItem.id);
          currentDegustations.forEach(currentDegust => {
            let randColor = "#" + Math.floor(Math.random()*16).toString(16) + Math.floor(Math.random()*16).toString(16) + Math.floor(Math.random()*16).toString(16) + Math.floor(Math.random()*16).toString(16) + Math.floor(Math.random()*16).toString(16) + Math.floor(Math.random()*16).toString(16) + "77";
            let fineDataset = {
              label: currentDegust.name + " (" + currentDegust.user.firstname + ")",
              data: JSON.parse(currentDegust.mesures),
              backgroundColor: Array(this.chartData.labels.length).fill(randColor),
            };
            this.chartData.datasets.push(fineDataset);
          });
        });
      }
      this.setCanvasHeight(this.chartData.datasets.length * 45);
    },
    onShowDegustSelection(cBool){
      this.showDegustSelector = false;
      this.showDegustSelection = true;
      this.selectedItems = [];
      this.onUpdateViewForComparison(cBool);
      this.setCanvasHeight(0);
    },
    onShowDegustSelector(){
      this.showDegustSelector = true;
      this.showDegustSelection = false;
      this.selectedItems = [];
      this.degustatorsText = "Sélectionner toutes les dégustations";
      this.onUpdateViewForDegustComparison();
      this.setCanvasHeight(0);
    },
    onShowDegustSelectorForUnivers(){
      this.showDegustSelector = true;
      this.showDegustSelection = false;
      this.showUniversalDegustSelection = true;
      this.selectedItems = [];
      this.selectedSubSite = [];
      this.degustatorsText = "Sélectionner toutes les dégustations (multi-univers)";
      this.onUpdateViewForDegustComparisonMultiUnivers();
      this.setCanvasHeight(0);
    },
    makeID(length) {
        let result = '';
        const characters = 'A0B9C1D8E2F7G3H6I4J5KLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    },
    async createDegustCode(){
        let queries = {
            id: this.makeID(8),
            client_id: this.selectedSite.id,
            site_id: this.selectedSubSite.id,
            visualization_id: this.selectedConfig.id,
            degustName: this.configName,
            date: this.dateSelected
        }
        await this.$store
            .dispatch("degustLinkCreateRequest", queries)
            .then(() => {
                this.$store.commit("setSnack", {
                    type: "success",
                    msg: "Code de dégustation partageable créé avec succès",
                });
                this.sharedCode = queries.id;
            })
            .catch((err) => {
                //console.log("err", err);
                this.$store.commit("setSnack", { type: "error", msg: "Une erreur est survenu lors de la génération du code" });
            });
    },
    onUpdateViewForDegustComparison() {

      let si_id = this.selectedSubSite.id;

      const queries = {
        // page: this.pagination.page,
        // per_page: this.pagination.itemsPerPage,
        sort: "",
        direction: "asc",
        site_id: si_id,
        // search: this.search,
      };

      this.$store
          .dispatch("degustsRequest", queries)
          .then((result) => {
            this.degustations = result.data;
            this.updateDatasets();

          })
          .catch((err) => {
            console.log("err", err);
            this.$store.commit("setSnack", { type: "error", msg: err });
          });
    },
    onUpdateViewForDegustComparisonMultiUnivers() {

      const queries = {
        // page: this.pagination.page,
        // per_page: this.pagination.itemsPerPage,
        sort: "",
        direction: "asc",
        // search: this.search,
      };

      this.$store
          .dispatch("degustsRequest", queries)
          .then((result) => {
            this.degustations = result.data.filter((degust) => degust.site.client_id === this.selectedSite.id);
            this.degustations = this.degustations.filter((degust) => this.selectedSubSite.filter((site) => site.id === degust.site_id).length > 0);
            this.updateDatasetsForMultiView();

          })
          .catch((err) => {
            console.log("err", err);
            this.$store.commit("setSnack", { type: "error", msg: err });
          });
    },
    onUpdateViewForComparison(cBool) {

      let si_id = this.selectedSubSite.id;
      let viz_id = this.selectedConfig.id;

      if(cBool){
        viz_id = this.selectedConfig.visualization.id;
      }

      const queries = {
        // page: this.pagination.page,
        // per_page: this.pagination.itemsPerPage,
        sort: "",
        direction: "asc",
        site_id: si_id,
        visualization_id: viz_id,
        // search: this.search,
      };

      this.$store
          .dispatch("degustsRequest", queries)
          .then((result) => {
            this.degustations = result.data;
            this.selectedItems = [];
            this.degustations.forEach((degust) => {
              if(degust['user'].id === this.user.id){
                let selfUser = degust['user'];
                selfUser['firstname'] = "Moi";
                if(!this.degustators.some(e => e.firstname === "Moi")) {
                  this.degustators.push(selfUser);
                }
              } else if(!this.degustators.some(e => e.id === degust['user'].id)){
                this.degustators.push(degust['user']);
                this.selectedItems.push(degust['user']);
              }
            });

            this.selectedItems = this.degustators.filter((degustator) => degustator.id === this.user.id);
            this.updateDatasets();

          })
          .catch((err) => {
            console.log("err", err);
            this.$store.commit("setSnack", { type: "error", msg: err });
          });
    },
    onUpdateSiteForView() {
      if(!this.showUniversalDegustSelection) {
        if (this.selectedSubSite != null) {
          this.selectedConfig = null;

          const queries = {
            // page: this.pagination.page,
            // per_page: this.pagination.itemsPerPage,
            sort: "",
            direction: "asc",
            site_id: this.selectedSubSite.id,
            user_id: this.user.id,
            // search: this.search,
          };

          this.$store
              .dispatch("degustsRequest", queries)
              .then((result) => {
                this.degustations = result.data;
              })
              .catch((err) => {
                console.log("err", err);
                this.$store.commit("setSnack", {type: "error", msg: err});
              });
          this.canShowDegust = false;
          this.showDegustSelection = false;
          this.showDegustSelector = false;
          this.chartData.datasets = [
            {
              label: "Dégustation " + this.$store.getters.getProfile.firstname + " " + Vue.moment().format("DD/MM/YYYY"),
              data: [10, 10, 10, 10, 10],
              backgroundColor: ["#C9F99DAA", "#C9F99DAA", "#C9F99DAA", "#C9F99DAA", "#C9F99DAA"]
            },
          ];
        }
      }
      else {
        this.onUpdateViewForDegustComparisonMultiUnivers();
      }
    },
    onUpdateConfigForView() {
      this.chartData.labels = JSON.parse(this.selectedConfig.visualization.events);
      this.chartData.datasets[0].label = this.selectedConfig.name + " (Moi)";
      this.chartData.datasets[0].data = JSON.parse(this.selectedConfig.mesures);
      this.canShowDegust = true;
    },
    updateDegustCreationData(){
      this.selectedConfig = null;
      this.selectedSubSite = null;
      let sites = this.$store.getters.getProfile.sites.filter((site) => site !== null && site.client_id === this.selectedSite.id)
      sites.forEach((site) => {
        site.visualizations.filter((vizu) => vizu.component != null && vizu.component === "RadarChart").forEach((viz) => {
          this.visualizations.push(viz);
        });
        //console.log(site.visualizations);
      });

      //console.log(this.visualizations);
    },
    degustViz(){
      this.showDegustViz = true;
      this.showSelectMenu = false;
      this.showDegustCreate = false;
      this.showDegustConfigCreate = false;
      this.ShowDegustCompBtn = false;
      this.showUniversalDegustSelection = false;
      this.showDegustSelection = false;
      this.degustConfPage = 1;
      this.chartOptions.plugins = {};
      this.chartData.datasets = [
        {
          label: "Dégustation " + this.$store.getters.getProfile.firstname + " " + Vue.moment().format("DD/MM/YYYY"),
          data: [10,10,10,10,10],
          backgroundColor: ["#C9F99DAA","#C9F99DAA","#C9F99DAA","#C9F99DAA","#C9F99DAA"]
        },
      ];
      this.chartOptions.elements = {
        point: {
          radius: 8,
        }
      };
    },
    degustConf(){
      this.showDegustViz = false;
      this.showDegustCreate = false;
      this.showSelectMenu = false;
      this.ShowDegustCompBtn = false;
      this.showUniversalDegustSelection = false;
      this.degustConfPage = 1;
      this.showDegustConfigCreate = true;
      this.showDegustSelection = false;

      if(this.$store.getters.getProfile.clients.length === 1){
        this.selectedSite = this.$store.getters.getProfile.clients[0]
      }
    },
    degustCreate(){
      this.showDegustCreate = true;
      this.showDegustViz = false;
      this.showDegustConfigCreate = false;
      this.showSelectMenu = false;
      this.showUniversalDegustSelection = false;
      this.ShowDegustCompBtn = false;
      this.showDegustSelection = false;
      this.degustConfPage = 1;

      if(this.$store.getters.getProfile.clients.length === 1){
        this.selectedSite = this.$store.getters.getProfile.clients[0];
        this.updateDegustCreationData();
      }

      if(this.$store.getters.getProfile.sites.length === 1){
        this.selectedSubSite = this.$store.getters.getProfile.sites[0];
        this.updateDegustCreationData();
      }

      this.chartData.datasets = [
        {
          label: "Dégustation " + this.$store.getters.getProfile.firstname + " " + Vue.moment().format("DD/MM/YYYY"),
          data: [10,10,10,10,10],
          backgroundColor: ["#C9F99DAA","#C9F99DAA","#C9F99DAA","#C9F99DAA","#C9F99DAA"]
        },
      ];
      this.chartOptions.plugins = {
        dragData: {
          round: 0,
          magnet: {
            //to: Math.round()
          }
        }
      }
    },
    addDegust(){

      this.loading = true;

      let queries = {};

      queries['mesures'] = this.chartData.datasets[0].data;
      queries['name'] = this.configName;
      queries['user_id'] = this.$store.getters.getProfile.id;
      queries['date'] = this.dateSelected;
      queries['note'] = this.note;
      queries['commentaire'] = this.commentaire;
      queries['visualization_id'] = this.selectedConfig.id;
      queries['site_id'] = this.selectedSubSite.id;

      this.$store
          .dispatch("degustCreateRequest", queries)
          .then(() => {
            this.$store.commit("setSnack", {
              type: "success",
              msg: "Dégustation correctement enregistrée",
            });
            this.$store.dispatch("profileRequest");
            this.$emit("created", this.visualization);
            this.loading = false;
            this.showDegustCompPage();
          })
          .catch((err) => {
            console.log("err", err);
            this.$store.commit("setSnack", { type: "error", msg: err });
          });
    },
    addDegustConf(){

      this.loading = true;

      let queries = {};

      queries['events'] = this.eventsNames;
      queries['name'] = this.configName;
      queries['axes'] = null;
      queries['component'] = "RadarChart";
      queries['site_id'] = this.$store.getters.getProfile.sites.filter((site) => site !== null && site.client_id === this.selectedSite.id)[0].id;

      this.$store
          .dispatch("visualizationCreateRequest", queries)
          .then(() => {
            this.$store.commit("setSnack", {
              type: "success",
              msg: "Configuration de dégustation correctement enregistrée",
            });
            this.$store.dispatch("profileRequest");
            this.$emit("created", this.visualization);
            this.loading = false;
            this.goBack();
          })
          .catch((err) => {
            console.log("err", err);
            this.$store.commit("setSnack", { type: "error", msg: err });
          });
    },
  }
}
</script>