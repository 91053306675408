<template>
    <div>
        <Navigation/>
        <v-main>
            <v-container fluid fill-height>
                <v-layout v-resize="onResize">
                    <v-col cols="12">
                        <v-card>
                            <v-card-text>

                                <v-row class="pb-4 text-center" align="center">
                                    <v-col cols="12" md="auto">
                                        <h2 class="ml-2 headline">Liste des types de capteur</h2>
                                    </v-col>

                                    <v-spacer></v-spacer>

                                    <v-col cols="12" md="auto">
                                        <v-text-field
                                            v-model="search"
                                            clearable
                                            hide-details
                                            solo-inverted
                                            flat
                                            prepend-inner-icon="search"
                                            label="Rechercher"
                                            @input="refreshPagination"
                                        ></v-text-field>
                                    </v-col>
                                    <v-spacer></v-spacer>

                                    <v-col cols="12" md="auto">
                                        <!-- CREATE/EDIT DIALOG -->
                                        <v-dialog v-model="dialogEdit" max-width="1400px" persistent scrollable>
                                            <template v-slot:activator="{ on }">
                                                <v-btn color="primary" v-on="on" @click.stop="createItem">Nouveau type de capteur</v-btn>
                                            </template>
                                            <v-card>
                                                <v-card-title class="primary white--text">
                                                    <template v-if="action == 'create'">Création</template>
                                                    <template v-if="action == 'update'">Modification</template>
                                                    d'un type de capteur
                                                    <v-spacer></v-spacer>
                                                    <v-btn small text dark @click="dialogEdit = false">Annuler</v-btn>
                                                </v-card-title>
                                                <v-card-text>
                                                    <SensorTypeForm ref="SensorTypeForm" v-model="editedSensorType" :action="action" @savedForm="handleSavedSensorTypeForm" @canceledForm="handleCanceledSensorTypeForm"/>
                                                </v-card-text>
                                            </v-card>
                                        </v-dialog>

                                        <!-- SHOW DIALOG -->
                                        <v-dialog v-model="dialogShow" ref="dialog" scrollable>
                                            <v-card>
                                                <v-card-title class="primary py-0">
                                                    <v-row class="subtitle-2 align-center white--text">
                                                        <v-col cols="auto" @click="dialogShow = false" class="cursor-pointer">Liste des types de capteur</v-col>
                                                        >
                                                        <v-col cols="auto">{{ shownSensorType.name }}</v-col>
                                                        <v-spacer></v-spacer>
                                                        <v-btn small text dark @click="dialogShow = false">Fermer</v-btn>
                                                    </v-row>
                                                </v-card-title>
                                                <v-card-text>
                                                    <SensorType v-model="shownSensorType" ref="Type de capteur"/>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="secondary" text @click="dialogShow = false">Fermer</v-btn>
                                                    <v-btn color="primary" @click="editItem(shownSensorType)">Modifier</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>
                                </v-row>

                                <v-data-table 
                                    :items="sensorTypes"
                                    :options.sync="pagination"
                                    :items-per-page="rowsPerPageItems"
                                    :server-items-length="total"
                                    :loading="loading"
                                    :headers="headers"
                                    :class="{mobile: isMobile}"
                                    :footer-props="{
                                        itemsPerPageText: 'Ligne par page',
                                    }"
                                >
                                    <template v-slot:item="props">
                                        <tr @click.stop="showItem(props.item)">
                                            <td>{{props.item.name}}</td>
                                            <td>{{props.item.csv_column}}</td>
                                            <td>{{props.item.unit_of_measure}}</td>
                                            <td>{{props.item.min}}</td>
                                            <td>{{props.item.max}}</td>
                                            <td class="text-right">
                                                <v-icon small class="mr-2" @click.stop="showItem(props.item)">remove_red_eye</v-icon>
                                                <v-icon small class="mr-2" @click.stop="editItem(props.item)">edit</v-icon>
                                                <v-icon small @click.stop="deleteItem(props.item)">delete</v-icon>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-slot:no-data>
                                        Aucun type de capteur
                                    </template>
                                </v-data-table>

                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-layout>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import SensorTypeForm from '../components/SensorTypeForm'
import SensorType from '../components/SensorType'

export default {
    components: {
        SensorTypeForm,
        SensorType,
    },
    data () {
        return {
            ready: false,
            sensorTypes: [],
            action: 'create',
            editedSensorType: {},
            shownSensorType: {},
            defaultSensorType: {},
            pagination: {
                page: 1,
            },
            search: '',
            total: 0,
            rowsPerPageItems: 10,
            headers: [
                {
                    text: 'Intitulé',
                    align: 'left',
                    value: 'name'
                },
                {
                    text: 'Colonne CSV',
                    align: 'left',
                    value: 'csv_column'
                },
                { 
                    text: 'Unité de mesure', 
                    align: 'left', 
                    value: 'unit_of_measure' 
                },
                { 
                    text: 'Min', 
                    align: 'center', 
                    value: 'min' 
                },
                { 
                    text: 'Max', 
                    align: 'center', 
                    value: 'max' 
                },
                {
                    text: '',
                    value: '',
                    sortable: false
                }
            ],
            dialogEdit: false,
            dialogShow: false,
            isMobile: false,
            loading: false,
        }
    },
    mounted () {
        // 
    },
    methods: {
        onResize() {
            this.isMobile = this.$vuetify.breakpoint.smAndDown;
        },
        getSensorTypes (queries) {
            this.$store.dispatch('sensorTypesRequest', queries)
                .then(result => {
                    this.sensorTypes = result.data
                    this.total = result.meta.total
                    this.loading = false
                    if (! this.ready)
                        this.$checkHistory('sensorType', sensorType => this.showItem(sensorType))
                    this.ready = true;
                })
                .catch(() => {
                    // 
                })
        },
        handleSavedSensorTypeForm (sensorType) {
            this.refreshPagination()
            this.shownSensorType = sensorType
            this.dialogEdit = false
        },
        handleCanceledSensorTypeForm () {
            this.dialogEdit = false
        },
        showItem (sensorType) {
            this.shownSensorType = sensorType
            this.dialogShow = true
        },
        createItem () {
            this.action = 'create'
            this.editedSensorType = Object.assign({}, this.defaultSensorType)
            this.dialogEdit = true
        },
        editItem (sensorType) {
            this.action = 'update'
            this.editedSensorType =  Object.assign({}, sensorType)
            this.dialogEdit = true
        },
        deleteItem (sensorType) {
            if (confirm('Êtes-vous sûr de vouloir supprimer ce type de capteur ?')) {
                this.$store.dispatch('sensorTypeDeleteRequest', { id: sensorType.id })
                    .then((result) => {
                        this.$store.commit('setSnack', {'type': 'success', 'msg': 'Type de capteur supprimé !'});
                        this.refreshPagination()
                    })
                    .catch((err) => {
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                    })
            }
        },
        refreshPagination () {
            const queries = {
                page: this.pagination.page,
                per_page: this.pagination.itemsPerPage,
                sort: this.pagination.sortBy,
                direction: this.pagination.sortDesc[0] ? 'desc' : 'asc',
                search: this.search,
            }

            this.loading = true
            this.getSensorTypes(queries)
        },
    },
    watch: {
        pagination: {
            handler () {
                this.refreshPagination()
            },
            deep: true
        },
        dialogShow() {
            if (this.dialogShow)
                this.$clicked_sensor_type = this.shownSensorType
            else
                this.$clicked_sensor_type = null

            if (this.dialogShow) this.$resetDialogScroll()
        },
        dialogEdit() {
            if (this.dialogEdit) this.$resetDialogScroll()
        }
    }
}
</script>
