<template>
    <v-form v-on:submit.prevent="onSubmit" ref="form" class="mb-5">
        <v-tabs show-arrows>
          <v-tab> Informations générales </v-tab>
          <v-tab> Informations complémentaires </v-tab>
          <v-tab> Intégrations </v-tab>
          <v-tab-item>
            <v-row>
              <v-col cols="12" :md="6">
                  <v-text-field
                      v-model="value.name"
                      label="Nom"
                  ></v-text-field>

                  <v-textarea
                      v-model="value.description"
                      label="Description"
                  ></v-textarea>

                  <v-text-field
                      v-model="value.phone"
                      label="Téléphone"
                  ></v-text-field>

                <v-text-field
                    v-model="value.email"
                    label="Email"
                ></v-text-field>
              </v-col>

              <v-col cols="12" :md="6" class="text-right">
                  <v-list-item class="px-0">
                      <v-list-item-avatar color="white" size="62" v-if="value.logo_url">
                          <img alt="" :src="value.logo_url">
                      </v-list-item-avatar>
                      <v-list-item-content>
                          <v-row>
                              <v-col>
                                  <v-file-input
                                      v-model="logo"
                                      value="logo"
                                      label="Logo"
                                      :clearable="false"
                                      :rules="[rules.image]"
                                  ></v-file-input>
                              </v-col>
                          </v-row>
                      </v-list-item-content>
                      <v-btn v-if="value.logo_url" dark small depressed class="red" @click="deleteLogo">supprimer</v-btn>
                  </v-list-item>

                  <v-autocomplete
                      v-model="value.sites"
                      :items="allSites"
                      item-text="complete_name"
                      item-value="id"
                      label="Sites"
                      multiple
                      chips
                      deletable-chips
                      return-object
                      hint="Veuillez séléctionner les univers auquel le client aura accès"
                  ></v-autocomplete>
                  <v-dialog v-model="dialogEdit" max-width="1400px" persistent scrollable v-if="action == 'update'">
                      <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click.stop="createSite" small rounded depressed class="mb-5 grey lighten-2">Nouvel univers</v-btn>
                      </template>
                      <SiteForm
                              :editable="dialogEdit"
                              ref="SiteForm"
                              v-model="editedSite"
                              action="create"
                              @savedForm="handleSavedSiteForm"
                              @canceledForm="handleCanceledSiteForm"
                              :key="localKey"
                      />
                  </v-dialog>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-text-field
                  v-model="value.address"
                  label="Adresse"
              ></v-text-field>

              <v-text-field
                  v-model="value.postCode"
                  label="Code postal"
              ></v-text-field>

              <v-text-field
                  v-model="value.city"
                  label="Ville"
              ></v-text-field>
              <v-color-picker v-model="backgroundColor" swatches-max-height="255" dot-size="20"></v-color-picker>
            </v-tab-item>

            <v-tab-item v-if="$acl.check('isAdmin')">
              <v-autocomplete
                  v-model="value.integrations"
                  :items="allInt"
                  item-text="name"
                  item-value="id"
                  :label="`Intégrations`"
                  multiple
                  chips
                  deletable-chips
                  show-select
                  return-object
                  hint="Veuillez séléctionner les intégrations auquel ce client aura accès"
                  v-on:change="testLecture()"
              ></v-autocomplete>
              <v-list>
                <v-list-group
                    v-for="integration in value.integrations"
                    v-bind:key="integration.name"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title v-text="integration.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item>
                    <v-autocomplete
                        v-model="intConfigs[`${integration.id}`]"
                        :items="allInt.filter((int) => int.id == integration.id)[0].integration_confs"
                        item-text="name"
                        item-value="id"
                        :label="`Configurations ${integration.name}`"
                        return-object
                        :hint="`Veuillez séléctionner la configuration ${integration.name} auquel le client aura accès`"
                        v-on:change="recheckSensors(integration, intConfigs[`${integration.id}`])"
                    ></v-autocomplete>
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete
                        v-if="intConfigs[`${integration.id}`]"
                        v-model="intSensors[`${integration.id}`]"
                        :items="intConfigs[`${integration.id}`].integration_sensors"
                        item-text="name"
                        item-value="id"
                        :label="`Capteurs ${integration.name}`"
                        multiple
                        chips
                        deletable-chips
                        show-select
                        return-object
                        :hint="`Veuillez séléctionner les capteurs ${integration.name} auquel le client aura accès`"
                    ></v-autocomplete>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-tab-item>
        </v-tabs>

        <div class="px-5 py-3 absolute--position left right bottom white text-right">
            <v-btn @click.stop="cancel" text color="secondary">Annuler</v-btn>
            <v-btn color="primary" type="submit" :disabled="loading" :loading="loading">Sauvegarder</v-btn>
        </div>
    </v-form>
</template>

<script>
import SiteForm from '../components/SiteForm'
import { uuid } from "vue-uuid";
import {SITE_DEFAULT} from "@/const/sitetypes";

export default {
    components: {
        SiteForm,
    },
    props: {
        value: Object,
        action: {
            type: String,
            default: 'update'
        },
    },
    data () {
        return {
            loading: false,
            backgroundColor: this.$vuetify.theme.currentTheme.primary,
            rules: {
                required: value => !!value || 'Champ requis',
                image: value => !value || value.type === 'image/jpeg' || value.type === 'image/png' || value.type === 'image/gif' || 'Fichier de type image (.jpg, .png) requis.',
            },
            allSites: [],
            allInt: {},
            dialogEdit: false,
            editedSite: {},
            intConfigs: [],
            intSensors: [],
            localKey: 1,
            defaultSite: {
                'client_id': this.value.id,
                'name': '',
                'description': '',
                'lagoon_volume': '',
                'logo': null,
                'logo_url': '',
                'sensors': [],
                'blower_model': '',
                'maintenance': 0,
                'comment': '',
                'installation_date': '',
                'visits': [],
                'dco_min': '',
                'dco_max': '',
                'dbo_min': '',
                'dbo_max': '',
                'mes_min': '',
                'mes_max': '',
                'ftp_host': '',
                'ftp_user': '',
                'ftp_pwd': '',
                'ftp_path': '',
            },
        }
    },
    computed: {
        logo : {
            get: function() {
                if (typeof this.value.logo == 'string' || ! this.value.logo) {
                    return null
                }
                return this.value.logo
            },
            set: function(val) {
                if (val instanceof File)
                    this.value.logo_url = URL.createObjectURL(val)
                this.value.logo = val
            }
        },
    },
    watch: {
        value() {
            this.$refs.form.resetValidation()
            /*if(this.allSencropConfigs !== null && this.value.sencropConfig !== null){
              this.fetchSencropSensors(this.value.sencropConfig.appID, this.value.sencropConfig.appSecret, this.value.sencropConfig.partnerID);
            }*/
        },
        dialogEdit() {
            if (this.dialogEdit) this.$resetDialogScroll()
        },
    },
    mounted() {
        this.fetchSites()
        this.fetchIntConfigs()
    },
    created() {
          this.intConfigs = [];
          this.intSensors = [];
    },
    methods: {
        onSubmit() {
          if (this.$refs.form.validate()) {
            this.loading = true;

            // prepare datas
            const datas = new FormData();
            datas.append('name', this.value.name)
            datas.append('color', this.backgroundColor)
            datas.append('description', this.value.description)
            datas.append('phone', this.value.phone)
            datas.append('email', this.value.email)
            datas.append('address', this.value.address)
            datas.append('postCode', this.value.postCode)
            datas.append('city', this.value.city)

            if (!this.intConfigs || this.intConfigs == null || this.intConfigs === undefined) {
              datas.set('sensorsConfig[]', []);
              datas.set('sensorsSubConfig[]', []);
            }
            else if(this.value.integrations != null || this.value.integrations !== undefined) {
              for (let key of Object.keys(this.value.integrations)) {
                datas.append('sensorsConfig[]', this.value.integrations[key].id + ',' + this.intConfigs[this.value.integrations[key].id].id);
                //datas.append('sensorsSubConfig[]', );
              }
            }

            if (!this.intSensors) datas.set('sensors[]', [])
            else this.intSensors.forEach((element) => {
              element.forEach((s) => {
                datas.append('sensors[]', s.id);
              });
            });

            datas.append('logo', this.value.logo)
            datas.append('remove_logo', this.value.remove_logo)

            datas.append('user_id', this.$store.getters.getProfile.id)

            if (!this.value.sites) datas.set('sites[]', [])
            else this.value.sites.forEach(s => datas.append('sites[]', s.id))

            // clean null values
            for (var d of datas.entries()) {
              // console.log(d[0]+ ', ' + d[1]+' ('+ (typeof d[1]) +')');
              if (d[1] == 'null' || d[1] == 'undefined') datas.set(d[0], '')
            }

            let request = 'clientCreateRequest'
            let queries = datas

            if (this.action === 'update') {
              datas.append('_method', 'put')
              request = 'clientEditRequest'
              queries = {id: this.value.id, datas}
            }

            this.$store.dispatch(request, queries)
                .then((client) => {
                  this.$refs.form.resetValidation()
                  this.loading = false;
                  this.$store.commit('setSnack', {'type': 'success', 'msg': 'Client enregistré !'});
                  this.$emit('savedForm', client);
                })
                .catch((err) => {
                  this.loading = false;
                  if (err.response.status != 403)
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
          }
        },
        cancel () {
            this.$refs.form.reset()
            this.$emit('canceledForm');
        },
        deleteLogo() {
            this.value.logo_url = null
            this.value.logo = null
            this.value.remove_logo = true
        },
        fetchSites() {
            this.$store.dispatch('sitesRequest', {})
                .then((result) => {
                    this.allSites = result.data
                })
                .catch((err) => {
                })
        },
        fetchIntConfigs() {
          this.$store.dispatch('integrationsRequest', {})
              .then((result) => {
                this.allInt = result;

                this.intConfigs = {};

                //console.log(this.allInt);

                /*this.allInt.forEach((int) => {
                  console.log(this.value.integrations);
                  //this.intConfigs[int.id] = this.value.integration.filter((integration) => int.id == integration.id)[0].integration_confs;
                });*/

                for (let key of Object.keys(this.value.integrations)) {
                  this.intConfigs[this.value.integrations[key].id] = this.value.integrations[key].integrations_confs[0];
                  this.recheckSensors(this.value.integrations[key],this.value.integrations[key].integrations_confs[0]);
                }

              })
              .catch((err) => {
              });

        },
        recheckSensors(integration, integration_conf){
          this.intConfigs[integration.id].integration_sensors = this.intConfigs[integration.id].integration_sensors.filter((intSensor) => (intSensor.client_id === this.value.id || intSensor.client_id === null) && intSensor.integration_conf_id === integration_conf.id );
          this.intSensors[integration.id] = this.intConfigs[integration.id].integration_sensors.filter((intSensor) => intSensor.client_id === this.value.id && intSensor.integration_conf_id === integration_conf.id );
        },
        createSite () {
            this.editedSite = Object.assign({}, SITE_DEFAULT)
            this.dialogEdit = true;
            this.localKey += 1;
        },
        handleSavedSiteForm (site) {
            this.value.sites.push(site)
            this.fetchSites()
            this.dialogEdit = false
        },
        handleCanceledSiteForm () {
            this.dialogEdit = false
        },
      testLecture() {
          console.log(this.value.sInt);
      },
    }
}
</script>
