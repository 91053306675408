<template>
  <div>
    <v-form v-on:submit.prevent="submit" ref="form">
      <v-card>
        <v-card-title>
          <span v-if="action == 'import_values'" class="headline"
            >Importer un fichier de valeurs</span
          >
          <span v-else class="headline">Importer un fichier d'évenements</span>
        </v-card-title>
        <v-card-text>
          <v-row></v-row>

          <v-file-input
            label="Fichier joint"
            v-model="importedData.file"
            small-chips
            show-size
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-btn depressed @click="cancel">Annuler</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            @click="submit"
            :disabled="loading"
            :loading="loading"
            >Importer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <v-form v-if="parcelleKeyFound" ref="formauto">
      <v-card>
        <v-card-text>
          <span class="headline"
            >Une colonne Parcelle a été trouvé ! Voulez vous automatiquement
            créer et importer les données dans des univers enfants parcelles ?
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn depressed @click="setAutoCreateChildFalse">Non</v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="setAutoCreateChildTrue"
            >Oui</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>


<script>
import { buildSaveSiteRequest, getSite } from "../vuex/modules/site";
const DOMAINE_PARCELLE_KEYS = ["Parcelle", "Parcelle_name", "Parcelles"];
const VIGNE_DATA_KEYS = [
  ...DOMAINE_PARCELLE_KEYS,
  "Id_chateau",
  "Cepage",
  "Semestre",
  "Annee",
  "Date",
  "date",
  "Time",
  "time",
  "DateTime",
  "dateUTC",
  "dateech",
  "datemes",
  "timestamp",
  "Timestamp",
  "TimeStamp",
  "dateHrLwt",
  "line_nb",
  "Traitement",
];
export default {
  props: {
    value: Object,
    site: Object,
    action: { type: String, default: "import_values" },
    // action: { type: String, default: "import_events" },
  },
  data() {
    return {
      loading: false,
      elastic_winery_mesures_index: "winery_mesures_" + this.site.client_id,
      elastic_winery_events_index: "winery_events_" + this.site.client_id,
      parcelleKeyFound: false,
      autoChildCreation: false,
    };
  },
  computed: {
    importedData() {
      return this.value
        ? this.value
        : {
            value: null,
            type: null,
            Datetime: null,
            date: new Date().toISOString().substr(0, 10),
            time:
              new Date().getHours() +
              ":" +
              (new Date().getMinutes() < 10 ? "0" : "") +
              new Date().getMinutes(),
            file: null,
          };
    },
    user() {
      return this.$store.getters.getProfile;
    },
  },
  mounted() {
    //
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.formatData();
        // console.log(this.importedData.fileContent);

        let Head = this.getHeaders(this.importedData.fileContent);

        this.importedData.headers = this.getValidHeaders(Head.headers);
        this.importedData.delimiter = Head.delimiter;
        console.log(this.importedData.headers);

        for (let header of Head.headers) {
          header = header.replace(/['"]+/g, "");
          if (DOMAINE_PARCELLE_KEYS.includes(header)) {
            this.parcelleKeyFound = true;
            break;
          }
        }

        if (!this.parcelleKeyFound) this.save();
      }
    },
    async formatData() {
      // console.log('test',this.importedData)
      this.importedData.createdBy = this.user;
      this.importedData.createdAt = this.$moment().valueOf();
      this.importedData.Datetime = this.$moment(
        this.importedData.date + " " + this.importedData.time + ":00"
      ).unix();
      //   this.importedData.value = this.importedData.value.replace(",", ".");
      if (this.importedData.file) {
        this.importedData.hasFile = true;
        this.importedData.fileName = this.importedData.file.name;
        this.importedData.fileType = this.importedData.file.type;
        this.importedData.fileContent = await this.readFileAsync();
        // this.importedData.fileData = this.parseData(this.importedData.fileContent)
      } else {
        this.importedData.hasFile = false;
      }
    },
    readFileAsync() {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsText(this.importedData.file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    },

    getValidHeaders(headers) {
      let newHeaders = [];

      for (let header of headers) {
        header = header.replace(/['"]+/g, "");
        if (VIGNE_DATA_KEYS.includes(header)) {
          continue;
        }
        if (header === "") {
          // header = "No_Title";
          continue;
        }
        if (header.includes(".")) {
          header = header.replaceAll(".", "");
        }
        newHeaders.push(header);
      }
      return newHeaders;
    },

    setAutoCreateChildFalse() {
      this.setAutoCreateChild(false);
      this.parcelleKeyFound = false;

      this.save();
    },
    setAutoCreateChildTrue() {
      this.setAutoCreateChild(true);
      this.parcelleKeyFound = false;

      this.save();
    },
    setAutoCreateChild(autoCreateChild) {
      this.autoChildCreation = autoCreateChild;
    },
    save() {
      let index;
      if (this.action === "import_values") {
        index = this.elastic_winery_mesures_index;
      } else {
        index = this.elastic_winery_events_index;
      }
      let query = {
        site_sensors: this.site.sensors,
        index,
        filename: this.importedData.fileName,
        body: { ...this.importedData },
        site: this.site.id,
      };

      if (this.importedData.headers) {
        query = {
          ...query,
          headers: this.importedData.headers,
          delimiter: this.importedData.delimiter,
          autoChildCreation: this.autoChildCreation,
          action: this.action,
        };
      }
      return this.$store
        .dispatch("elasticCreateRequest", query)
        .then((result) => {
          console.log("Document created.", result.data);
          if (result.data.headers) {
            this.importedData.headers = this.getValidHeaders(
              result.data.headers
            );
          }
          getSite(this.$store, this.site.id)
            .then((result) => {
              this.site = result.data;
              console.log("Got site.", this.site);
              if (this.action === "import_values") {
                console.log(this.site.sensors);
                for (let header of this.importedData.headers) {
                  let hasSensor = false;
                  for (let s of this.site.sensors) {
                    if (s.csv_column === header) {
                      hasSensor = true;
                    }
                  }
                  if (!hasSensor) {
                    let sensorCreated = {};
                    sensorCreated.type = "";
                    sensorCreated.unit_of_measure = "";
                    sensorCreated.min = "";
                    sensorCreated.max = "";
                    let sensor = {
                      ...sensorCreated,
                      name: header,
                      csv_column: header,
                    };

                    this.site.sensors.push(sensor);
                  }
                }
                console.log(this.site.sensors);
              } else {
                if (!this.site.events || this.site.events == null)
                  this.site.events = { names: [] };
                console.log(this.site.events);
                for (let header of this.importedData.headers) {
                  this.site.events.names.push(header);
                }
              }
              let build = buildSaveSiteRequest(this.site, false);
              console.log("save", this.site);
              this.$store
                .dispatch(build.request, build.queries)
                .then((site) => {
                  this.$refs.form.reset();
                  this.$store.commit("setSnack", {
                    type: "success",
                    msg: "Fichier correctement importé",
                  });
                  this.$emit("saved");
                  this.loading = false;
                })
                .catch((err) => {
                  console.log("err", err);
                  this.$store.commit("setSnack", { type: "error", msg: err });
                  this.loading = false;
                  this.cancel();
                });
            })
            .catch((err) => {
              console.log("err", err);
              this.$store.commit("setSnack", { type: "error", msg: err });
              this.loading = false;
              this.cancel();
            });
        })
        .catch((err) => {
          console.log("err", err);
          this.$store.commit("setSnack", { type: "error", msg: err });
          this.loading = false;
          this.cancel();
        });
    },
    cancel() {
      this.$refs.form.reset();
      this.$emit("cancel");
    },

    getHeaders(csv) {
      let lines = csv.split("\n");

      let result = [];
      let head = lines[0];
      let test = head.split(",");
      let test2 = head.split(";");
      let delimiter = ",";
      if (test.length < test2.length) {
        delimiter = ";";
      }
      let headers = head.split(delimiter);

      return { headers, delimiter };
    },
  },
};
</script>
