<template>
    <v-form v-on:submit.prevent="onSubmit" ref="form" class="mb-5">
        <v-row>
            <v-col>
                <v-text-field
                    v-model="value.name"
                    label="Intitulé"
                    :rules="[rules.required]"
                ></v-text-field>

                <v-text-field
                    v-model="value.csv_column"
                    label="Colonne CSV"
                ></v-text-field>

                <v-text-field
                    v-model="value.unit_of_measure"
                    label="Unité de mesure"
                ></v-text-field>

                <v-text-field
                    v-model="value.min"
                    label="Valeur minimale pour alerte"
                ></v-text-field>

                <v-text-field
                    v-model="value.max"
                    label="Valeur maximale pour alerte"
                ></v-text-field>
            </v-col>

        </v-row>

        <div class="px-5 py-3 absolute--position left right bottom white text-right">
            <v-btn @click.stop="cancel" text color="secondary">Annuler</v-btn>
            <v-btn color="primary" type="submit" :disabled="loading" :loading="loading">Sauvegarder</v-btn>
        </div>
    </v-form>
</template>

<script>
export default {
    props: {
        value: Object,
        action: {
            type: String,
            default: 'update'
        }
    },
    data () {
        return {
            loading: false,
            rules: {
                required: value => !!value || 'Champ requis',
                minlength: value => value.length >= 8 || '8 caractères min.',
            },
        }
    },
    computed: {
        // 
    },
    watch: {
        value() {
            this.$refs.form.resetValidation()
        }
    },
    mounted() {

    },
    methods: {
        onSubmit () {
            if (this.$refs.form.validate()) {

                this.loading = true;

                let request = 'sensorTypeCreateRequest'
                let queries = this.value

                if (this.action === 'update') {
                    request = 'sensorTypeEditRequest'
                    queries = {id: this.value.id, datas: this.value}
                }

                this.$store.dispatch(request, queries)
                    .then((sensorType) => {
                        this.$refs.form.resetValidation()
                        this.loading = false;
                        this.$store.commit('setSnack', {'type': 'success', 'msg': 'Type de capteur enregistré !'});
                        this.$emit('savedForm', sensorType);
                    })
                    .catch((err) => {
                        this.loading = false;
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                    })
            }
        },
        cancel () {
            this.$refs.form.reset()
            this.$emit('canceledForm');
        },
    }
}
</script>
