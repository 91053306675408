<template>
  <v-form ref="form" class="mb-5">
    <v-card>
      <v-card-title class="primary white--text">
        <span v-if="editable">
          <template v-if="action === 'create'">Création d'un univers</template>
          <template v-if="action === 'update'">Modification d'un univers</template></span
        >
        <span v-else>Information de l'univers</span>
        <v-spacer></v-spacer>
        <v-btn small text dark @click.stop="cancel">Annuler</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row v-if="editable">
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  v-model="value.name"
                  label="Nom"
                  filled
                  outlined
                  :rules="[rules.required]"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item>
              <v-select
                :items="user.sites"
                label="Copier la configuration de l'univers"
                item-text="complete_name"
                item-value="id"
                return-object
                clearable
                filled
                outlined
                v-model="duplicateSite"
                @change="changeDuplicateSite"
              ></v-select>
            </v-list-item> -->
            <!-- <v-list-item>
              <v-autocomplete
                v-model="value.client_id"
                :items="availableClients"
                label="Client"
                item-text="name"
                item-value="id"
                :rules="[rules.required]"
                filled
                outlined
                clearable
                @change="fetchClientSites"
              ></v-autocomplete>
            </v-list-item> -->
          </v-col>
        </v-row>
        <template v-else>
          <v-row>
            <v-col cols="12" :md="6">
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col cols="auto">
                      <span class="headline">{{ value.name }}</span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col v-if="!editable" cols="auto">
                      <v-btn
                        color="secondary"
                        fab
                        :to="{
                          name: 'Dashboard',
                          params: { site_id: value.id },
                        }"
                        class="mr-3"
                      >
                        <v-icon>bar_chart</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col>
            <template v-if="!editable && value.client">
              <v-list-item-title>Client</v-list-item-title>
              <v-list-item-subtitle>{{ value.client.name }}</v-list-item-subtitle>
            </template>
              <template>
                <v-textarea
                    v-if="editable"
                    filled
                    outlined
                    chips
                    v-model="value.description"
                    label="Description"
                ></v-textarea>
              </template>
              <template v-if="editable">
                <v-list-item class="px-0">
                  <v-list-item-avatar color="white" size="62" v-if="value.logo_url">
                    <img alt="" :src="value.logo_url" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-row>
                      <v-col>
                        <v-file-input
                            v-model="logo"
                            v-if="!value.logo_url || value.logo_url == null || value.logo_url == undefined"
                            value="logo"
                            label="Photo étiquette"
                            filled
                            outlined
                            chips
                            :clearable="false"
                            :rules="[rules.image]"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                  <v-btn
                      v-if="editable && value.logo_url"
                      dark
                      small
                      depressed
                      class="red"
                      @click="deleteLogo"
                  >supprimer</v-btn
                  >
                </v-list-item>
              </template>
              <v-menu
                  v-if="editable"
                  v-model="menu_installation_date"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
              <template v-slot:activator="{ on }">
                <v-text-field
                    :value="formattedInstallationDate"
                    label="Millesime"
                    prepend-icon="event"
                    filled
                    outlined
                    readonly
                    v-on="on"
                    clearable
                ></v-text-field>
              </template>
              <v-date-picker
                      ref="millesimePicker"
                      v-model="value.installation_date"
                      no-title
                      scrollable
                      @click:stop="this.internalActivePicker = 'YEAR';"
                      @input="setYearMode()"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-card-actions v-if="editable">
          <v-spacer></v-spacer>
          <v-btn @click.stop="cancel" text color="secondary">Annuler</v-btn>
          <v-btn color="primary" @click.stop="save" :disabled="loading" :loading="loading"
            >Sauvegarder</v-btn
          >
        </v-card-actions>
        <v-card-actions v-if="!editable">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click.stop="cancel">Fermer</v-btn>
          <v-btn v-if="canModify()" color="primary" @click.stop="edit">Modifier</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card></v-form
  >
</template>

<script>
import UserForm from "../components/UserForm";
import FtpForm from "../components/FtpForm";
import SiteSensors from "../components/SiteSensors";
import SiteCategories from "../components/SiteCategories";
import SiteEvents from "../components/SiteEvents";
import { buildSaveSiteRequest } from "@/vuex/modules/site";
import { SITE_TYPES_VALUES, DEFAULT_SITE_TYPE } from "@/const/sitetypes";
import { getSitePromise } from "@/vuex/modules/site";

import * as MathLive from "@/mathlib/mathlive.min.mjs";
import VueMathfield from "@/mathlib/vue-mathlive.mjs";
import Vue from "vue";
Vue.use(VueMathfield, MathLive);

export default {
  components: {
  },
  props: {
    value: Object,
    action: {
      type: String,
      default: "update",
    },
    editable: { type: Boolean, default: false },
  },
  data() {
    let user = this.$store.state.profile.user;
    this.initSiteEmptyData();
    return {
      duplicateSite: -1,
      user,
      loading: false,
      calcForm: null,
      rules: {
        required: (value) => !!value || "Champ requis",
        image: (value) =>
          !value ||
          value.type === "image/jpeg" ||
          value.type === "image/png" ||
          value.type === "image/gif" ||
          "Fichier de type image (.jpg, .png) requis.",
      },
      availableUsers: [],
      availableClients: [],
      availableInt: [],
      availableIntSensors: [],
      createUserDialogVisible: false,
      editedUser: {},
      formula: null,
      defaultUser: {
        lastname: "",
        firstname: "",
        email: "",
        role: "",
        sites: [],
      },
      menu_installation_date: false,
      clientSites: [],
      SITE_TYPES_VALUES,
      childIdsToName: null,
      availableMesures: [],
    };
  },
  computed: {
    logo: {
      get: function () {
        if (typeof this.value.logo == "string" || !this.value.logo) {
          return null;
        }
        return this.value.logo;
      },
      set: function (val) {
        if (val instanceof File) this.value.logo_url = URL.createObjectURL(val);
        this.value.logo = val;
        this.$forceUpdate();
      },
    },
    formattedInstallationDate() {
      return this.value.installation_date
        ? this.$moment(this.value.installation_date).format("DD/MM/YYYY")
        : "";
    },
    availableParents() {
      let availableParents = [...this.clientSites].filter((site) => {
        return (
          this.value.id != site.id &&
          (!this.value.relations ||
            !this.value.relations.children ||
            !this.value.relations.children.map((item) => item.id).includes(site.id))
        );
      });
      return availableParents;
    },
    availableChildren() {
      let availableChildren = [...this.clientSites].filter((site) => {
        return (
          this.value.id != site.id &&
          (!this.value.relations ||
            !this.value.relations.parents ||
            !this.value.relations.parents.map((item) => item.id).includes(site.id))
        );
      });
      return availableChildren;
    },
  },
  watch: {
    value: {
      handler() {
        this.$refs.form.resetValidation();
        this.initSiteEmptyData();
      },
      deep: true,
    },
    createUserDialogVisible() {
      if (this.createUserDialogVisible) this.$resetDialogScroll();
    },
  },
  async mounted() {
    this.initSiteEmptyData();
    this.setYearMode();
    this.fetchUsers();
    this.fetchClients();
    this.fetchClientSites();
    this.fetchIntConfigs();
    this.childIdsToName = await this.getChildIdsToName();
     console.log("Site Form childIdsToName :" + this.childIdsToName);
    this.availableMesures = await this.getAvailableMesures();
   console.log("Site Form availableMesures :" + this.availableMesures);
  },
  updated() {
    // console.log("Site Form sensors :" + this.value.sensors);
    // console.log("Site Form (event):" + this.value.events);
  },
  methods: {
    setYearMode() {
        this.$nextTick(() => {
            this.$refs.millesimePicker.internalActivePicker = 'YEAR';
        });
    },
    async getChildIdsToName() {
      let childIdsToName = {};
      if (this.value.relations && this.value.relations.children)
        for (let child of this.value.relations.children) {
          let childSite = await this.getChildSite(child.id);
          childIdsToName[child.id] = childSite.name;
        }
      return childIdsToName;
    },
    async getChildSite(child_id) {
      let childSite;
      let filteredSites = this.clientSites.filter((s) => s.id == child_id);
      if (filteredSites.length == 1) {
        childSite = filteredSites[0];
      } else if (filteredSites == 0 && this.$store.state.profile.user.role == "admin") {
        childSite = await getSitePromise(this.$store, child_id);
      } else {
        console.log("Error : filter give ", filteredSites);
      }
      return childSite;
    },

    async getAvailableMesures() {
      let availableMesures = [];
      if (this.value.sensors) {
        availableMesures = [...this.value.sensors];
        if (this.childIdsToName) {
          for (let child of Object.keys(this.childIdsToName)) {
            let childSite = await this.getChildSite(child);
            availableMesures = [...availableMesures, ...childSite.sensors];
          }
        }
      }
      return availableMesures;
    },
    /**
     * duplicate site configuration !
     */
    changeDuplicateSite() {
      this.value.client_id = this.duplicateSite.client_id;
      this.value.type = this.duplicateSite.type;
      this.value.is_allow_year_compare = this.duplicateSite.is_allow_year_compare;
      this.value.is_allow_input_value = this.duplicateSite.is_allow_input_value;
      this.value.logo = this.duplicateSite.logo;
      this.value.logo_url = this.duplicateSite.logo_url;
      this.value.users = this.duplicateSite.users;
      this.value.sensors = [];
      for (let sensor of this.duplicateSite.sensors) {
        let duplicateSensor = {};
        duplicateSensor.name = sensor.name;
        duplicateSensor.csv_column = sensor.csv_column;
        duplicateSensor.unit_of_measure = sensor.unit_of_measure;
        this.value.sensors.push(duplicateSensor);
      }
      this.value.visualizations = [];
      this.value.events = this.duplicateSite.events;
      for (let v of this.duplicateSite.visualizations) {
        let dv = {};
        dv.axes = v.axes;
        dv.name = v.name;

        this.value.visualizations.push(dv);
      }
      if (this.duplicateSite.relations) {
        this.value.relations = Object.assign(this.duplicateSite.relations);
      }
      this.fetchClientSites();
    },
    canModify() {
      return this.user.role == "user_admin" || this.user.role == "admin";
    },

    initSiteEmptyData() {
      let user = this.$store.state.profile.user;
      if (!this.value.relations) {
        this.value.relations = {};
      }
      if (!this.value.relations.children || this.value.relations.children == null) {
        this.value.relations.children = [];
      }
      if (!this.value.relations.parents || this.value.relations.parents == null) {
        this.value.relations.parents = [];
      }
      if (!this.value.users || this.value.users == null || this.value.users.length == 0) {
        this.value.users = [user];
      }
      // if (!this.value.sensors || this.value.sensors == null)
      //   this.value.sensors = [];
      if (!this.value.type || this.value.type == null || this.value.type == "") {
        this.value.type = DEFAULT_SITE_TYPE;
      }
      // if (!this.value.events) {
      //   this.value.events = []
      // }
    },
    showErr(err) {
      this.$store.commit("setSnack", {
        type: "red",
        err,
      });
    },
    saveSitePromise(site) {
      return new Promise((resolve, reject) => {
        let build = buildSaveSiteRequest(site, this.action !== "update");
        console.log("save", this.value);
        this.$store
          .dispatch(build.request, build.queries)
          .then((site) => {
            this.$store.commit("setSnack", {
              type: "success",
              msg: "Univers enregistré !",
            });
            resolve(site);
          })
          .catch((err) => {
            this.saveErr(err);
          });
      });
      //TODO
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        this.saveSitePromise(this.value)
          .then((site) => {
            this.$refs.form.resetValidation();
            this.loading = false;
            this.$emit("savedForm", site);
          })
          .catch((err) => {
            this.saveErr(err);
          });
      }
    },
    saveErr(err) {
      this.loading = false;
      let msg = err;
      if (err.response && err.response.status != 403) msg = err.response.data;
      this.showErr(msg);
    },

    cancel() {
      this.$refs.form.resetValidation();
      this.$emit("canceledForm");
    },
    edit() {
      this.$emit("editForm");
    },
    deleteLogo() {
      this.value.logo_url = null;
      this.value.logo = null;
      this.value.remove_logo = true;
      this.$forceUpdate();
    },
    fetchUsers() {
      if (this.user.role == "admin") {
        this.$store
          .dispatch("usersRequest", {})
          .then((result) => {
            this.availableUsers = result.data;
          })
          .catch((err) => this.showErr(err));
      } else {
        this.availableUsers = [...this.user.users];
        this.availableUsers.push(this.user);
        if (this.value && this.value.users) {
          for (let user of this.value.users) {
            this.availableUsers.push(user);
          }
        }
        return;
      }
    },
    fetchClients() {
      if (this.user.role == "admin") {
        this.$store
          .dispatch("clientsRequest", {})
          .then((result) => {
            this.availableClients = result.data;
          })
          .catch((err) => this.showErr(err));
      } else {
        this.availableClients = [...this.user.clients];
        return;
      }
    },
    fetchClientSites() {
      if (this.user.role == "admin") {
        this.$store
          .dispatch("sitesRequest", {})
          .then((result) => {
            this.clientSites = result.data.filter((site) => {
              return this.value.client_id == site.client_id;
            });
          })
          .catch((err) => this.showErr(err));
      } else {
        this.clientSites = this.user.sites.filter((site) => {
          return this.value.client_id == site.client_id;
        });
      }
    },

    createUser() {
      this.editedUser = Object.assign({}, this.defaultUser);
      this.createUserDialogVisible = true;
    },
    handleSavedUserForm(user) {
      //   this.value.users.push(user);
      this.fetchUsers();
      this.createUserDialogVisible = false;
    },
    handleCanceledUserForm() {
      this.createUserDialogVisible = false;
    },
    // handleSavedVisitForm(visit) {
    //   this.value.visits.push(this.visitCreated);
    //   this.visitModal = false;
    //   this.visitCreated = {};
    // },
    handleAddedSensorForm(sensor) {
      if (!this.value.sensors) {
        this.value.sensors = [];
      }
      //   this.value.sensors = [...this.value.sensors];
      this.value.sensors.push(sensor);
      this.$forceUpdate();
    },
    handleDeletedSensor(sensor) {
      this.value.sensors = this.value.sensors.filter((row) => row != sensor);
      this.$forceUpdate();
    },
    handleAddEvent(event) {
      this.value.events.names.push(event);
    },

    handleDeleteEvent(event) {
      this.value.events.names = this.value.events.names.filter((row) => row != event);
    },

    handleAddCategory(category) {
      this.value.categories.names.push(category);
    },

    handleDeleteCategory(category) {
      this.value.categories.names = this.value.categories.names.filter(
        (row) => row != category
      );
    },
    fetchIntConfigs() {
      let i = 0;
      this.$store.dispatch('integrationsRequest', {})
          .then((result) => {
            this.availableInt = result;

            this.availableIntSensors = [];

            this.availableInt.filter((int) => int.id == this.value.integration.id)[0].integration_confs.forEach((intConf) => {
              intConf.integration_sensors.forEach((sensor) => {
                this.availableIntSensors.push(sensor);
              });
            });

            this.availableIntSensors = this.availableIntSensors.filter((intSensor) => ((intSensor.client_id === this.value.client_id && intSensor.client_id != null) || intSensor.is_allowed_for_demo == true))
          })
          .catch((err) => {
          });
    },
    recheckAvailableSensors(){
      this.availableIntSensors = [];

      this.value.integration.integration_confs.forEach((intConf) => {
          intConf.integration_sensors.forEach((sensor) => {
            this.availableIntSensors.push(sensor);
          });
      });

      this.availableIntSensors = this.availableIntSensors.filter((intSensor) => ((intSensor.client_id === this.value.client_id && intSensor.client_id != null) || intSensor.is_allowed_for_demo == true))
    },
    autoFillData(){
      if(this.value.integration){
        this.value.sensors = JSON.parse(this.value.integration.csv_data);
      }
    },
    autoFillFTP(){
      if(this.value.integration_sensor){
        this.value.ftp_host = "172.20.6.12";
        this.value.ftp_user = "mtp";
        this.value.ftp_pwd = "WelcomeToWinery";
        this.value.ftp_path = "/" + this.value.integration.name + "/" + this.value.integration_sensor.name + "/";

        this.autoFillData();
      }
    },
  },
};
</script>
