<template>
    <div>
        <v-row no-gutters>
            <v-col cols="12">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="headline">{{ value.name }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Colonne CSV</v-list-item-title>
                        <v-list-item-subtitle>{{ value.csv_column }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Unité de mesure</v-list-item-title>
                        <v-list-item-subtitle>{{ value.unit_of_measure }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Valeur minimum pour alerte</v-list-item-title>
                        <v-list-item-subtitle>{{ value.min }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Valeur maximum pour alerte</v-list-item-title>
                        <v-list-item-subtitle>{{ value.max }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

            </v-col>
        </v-row>
    </div>
</template>
<script>
export default{
    components: {
        // 
    },
    props: {
        value: {
            type: Object,
            required: true
        },
    },
    data(){
        return {
            // 
        }
    },
    mounted() {
        // 
    },
    methods: {
        // 
    }
}
</script>