<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-card class="pa-4" outlined>
        <v-card-title class="text-h5">{{ label }}</v-card-title>
        <v-card-text class="display-1">{{ value }}</v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    }
  }
};
</script>

<style scoped>
.display-1 {
  font-weight: bold;
}
</style>
