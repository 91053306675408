<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <template v-if="nearestYears.events">
          <v-col v-for="event in Object.keys(nearestYears.events)" :key="event">
            <table>
              <caption>
                Date
                {{
                  event
                }}
              </caption>
              <!-- <thead>
                <tr>
                  <th>Millésime</th>
                  <th>{{ event }}</th>
                </tr>
              </thead> -->
              <tbody>
                <tr v-for="row in nearestYears.events[event]" :key="row.year">
                  <td>{{ row.year }}</td>
                  <td>+/-{{ row.value }}J</td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </template>
        <v-col>
          <template v-if="nearestYears.Precipitations">
            <table id="Precipitation">
              <!-- <thead>
                <tr>
                  <th>Millésime</th>
                  <th>Cumul Precipitation</th>
                </tr>
              </thead> -->
              <caption>
                Cumul Precipitations
              </caption>
              <tbody>
                <tr v-for="row in nearestYears.Precipitations" :key="row.year">
                  <td>{{ row.year }}</td>
                  <td>{{ row.value }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </v-col>
        <v-col>
          <template v-if="nearestYears.Tmaxsum">
            <table id="Tmaxsum">
              <caption>
                Cumul T° Max
              </caption>
              <!-- <thead>
                <tr>
                  <th>Millésime</th>
                  <th>Cumul Tmax</th>
                </tr>
              </thead> -->
              <tbody>
                <tr v-for="row in nearestYears.Tmaxsum" :key="row.year">
                  <td>{{ row.year }}</td>
                  <td>{{ row.value }}°</td>
                </tr>
              </tbody>
            </table>
          </template>
        </v-col>
        <v-col>
          <template v-if="nearestYears.Tsum10">
            <table id="Tsum10">
              <caption>
                Cumul T°>10°
              </caption>
              <!-- <thead>
                <tr>
                  <th>Millésime</th>
                  <th>Cumul T°>10°</th>
                </tr>
              </thead> -->
              <tbody>
                <tr v-for="row in nearestYears.Tsum10" :key="row.year">
                  <td>{{ row.year }}</td>
                  <td>{{ row.value }}°</td>
                </tr>
              </tbody>
            </table>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style scoped>
td {
  border: 2px solid black;
  padding: 1px;
  min-width: 80px;

}
/* table {
  min-width: 100px;
} */
</style>
<script>
export default {
  components: {},
  props: {
    nearestYears: { type: Object, required: true },
  },

  data() {
    return {};
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>
