<template>
  <v-app id="app">
    <link
      href="https://fonts.googleapis.com/css?family=Material+Icons"
      rel="stylesheet"
    />
    <Snackbar />
    <router-view />
  </v-app>
</template>

<script>

export default {};
</script>

<style lang="sass">
@import "~@/styles/style.scss"
</style>