<template>
    <v-container>
        <!-- Bell Button with Google Material Icons -->
        <v-menu
            v-model="menu"
            bottom
            left
            offset-y
        >
            <!-- The button that triggers the menu -->
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon class="material-icons">notifications</v-icon>
                </v-btn>
            </template>

            <!-- Notifications container -->
            <v-list>
                <router-link
                    v-for="(notification, index) in notifications"
                    :key="index"
                    :to="notification.route"
                    class="notification-link"
                >
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{ notification.title }}</v-list-item-title>
                            <v-list-item-subtitle>{{ notification.message }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <v-list-item v-if="notifications.length === 0">
                    <v-list-item-content>No notifications</v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            menu: false, // Controls the visibility of the notifications menu
            notifications: [] // List of notifications
        };
    },
    mounted() {
        this.toggleNotifications();
    },
    methods: {
        toggleNotifications() {
            //this.menu = !this.menu; // Open/Close the notification menu

            // Load notifications if they aren't loaded yet
            if (this.notifications.length === 0) {
                this.loadNotifications();
            }
        },
        loadNotifications() {
            // Simulate loading notifications from an API or script
            setTimeout(() => {
                this.notifications = [];
            }, 500); // Simulated delay for loading notifications
        }
    }
};
</script>

<style scoped>
.notification-link {
    text-decoration: none;
    color: inherit;
}
</style>