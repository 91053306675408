<template>
  <div class="user_admin_sites">
    <v-card v-if="ready">
      <v-card-text>
        <!-- CREATE/EDIT DIALOG -->
        <v-dialog v-model="dialogShow" max-width="1400px" persistent scrollable>
          <template
            v-if="user.role == 'admin' || user.role == 'user_admin'"
            v-slot:activator="{ on }"
          >
            <v-btn color="primary" v-on="on" @click.stop="createItem">Nouvel univers</v-btn>
          </template>
          <SiteForm
            :editable="dialogEdit"
            ref="SiteForm"
            v-model="site"
            :action="action"
            @savedForm="handleSavedSiteForm"
            @canceledForm="handleCanceledSiteForm"
            @editForm="editItem"
            :key="localKey"
          />
        </v-dialog>
        <v-btn color="primary" class="ml-4" @click.stop="
            $router.push({
              name: 'Degustations',
            })
          "
        >Dégustations</v-btn>

        <v-col cols="12" md="auto">
          <v-text-field
            v-model="search"
            clearable
            hide-details
            solo-inverted
            flat
            prepend-inner-icon="search"
            label="Rechercher"
            @input="updateSearch"
          ></v-text-field>
        </v-col>
        <v-list id="ul-root">
          <v-list-group v-for="client in Object.keys(mapClientNameToSitesWithoutChildren)" :key="mapClientNameToSitesWithoutChildren[client].name" v-model="mapClientNameToSitesWithoutChildren[client].active" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="client"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
                v-for="site in mapClientNameToSitesWithoutChildren[client]"
                :key="site.id"
                class="li-site"
            >
              <TreeNode
                  :node="site"
                  :datas="sites"
                  @editItem="editItem"
                  @showItem="showItem"
                  @deleteItem="deleteItem"
              />
            </v-list-item>
          </v-list-group>
        </v-list>
        <br />
      </v-card-text>
    </v-card>
  </div>
</template>
<style>
.client_title {
  height: 35px;
}

.li-site {
  list-style-type: none;
}

#ul-root {
  padding-left: 0;
}
</style>
<script>
import SiteForm from "../components/SiteForm";
import TreeNode from "../components/TreeNode";
import { SITE_DEFAULT } from "@/const/sitetypes";

export default {
  components: {
    SiteForm,
    TreeNode,
  },
  data() {
    return {
      user: null,
      ready: false,
      sendReadyEvent: false,
      sites: null,
      mapClientNameToSitesWithoutChildren: null,
      action: "create",
      site: {},
      pagination: {
        page: 1,
      },
      headers: [],
      dialogEdit: false,
      dialogShow: false,
      isMobile: false,
      loading: false,
      localKey: 0,
      search: "",
    };
  },
  mounted() {
    this.updateSites();
  },
  updated() {
    if (this.ready && this.sendReadyEvent) {
      this.$emit("ready", this.sites[0].id);
      this.sendReadyEvent = false;
    }
  },
  methods: {
    onResize() {
      this.isMobile = this.$vuetify.breakpoint.smAndDown;
    },

    updateSites(callback) {
      this.loading = true;
      this.$store.dispatch("profileRequest").then(() => {
        let profile = this.$store.state.profile;
        this.user = profile.user;
        this.sites = profile.user.sites;
        this.mapClientNameToSitesWithoutChildren = this.updateMapSites(this.sites);

        let i = 0;

        Object.keys(this.mapClientNameToSitesWithoutChildren).forEach((client) => {
          this.mapClientNameToSitesWithoutChildren[client]["name"] = client;
          i++;
        });

        console.log(this.mapClientNameToSitesWithoutChildren);

        this.editedSite = {};
        this.ready = true;
        this.sendReadyEvent = true;
        this.loading = false;
        if (callback) callback();
      });
    },

    updateSearch() {
      this.mapClientNameToSitesWithoutChildren = this.updateMapSites(this.sites);
    },

    getSite(siteId) {
      let site;
      for (let s of this.sites) {
        if (s.id == siteId) {
          site = s;
          break;
        }
      }
      return site;
    },
    isRootSite(site) {
      return (
        !site.relation ||
        site.relation.parents == undefined ||
        site.relation.parents == null ||
        site.relation.parents.length == 0
      );
    },
    updateMapSites(sites) {
      let mapClientNameToSitesWithoutChildren = {};
      let isChildren = {};
      for (let site of sites) {
        let name = site.complete_name;
        if (!name) {
          name = site.name;
        }
        if (name && this.search && !name.includes(this.search)) {
          continue;
        }
        let clientName = site.client.name;
        let clientSites = mapClientNameToSitesWithoutChildren[clientName];
        if (!clientSites) {
          mapClientNameToSitesWithoutChildren[clientName] = [site];
        } else {
          clientSites.push(site);
        }
        if (site.relations && site.relations.children) {
          for (let child of site.relations.children) {
            isChildren[child.id] = true;
          }
        }
      }
      Object.entries(mapClientNameToSitesWithoutChildren).map(
        ([clientName, clientSites]) => {
          clientSites = clientSites.filter((site) => {
            return !isChildren[site.id];
          });
          mapClientNameToSitesWithoutChildren[clientName] = clientSites;
        }
      );

      return mapClientNameToSitesWithoutChildren;
    },
    handleSavedSiteForm(site) {
      this.updateSites(() => {
        this.dialogEdit = false;
        this.dialogShow = false;
      });
    },

    handleCanceledSiteForm() {
      this.dialogShow = false;
      this.dialogEdit = false;
      this.$resetDialogScroll();
    },

    editItem(site) {
      this.action = "update";
      if (site) this.site = site;
      this.dialogEdit = true;
      this.localKey += 1;
      this.dialogShow = true;
    },
    showItem(site) {
      this.action = "update";
      this.site = site;
      this.dialogEdit = false;
      this.localKey += 1;
      this.dialogShow = true;
    },
    createItem() {
      this.action = "create";
      this.site = Object.assign({}, SITE_DEFAULT);
      this.dialogEdit = true;
      this.localKey += 1;
      this.dialogShow = true;
      this.$resetDialogScroll();
    },
    deleteItem(site) {
      if (confirm("Êtes-vous sûr de vouloir supprimer ce site ?")) {
        this.$store
          .dispatch("siteDeleteRequest", { id: site.id })
          .then((result) => {
            this.$store.commit("setSnack", {
              type: "success",
              msg: "Site supprimé !",
            });
            this.updateSites();
          })
          .catch((err) => {
            let msg = err;
            if (err.response && err.response.data) {
              msg = err.response.data;
            }
            this.$store.commit("setSnack", {
              type: "red",
              msg,
            });
          });
      }
    },
  },
  watch: {},
};
</script>
