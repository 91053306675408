<template>
    <div class="profile">
        <Navigation/>
        <v-main>
            <v-container fluid fill-height>
                <v-layout>
                    <v-col cols="12">
                        <v-card>
                            <v-card-text>
                                <User v-model="profile" ref="User"/>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="editItem(profile)">Modifier</v-btn>
                                <v-dialog v-model="dialogEdit" max-width="1400px" persistent scrollable>
                                    <v-card>
                                        <v-card-title class="primary white--text">
                                            Modification d'un utilisateur
                                            <v-spacer></v-spacer>
                                            <v-btn small text dark @click="dialogEdit = false">Annuler</v-btn>
                                        </v-card-title>
                                        <v-card-text>
                                            <UserForm ref="UserForm" v-model="editedUser" action="update" @savedForm="handleSavedUserForm" @canceledForm="handleCanceledUserForm"/> 
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-layout>
            </v-container>
        </v-main>
    </div>
</template>
<script>
import UserForm from '../components/UserForm'
import User from '../components/User'

export default {
    components: {
        UserForm,
        User,
    },
    data() {
        return {
            dialogEdit: false,
            profile: this.$store.state.profile.user,
            editedUser: {
                firstname: '',
                lastname: '',
                email: '',
                role: '',
                phone: '',
                sites: [],
            },
        }
    },
    mounted() {
        this.$resetHistory()
    },
    watch: {
        dialogEdit() {
            if (this.dialogEdit) this.$resetDialogScroll()
        }
    },
    methods: {
        handleSavedUserForm (user) {
            this.$store.dispatch('profileRequest').then()
            this.profile = user
            this.dialogEdit = false
        },
        handleCanceledUserForm () {
            this.dialogEdit = false
        },
        editItem (user) {
            this.editedUser =  Object.assign({}, this.profile)
            this.dialogEdit = true
        },
    }
}
</script>
