import Vue from 'vue'
import {
    convertAxes
} from '../../utils/axes';


const state = {
    status: '',
    sites: [],
}

const getters = {}

const actions = {
    siteRequest: async ({
        commit
    }, queries) => {
        let url = `/api/sites/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('siteRequest')
            Vue.axios.get(url)
                .then(result => {
                    if (result.data) {
                        let site = result.data.data;
                        if (site.events) {
                            site.events = JSON.parse(site.events);
                        } else if (!site.events || !site.events.names) {
                            site.events = {
                                names: []
                            }
                        }
                        if (site.categories) {
                            site.categories = JSON.parse(site.categories);
                        } else if (!site.categories || !site.categories.names) {
                            site.categories = {
                                names: []
                            }
                        }
                        if (site.relations) {
                            site.relations = JSON.parse(site.relations);
                        }
                        if (site.visualizations) {
                            for (let v of site.visualizations) {
                                if (v.axes) {
                                    v.axes = JSON.parse(v.axes);
                                    convertAxes(v.axes.axes);
                                }
                                if (v.events) {
                                    v.events = JSON.parse(v.events);
                                }
                            }
                        }

                        if (site.ftp_pwd) {
                            site.ftp_pwd = null;
                        }

                        commit('siteSuccess', result.data)
                        resolve(result.data)
                    }
                })
                .catch(err => {
                    commit('siteError');
                    reject(err)
                })
        })
    },
    siteEditRequest: async ({
        commit
    }, queries) => {
        let url = `/api/sites/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('siteRequest')
            Vue.axios.post(url, queries.datas)
                .then(result => {
                    commit('siteSuccess', result.data.data)
                    resolve(result.data.data)
                })
                .catch(err => {
                    commit('siteError');
                    reject(err)
                })
        })
    },
    siteCreateRequest: async ({
        commit
    }, queries) => {
        let url = `/api/sites`;

        return new Promise((resolve, reject) => {
            commit('siteRequest')
            Vue.axios.post(url, queries)
                .then(result => {
                    commit('siteSuccess', result.data.data)
                    resolve(result.data.data)
                })
                .catch(err => {
                    commit('siteError');
                    reject(err)
                })
        })
    },
    siteDeleteRequest: async ({
        commit
    }, queries) => {
        let url = `/api/sites/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('siteRequest')
            Vue.axios.delete(url)
                .then(result => {
                    commit('siteDeleteSuccess')
                    resolve(result)
                })
                .catch(err => {
                    commit('siteError');
                    reject(err)
                })
        })
    },
    sitesRequest: async ({
        commit,
        dispatch
    }, queries) => {
        // let url = '/api/sites?'
        // if (queries.per_page) {
        //     url += `page=${queries.page}
        //             &per_page=${queries.per_page}`;
        // }
        // if (queries.sort) {
        //     url += `${queries.sort && queries.direction ? '&sort='+queries.sort+'&direction='+queries.direction : ''}`;
        // }

        // if (queries.client_id) {
        //     url += '&client_id='+queries.client_id
        // }
        // if (queries.user_id) {
        //     url += '&user_id='+queries.user_id
        // }
        // url = url.replace(/\s/g, '')

        //remove null values
        queries = Object.entries(queries).reduce((a, [k, v]) => (v ? {
            ...a,
            [k]: v
        } : a), {})
        //build query url
        let url = '/api/sites?' + Object.entries(queries).map(e => e.join('=')).join('&')

        return new Promise((resolve, reject) => {
            commit('siteRequest')
            Vue.axios.get(url)
                .then(result => {
                    if (result.data.data) {
                        for (let site of result.data.data) {
                            if (site.events) {
                                site.events = JSON.parse(site.events);
                            } else if (!site.events || !site.events.names) {
                                site.events = {
                                    names: []
                                }
                            }
                            if (site.categories) {
                                site.categories = JSON.parse(site.categories);
                            } else if (!site.categories || !site.categories.names) {
                                site.categories = {
                                    names: []
                                }
                            }
                            if (site.visualizations) {
                                for (let v of site.visualizations) {
                                    if (v.axes) {
                                        v.axes = JSON.parse(v.axes);
                                        convertAxes(v.axes);
                                    }
                                    if (v.events) {
                                        v.events = JSON.parse(v.events);
                                    }
                                }
                            }
                            if (site.relations) {
                                site.relations = JSON.parse(site.relations);
                            }
                            if (site.ftp_pwd) {
                                site.ftp_pwd = null;
                            }
                        }
                    }
                    commit('sitesSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('siteError');
                    reject(err)
                })
        })
    },
}



const mutations = {
    siteRequest: (state) => {
        state.status = 'loading';
    },
    sitesSuccess: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'sites', resp);
    },
    siteSuccess: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'site', resp);
    },
    siteDeleteSuccess: (state) => {
        state.status = 'success';
    },
    siteError: (state) => {
        state.status = 'error';
    },
}



export const getSite = (store, site_id) => {
    return store.dispatch("siteRequest", {
        id: site_id
    });
}

export function getSitePromise(store, site_id) {
    return new Promise((resolve, reject) => {
        getSite(store, site_id)
            .then((site) => {
                resolve(site.data);
            })
            .catch((err) => reject(err));
    });
}

export const getUserSite = (user, site_id) => {
    let userSite = user.sites.filter((site) => {
        return site.id == site_id;
    });
    return userSite[0];
}

export const getChildrenIds = (site) => {
    let children_site_ids = [];
    if (site.relations && site.relations.children) {
        for (let child of site.relations.children) {
            children_site_ids.push(child.id);
        }
    }
    return children_site_ids;
}

export const getRecursiveChildrenIds = (site, sites) => {
    let children_site_ids = [];
    let s = site;
    if (s.relations && s.relations.children) {
        for (let child of s.relations.children) {
            let childSite = sites.filter(site => site.id == child.id);
            if (childSite && childSite.length > 0) {
                children_site_ids.push(child.id);
                let child_of_childs = getRecursiveChildrenIds(childSite[0], sites);
                children_site_ids = children_site_ids.concat(child_of_childs);
            }
        }
    }
    return children_site_ids;
}

export const buildSaveSiteRequest = (site, isCreateNew) => {
    // let data = Object.assign({}, site);
    // // if (site.users) data.users = site.users.map((u) => u.id);
    // if (!site.users) datas.set("users", "");
    // else site.users.forEach((u) => datas.append("users[]", u.id));
    const datas = new FormData();
    datas.append("name", site.name);
    datas.append("type", site.type);
    datas.append("description", site.description);
    datas.append("address", site.address);
    datas.append("gps", site.gps);
    datas.append("lagoon_volume", site.lagoon_volume);
    datas.append(
        "logo",
        site.logo instanceof File ? site.logo : site.logo
    );
    datas.append("remove_logo", site.remove_logo);
    datas.append("client_id", site.client_id);
    datas.append("installation_date", site.installation_date);
    datas.append("ftp_host", site.ftp_host);
    datas.append("ftp_user", site.ftp_user);
    datas.append("ftp_pwd", site.ftp_pwd);
    datas.append("ftp_path", site.ftp_path);
    datas.append("calcForm", site.calcForm);
    datas.append("securityCO2_mesure", JSON.stringify(site.securityCO2_mesure));
    datas.append("is_securityCO2", (site.is_securityCO2) ? 1 : 0);
    datas.append("use_securityCO2_plan", (site.use_securityCO2_plan) ? 1 : 0);
    datas.append("integration_sensor", site.integration && site.integration_sensor ? site.integration_sensor.id : null);
    datas.append("integration", site.integration ? site.integration.id : null);
    datas.append("degustations", site.degustations);
    datas.append("date_formats", JSON.stringify(site.date_formats));

    if (!site.users) datas.set("users", "");
    else site.users.forEach((u) => datas.append("users[]", u.id));


    if (site.relations) {
        let saved_relations = {};
        if (site.relations.children) {
            saved_relations.children = [];
            for (let child of site.relations.children) {
                saved_relations.children.push({
                    name: child.name,
                    id: child.id
                });
            }
        }
        if (site.relations.parents) {
            saved_relations.parents = [];
            for (let parent of site.relations.parents) {
                saved_relations.parents.push({
                    name: parent.name,
                    id: parent.id
                });
            }
        }
        datas.append("relations", JSON.stringify(saved_relations));
    }
    if (!site.sensors || site.sensors.length == 0)
        datas.set("sensors[]", []);
    else
        Object.keys(site.sensors).forEach((i) => {
            datas.append(
                "sensors[" + i + "][id]",
                site.sensors[i].id ? site.sensors[i].id : ""
            );
            datas.append(
                "sensors[" + i + "][name]",
                site.sensors[i].name ? site.sensors[i].name : ""
            );
            //datas.append('sensors['+i+'][active]', site.sensors[i].active ? 1 : 0)
            //datas.append('sensors['+i+'][graph_type]', site.sensors[i].graph_type ? site.sensors[i].graph_type : '')
            datas.append(
                "sensors[" + i + "][min]",
                site.sensors[i].min ? site.sensors[i].min : ""
            );
            datas.append(
                "sensors[" + i + "][max]",
                site.sensors[i].max ? site.sensors[i].max : ""
            );
            datas.append(
                "sensors[" + i + "][csv_column]",
                site.sensors[i].csv_column ?
                site.sensors[i].csv_column :
                ""
            );
            datas.append(
                "sensors[" + i + "][calcForm]",
                site.sensors[i].calcForm ?
                    site.sensors[i].calcForm :
                    ""
            );
            datas.append(
                "sensors[" + i + "][unit_of_measure]",
                site.sensors[i].unit_of_measure ?
                site.sensors[i].unit_of_measure :
                ""
            );
        });

    if (!site.visualizations || site.visualizations.length == 0)
        datas.set("visualizations[]", []);
    else
        Object.keys(site.visualizations).forEach((i) => {
            datas.append(
                "visualizations[" + i + "][id]",
                site.visualizations[i].id ? site.visualizations[i].id : ""
            );
            datas.append(
                "visualizations[" + i + "][name]",
                site.visualizations[i].name ? site.visualizations[i].name : ""
            );
            //datas.append('visualizations['+i+'][active]', site.visualizations[i].active ? 1 : 0)
            //datas.append('visualizations['+i+'][graph_type]', site.visualizations[i].graph_type ? site.visualizations[i].graph_type : '')
            datas.append(
                "visualizations[" + i + "][events]",
                site.visualizations[i].events ? JSON.stringify(site.visualizations[i].events) : ""
            );
            datas.append(
                "visualizations[" + i + "][axes]",
                site.visualizations[i].axes ? JSON.stringify(site.visualizations[i].axes) : ""
            );
            datas.append(
                "visualizations[" + i + "][component]",
                site.visualizations[i].component ?
                site.visualizations[i].component :
                ""
            );
        });

        let isAllowYearCompare = site.is_allow_year_compare;
        if (isAllowYearCompare != 0 && isAllowYearCompare != 1)
            isAllowYearCompare = 0;
        datas.append("is_allow_year_compare", isAllowYearCompare);
    
        if (isAllowYearCompare) {
            let isAllowNearestYears = site.is_allow_nearest_years;
            if (isAllowNearestYears != 0 && isAllowNearestYears != 1)
                isAllowNearestYears = 0;
            datas.append("is_allow_nearest_years", isAllowNearestYears);
            if (isAllowNearestYears) {
                datas.append("precipitations", site.precipitations);
                datas.append("tmoy", site.tmoy);
                datas.append('tmax', site.tmax);
            }
        }
    
        let isAllowInputValue = site.is_allow_input_value;
        if (isAllowInputValue != 0 && isAllowInputValue != 1)
            isAllowInputValue = 0;
    
        datas.append("is_allow_input_value", isAllowInputValue);

    datas.append("events", JSON.stringify(site.events));
    datas.append("categories", JSON.stringify(site.categories));

    // clean null values
    for (var d of datas.entries()) {
        // console.log(d[0]+ ', ' + d[1]+' ('+ (typeof d[1]) +')');
        if (d[1] == "null" || d[1] == "undefined") datas.set(d[0], "");
    }

    let request = "siteCreateRequest";
    let queries = datas;

    if (!isCreateNew) {
        datas.append("_method", "put");
        request = "siteEditRequest";
        queries = {
            id: site.id,
            datas
        };
    }
    return {
        request,
        queries
    };

}

export default {
    state,
    getters,
    actions,
    mutations,
}
