<template>
  <v-form v-on:submit.prevent="submit" ref="form">
    <v-card>
      <v-card-title>
        <span class="headline">Saisir une mesure</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-menu
              ref="datePicker"
              v-model="datePicker"
              transition="scale-transition"
              :close-on-content-click="false"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="inputData.date"
                  label="Date"
                  prepend-icon="event"
                  readonly
                  clearable
                  :rules="[(v) => !!v || 'Champ requis']"
                  required
                  v-on="on"
                ></v-text-field>
              </template>

              <v-date-picker
                locale="fr-FR"
                v-model="inputData.date"
                @input="datePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="timePicker"
              v-model="timePicker"
              transition="scale-transition"
              :close-on-content-click="false"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="inputData.time"
                  label="Heure"
                  prepend-icon="schedule"
                  readonly
                  clearable
                  :rules="[(v) => !!v || 'Champ requis']"
                  v-on="on"
                ></v-text-field>
              </template>

              <v-time-picker
                locale="fr-FR"
                v-model="inputData.time"
                format="24hr"
                @click:minute="timePicker = false"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
        <template v-if="isCuve()">
          <v-list-item>
            <v-list-item-title> Source : </v-list-item-title>
            <v-select
              :items="clientParcelles"
              label="Parcelle ou Cuve"
              item-text="name"
              item-value="id"
              return-object
              clearable
              v-model="cuveParcelleSource"
            ></v-select>
          </v-list-item>
        </template>
        <div v-for="(mesure, index) in mesures" :key="mesure.id">
          <v-list-item>
            {{ displayMesure(mesure) }}
            <v-btn icon class="mx-2" @click="deleteMesure(index)">
              <v-icon>close</v-icon>
            </v-btn>
          </v-list-item>
        </div>
        <v-dialog v-model="isAddingMesure" max-width="720px">
          <v-card>
            <v-form v-on:submit.prevent="handleSavedSensorForm" ref="form">
              <v-card-title>
                <span class="headline">Ajouter une mesure</span>
              </v-card-title>
              <v-list-item class="flex-wrap">
                <v-autocomplete
                  v-if="site.categories && site.categories.names.length > 0"
                  :items="site.categories.names"
                  v-model="mesureAdded.category"
                  label="Catégorie"
                  :rules="[(v) => !!v || 'Champ requis']"
                ></v-autocomplete>
                <v-autocomplete
                  :items="site.sensors"
                  item-text="name"
                  item-value="csv_column"
                  v-model="mesureAdded.csv_column"
                  label="Type de valeur"
                  :rules="[(v) => !!v || 'Champ requis']"
                ></v-autocomplete>
                <v-text-field
                  label="Valeur mesurée"
                  v-model="mesureAdded.value"
                  :rules="[(v) => !!v || 'Champ requis']"
                ></v-text-field>
                <v-text-field
                  label="Commentaire"
                  v-model="mesureAdded.commentary"
                  :rules="[(v) => !!v || 'Champ requis']"
                ></v-text-field>
              </v-list-item>
              <v-card-actions>
                <v-btn depressed @click="cancelAddMesure">Annuler</v-btn>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="handleAddMesure">Ajouter</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-btn small depressed @click.stop="isAddingMesure = true">
          <v-icon left>add</v-icon> ajouter une mesure
        </v-btn>
        <v-autocomplete
          v-if="site.events && site.events.names"
          v-model="events"
          :items="site.events.names"
          item-text="complete_name"
          item-value="id"
          label="Evenements"
          multiple
          chips
          deletable-chips
          return-object
          hint="Vous pouvez ajouter des événements"
        ></v-autocomplete>

        <!-- <v-text-field
          label="Unité de mesure"
          v-model="inputData.unit_of_measure"
          :rules="[]"
        ></v-text-field> -->

        <v-file-input
          label="Image, Photographie ou Fichier joint"
          v-model="inputData.file"
          small-chips
          show-size
          :disabled="loading"
          :loading="loading"
        ></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-btn depressed @click="cancel">Annuler</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="loading"
          :loading="loading"
          depressed
          color="primary"
          @click="submit"
          >Enregistrer</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
export default {
  props: {
    value: Object,
    site: Object,
  },
  data() {
    let user = this.$store.state.profile.user;
    let items = this.site.sensors;
    console.log(items);
    let clientParcelles = user.sites.filter((s) => {
      return (s.type == "Parcelle" || s.type == "Cuve") && s.client_id == this.site.client_id;
    });

    return {
      elastic_winery_mesures_index: "winery_mesures_" + this.site.client_id,
      elastic_winery_events_index: "winery_events_" + this.site.client_id,
      datePicker: false,
      timePicker: false,
      loading: false,
      mesures: [],
      isAddingMesure: false,
      mesureAdded: {},
      // testia: [1, 2],
      events: [],
      clientParcelles,
      cuveParcelleSource: {},
      geolocation: {},
    };
  },
  computed: {
    inputData() {
      return this.value
        ? this.value
        : {
            value: null,
            type: null,
            Datetime: null,
            date: new Date().toISOString().substr(0, 10),
            time:
              new Date().getHours() +
              ":" +
              (new Date().getMinutes() < 10 ? "0" : "") +
              new Date().getMinutes(),
            file: null,
          };
    },
    // testi() {
    //   let items = this.site.sensors;
    //   let testia = items.sort((a, b) => a.csv_column.localeCompare(b.csv_column))
    //   return testia
    // },
    user() {
      return this.$store.getters.getProfile;
    },
  },
  mounted() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.geolocation.lat = position.coords.latitude;
        this.geolocation.lon = position.coords.longitude;
      });
    } else {
      console.log("Geolocation is not supported.");
    }
    //
  },
  methods: {
    isCuve() {
      // console.log(this.site.type);
      return this.site.type == "Cuve";
    },
    handleAddMesure() {
      let sensortype = this.getSensor(this.mesureAdded.csv_column);
      this.mesureAdded.name = sensortype.name;
      this.mesureAdded.unit_of_measure = sensortype.unit_of_measure;
      let newSensor = Object.assign({}, this.mesureAdded);

      this.mesures.push(newSensor);

      this.isAddingMesure = false;
      this.$refs.form.reset();
    },
    deleteMesure: function (mesure) {
      this.mesures.splice(this.mesures.indexOf(mesure), 1);
    },
    cancelAddMesure() {
      this.$refs.form.reset();
      this.isAddingMesure = false;
    },
    async submit() {
      // if (this.$refs.form.validate()) {
      this.loading = true;
      await this.formatData();
      this.save();
      // }
    },
    getSensor(sensor_columncsv) {
      let currentSensor;
      for (let sensor of this.site.sensors) {
        if (sensor_columncsv == sensor.csv_column) {
          currentSensor = sensor;
          break;
        }
      }
      return currentSensor;
    },
    async formatData() {
      //   console.log("test", this.inputData);
      this.inputData.mesures = [];
      for (let mesure of this.mesures) {
        let currentSensor = this.getSensor(mesure.csv_column);
        let data = {
          name: currentSensor.name,
          csv_column: mesure.csv_column,
          value: parseFloat(mesure.value),
          unit_of_measure: currentSensor.unit_of_measure,
        };
        if (mesure.category) {
          data.category = mesure.category;
        }
        if (mesure.commentary) {
          data.commentary = mesure.commentary;
        }
        this.inputData.mesures.push(data);
      }
      this.inputData.events = [...this.events];
      this.inputData.createdBy = {
        id: this.user.id,
        name: this.user.complete_name,
      };
      if (this.geolocation && this.geolocation.lat) {
        this.inputData.location = this.geolocation.lat + ", " + this.geolocation.lon;
      }
      this.inputData.createdAt = this.$moment().valueOf();
      if (this.isCuve() && this.cuveParcelleSource)
        this.inputData.parcelleSource = {
          name: this.cuveParcelleSource.name,
          id: this.cuveParcelleSource.id,
        };
      let time = this.inputData.time;
      let timesplit = time.split(":");
      if (timesplit.length == 2 && timesplit[0].length == 1) {
        time = "0" + timesplit[0] + ":" + timesplit[1];
      }
      this.inputData.Datetime = this.$moment(
        this.inputData.date + " " + time + ":00"
      ).unix();
      // "T00:00:00.000Z"
      this.inputData["@timestamp"] = this.inputData.date + "T" + time + ":00.000Z";

      if (this.inputData.file) {
        this.inputData.hasFile = true;
        this.inputData.fileName = this.inputData.file.name;
        this.inputData.fileType = this.inputData.file.type;
        this.inputData.fileContent = await this.readFileAsync(this.inputData.file);
      } else {
        this.inputData.hasFile = false;
      }

      this.inputData.handmade = true;

      //   console.log("test", this.inputData);
    },
    readFileAsync(file) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(this.inputData.file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = () => {
          console.log("err", reader.error);
          this.$store.commit("setSnack", { type: "error", msg: reader.error });
          this.loading = false;
        };
      });
    },
    save() {
      let body = {};
      if (this.inputData.mesures)
        for (let mesure of this.inputData.mesures) {
          this.inputData[mesure.csv_column] = mesure.value;
        }

      let status = this.$store
        .dispatch("elasticCreateRequest", {
          index: this.elastic_winery_mesures_index,
          site: this.site.id,
          body: { ...this.inputData, Site: this.site.id },
        })
        .then((result) => {
          console.log("Document created.", result.data);
          this.$refs.form.reset();
          this.$store.commit("setSnack", {
            type: "success",
            msg: "Valeur correctement enregistrée",
          });
          this.$emit("saved");
          this.loading = false;
        })
        .catch((err) => {
          console.log("err", err);
          this.$store.commit("setSnack", { type: "error", msg: err });
          this.loading = false;
        });
    },
    cancel() {
      this.$refs.form.reset();
      this.$emit("cancel");
    },
    displayMesure(mesure) {
      let display = mesure.name + " " + mesure.value;
      if (mesure.unit_of_measure) {
        display += " " + mesure.unit_of_measure;
      }
      if (mesure.category) {
        display = mesure.category + " : " + display;
      }
      if (mesure.commentary) {
        display += " " + mesure.commentary;
      }
      return display;
    },
  },
};
</script>
