import Vue from 'vue'

const state = {
    status: '',
    degustations: [],
}

const getters = {}

const actions = {
    degustCreateRequest: async ({commit}, queries) => {
        let url = `/api/degustations`;

        return new Promise((resolve, reject) => {
            commit('degustRequest')
            Vue.axios.post(url, queries)
                .then(result => {
                    commit('degustSuccess', result.data.data)
                    resolve(result.data.data)
                })
                .catch(err => {
                    commit('degustError');
                    reject(err)
                })
        })
    },
    degustDeleteRequest: async ({commit}, queries) => {
        let url = `/api/degustations/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('degustRequest')
            Vue.axios.delete(url)
                .then(result => {
                    commit('degustDeleteSuccess')
                    resolve(result)
                })
                .catch(err => {
                    commit('degustError');
                    reject(err)
                })
        })
    },
    degustsRequest: async ({commit, dispatch}, queries) => {
        //remove null values
        queries = Object.entries(queries).reduce((a,[k,v]) => (v ? {...a, [k]:v} : a), {})
        //build query url
        let url = '/api/degustations?'+Object.entries(queries).map(e => e.join('=')).join('&')


        return new Promise((resolve, reject) => {
            commit('degustRequest')
            Vue.axios.get(url)
                .then(result => {
                    commit('degustsSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('degustError');
                    reject(err)
                })
        })
    },
}



const mutations = {
    degustRequest: (state) => {
        state.status = 'loading';
    },
    degustsSuccess: (state, resp) => {
        state.status = 'success';
    },
    degustSuccess: (state, resp) => {
        state.status = 'success';
    },
    degustDeleteSuccess: (state) => {
        state.status = 'success';
    },
    degustError: (state) => {
        state.status = 'error';
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}