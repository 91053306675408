import Vue from 'vue'

const state = {
    status: '',
    degustations: [],
}

const getters = {}

const actions = {
    degustLinkCreateRequest: async ({commit}, queries) => {
        let url = `/api/degustLinks`;

        return new Promise((resolve, reject) => {
            commit('degustRequest')
            Vue.axios.post(url, queries)
                .then(result => {
                    commit('degustLinkSuccess', result.data.data)
                    resolve(result.data.data)
                })
                .catch(err => {
                    commit('degustLinkError');
                    reject(err)
                })
        })
    },
    degustLinkDeleteRequest: async ({commit}, queries) => {
        let url = `/api/degustLinks/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('degustRequest')
            Vue.axios.delete(url)
                .then(result => {
                    commit('degustLinkDeleteSuccess')
                    resolve(result)
                })
                .catch(err => {
                    commit('degustLinkError');
                    reject(err)
                })
        })
    },
    degustLinksRequest: async ({commit, dispatch}, queries) => {
        //remove null values
        queries = Object.entries(queries).reduce((a,[k,v]) => (v ? {...a, [k]:v} : a), {})
        //build query url
        let url = '/api/degustLinks?'+Object.entries(queries).map(e => e.join('=')).join('&')


        return new Promise((resolve, reject) => {
            commit('degustRequest')
            Vue.axios.get(url)
                .then(result => {
                    commit('degustLinksSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('degustLinkError');
                    reject(err)
                })
        })
    },
    degustLinkRequest: async ({commit, dispatch}, queries) => {
        //remove null values
        //queries = Object.entries(queries).reduce((a,[k,v]) => (v ? {...a, [k]:v} : a), {})
        //build query url
        let url = '/api/degustLinks/'+queries.id;
        //let url = '/api/ttr';

        return new Promise((resolve, reject) => {
            commit('degustRequest')
            Vue.axios.get(url)
                .then(result => {
                    commit('degustLinkSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('degustLinkError');
                    reject(err)
                })
        })
    },
}



const mutations = {
    degustLinkRequest: (state) => {
        state.status = 'loading';
    },
    degustLinksSuccess: (state, resp) => {
        state.status = 'success';
    },
    degustLinkSuccess: (state, resp) => {
        state.status = 'success';
    },
    degustLinkDeleteSuccess: (state) => {
        state.status = 'success';
    },
    degustLinkError: (state) => {
        state.status = 'error';
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}