function isIterable(obj) {
    // checks for null and undefined
    if (obj == null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
}

export const convertAxes = (axes) => {
    if (isIterable(axes)) {
        for (let axe of axes) {
            if (axe.type && axe.type == "LineChart") {
                axe.type = "Lignes";
            } else if (axe.type && axe.type == "BarChart") {
                axe.type = "Bars";
            }
            if (axe.aggregationsType && axe.aggregationsType == "avg") {
                axe.aggregationsType = "Moyenne";
            } else if (axe.aggregationsType && axe.aggregationsType == "sum") {
                axe.aggregationsType = "Cumul";
            }
        }
    }
}