<template>
  <div class="senForm">
    <v-form  v-on:submit.prevent="onSubmit" ref="form" class="mb-5">
      <v-autocomplete
          v-model="value.integration"
          :items="integrations"
          item-text="name"
          item-value="id"
          label="Type d'intégration"
          v-on:change="setConnectionSettings"
          return-object
          hint="Veuillez séléctionner l'intégration à configurer"
      ></v-autocomplete>
      <v-text-field
          v-model="value.name"
          label="Nom de la configuration"
      />
      <div v-for="(input, index) in value.connection_settings"
           :key="`connect_settings-${index}`"
           class="input wrapper flex items-center"
      >
        <v-text-field
          v-model="value.params[`${index}`]"
          :label="index"
        />
      </div>

      <div class="px-5 py-3 absolute--position left right bottom white text-right">
        <v-btn @click.stop="cancel" text color="secondary">Annuler</v-btn>
        <v-btn color="primary" type="submit" :disabled="loading" :loading="loading">Sauvegarder</v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>

import Vue from 'vue';

 export default {
   data() {
     return {
       loading: false,
       integrations: [],
     }
   },
   props: {
     value: Object,
     action: {
       type: String,
       default: 'update'
     },
   },
   mounted() {
     this.fetchIntegrations();
   },
   methods: {
     cancel () {
       this.$refs.form.reset()
       this.$emit('canceledForm');
     },
     onSubmit () {
       //console.log(this.value.params);
       if (this.$refs.form.validate()) {
         this.loading = true;
         const datas = new FormData();
         datas.append('name', this.value.name);
         datas.append('integration_id', this.value.integration.id)
         datas.append('params', JSON.stringify(this.value.params));

         let request = 'intConfCreateRequest'
         let queries = datas

         if (this.action === 'update') {
           datas.append('_method', 'put')
           request = 'intConfEditRequest'
           queries = {id: this.value.id, datas}
         }

         this.$store.dispatch(request, queries)
             .then((intConf) => {
               this.$refs.form.resetValidation()
               this.loading = false;
               this.$store.commit('setSnack', {'type': 'success', 'msg': 'Configuration Sencrop enregistré !'});
               this.$emit('savedForm', intConf);
             })
             .catch((err) => {
               this.loading = false;
               if (err.response.status != 403)
                 this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
             })
        }
       },
    setConnectionSettings(){
       console.log(this.value.integration);
       this.value.connection_settings = JSON.parse(this.value.integration.connection_settings);
    },
    fetchIntegrations(){
      this.$store.dispatch('integrationsRequest', {})
          .then((result) => {
            this.integrations = result
          })
          .catch((err) => {
          })
    },
   },
   watch: {
     value() {
       this.$refs.form.resetValidation()
     },
   }
 };
</script>