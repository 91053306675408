import Vue from 'vue';

export default {
    install (Vue) {

        Vue.prototype.$resetDialogScroll = function() {
            // console.log(Date.now(), 'resetDialogScroll', document.querySelectorAll('.v-dialog .v-card__text').length)
            for (let resetable of document.querySelectorAll('.v-dialog .v-card__text')) {
                this.$vuetify.goTo(-1, {container: resetable})
            }
        }
    }
}