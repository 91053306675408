import Vue from 'vue'

const state = {
    status: '',
    clients: [],
}

const getters = {}

const actions = {
    // clientRequest: async ({commit}, queries) => {
    //     let url = `/api/clients/${queries.id}`;

    //     return new Promise((resolve, reject) => {
    //         commit('clientRequest')
    //         Vue.axios.get(url)
    //             .then(result => {
    //                 commit('clientSuccess', result.data)
    //                 resolve(result.data)
    //             })
    //             .catch(err => {
    //                 commit('clientError');
    //                 reject(err)
    //             })
    //     })
    // },
    clientEditRequest: async ({commit}, queries) => {
        let url = `/api/clients/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('clientRequest')
            Vue.axios.post(url, queries.datas)
                .then(result => {
                    commit('clientSuccess', result.data.data)
                    resolve(result.data.data)
                })
                .catch(err => {
                    commit('clientError');
                    reject(err)
                })
        })
    },
    clientCreateRequest: async ({commit}, queries) => {
        let url = `/api/clients`;
 
        return new Promise((resolve, reject) => {
            commit('clientRequest')
            Vue.axios.post(url, queries)
                .then(result => {
                    commit('clientSuccess', result.data.data)
                    resolve(result.data.data)
                })
                .catch(err => {
                    commit('clientError');
                    reject(err)
                })
        })
    },
    clientDeleteRequest: async ({commit}, queries) => {
        let url = `/api/clients/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('clientRequest')
            Vue.axios.delete(url)
                .then(result => {
                    commit('clientDeleteSuccess')
                    resolve(result)
                })
                .catch(err => {
                    commit('clientError');
                    reject(err)
                })
        })
    },
    clientsRequest: async ({commit, dispatch}, queries) => {
        //remove null values
        queries = Object.entries(queries).reduce((a,[k,v]) => (v ? {...a, [k]:v} : a), {})
        //build query url
        let url = '/api/clients?'+Object.entries(queries).map(e => e.join('=')).join('&')


        return new Promise((resolve, reject) => {
            commit('clientRequest')
            Vue.axios.get(url)
                .then(result => {
                    commit('clientsSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('clientError');
                    reject(err)
                })
        })
    },
    sencropSensorsRequest: async ({commit, dispatch}, queries) => {
        //build query url
        let url = '/api/sencropSensors'


        return new Promise((resolve, reject) => {
            commit('clientRequest')
            Vue.axios.post(url, queries.datas)
                .then(result => {
                    commit('clientsSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('clientError');
                    reject(err)
                })
        })
    },
}

const mutations = {
    clientRequest: (state) => {
        state.status = 'loading';
    },
    clientsSuccess: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'clients', resp);
    },
    clientSuccess: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'client', resp);
    },
    clientDeleteSuccess: (state) => {
        state.status = 'success';
    },
    sencropSensorsSuccess: (state) => {
        state.status = 'success';
    },
    clientError: (state) => {
        state.status = 'error';
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}