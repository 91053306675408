import Vue from 'vue';

export default {
    install (Vue) {



        Vue.prototype.$checkHistory = function(tocheck, callback) {
            let obj = null
            if (tocheck == 'user' && !!this.$clicked_user)
                obj = this.$clicked_user

            if (tocheck == 'site' && !!this.$clicked_site)
                obj = this.$clicked_site

            if (tocheck == 'client' && !!this.$clicked_client)
                obj = this.$clicked_client

            if (tocheck == 'senConf' && !!this.$clicked_senConf)
                obj = this.$clicked_senConf

            if (obj)
                callback(obj)

        }

        Vue.prototype.$resetHistory = function() {
            this.$clicked_user = null
            this.$clicked_client = null
            this.$clicked_site = null
            this.$clicked_senConf = null
        }

        Object.defineProperties(Vue.prototype, {
            '$clicked_user': {
                get: function () {
                    // console.log('get clicked_user', this.$store.state.clicked_user)
                    return this.$store.state.clicked_user
                },
                set: function (value) {
                    // console.log('set clicked_user', value)
                    this.$store.state.clicked_user = value
                    return this
                }
            },
            '$clicked_site': {
                get: function () {
                    // console.log('get clicked_site', this.$store.state.clicked_site)
                    return this.$store.state.clicked_site
                },
                set: function (value) {
                    // console.log('set clicked_site', value)
                    this.$store.state.clicked_site = value
                    return this
                }
            },
            '$clicked_client': {
                get: function () {
                    // console.log('get clicked_client', this.$store.state.clicked_client)
                    return this.$store.state.clicked_client
                },
                set: function (value) {
                    // console.log('set clicked_client', value)
                    this.$store.state.clicked_client = value
                    return this
                }
            },
            '$clicked_senConf': {
                get: function () {
                    // console.log('get clicked_client', this.$store.state.clicked_client)
                    return this.$store.state.clicked_senConf
                },
                set: function (value) {
                    // console.log('set clicked_client', value)
                    this.$store.state.clicked_senConf = value
                    return this
                }
            },
        })

    }
};
