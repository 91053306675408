<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="headline"
              >{{ value.lastname }} {{ value.firstname }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="12" :md="6">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Role</v-list-item-title>
            <v-list-item-subtitle>{{ value.role_name }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Email</v-list-item-title>
            <v-list-item-subtitle>{{ value.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Téléphone</v-list-item-title>
            <v-list-item-subtitle>{{ value.phone }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="12" :md="6">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Autorise les notif. mail</v-list-item-title>
            <v-list-item-subtitle>{{
              value.optin_mail ? "Oui" : "Non"
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Autorise les notif. sms</v-list-item-title>
            <v-list-item-subtitle>{{
              value.optin_sms ? "Oui" : "Non"
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  components: {
    //
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      //
    };
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>