import Vue from 'vue'
import './plugins/axios'
import vuetify from './plugins/vuetify'
import './plugins/moment'
import acl from './plugins/acl'
import App from './App.vue'
import router from './router'
import store from './vuex/store'
import './plugins/chart'

import VueMobileDetection from 'vue-mobile-detection'
Vue.use(VueMobileDetection)

import breadcrumb from './plugins/breadcrumb'
Vue.use(breadcrumb)
import helper from './plugins/helper'
Vue.use(helper)

import Navigation from './components/Navigation.vue'
Vue.component('Navigation', Navigation)
import Snackbar from './components/Snackbar.vue'
Vue.component('Snackbar', Snackbar)

Vue.config.productionTip = false

Vue.config.errorHandler = function (err, vm, info) {
    // Log error to the console for debugging
    console.error('Error caught: ', err);
    console.log('Component: ', vm);
    console.log('Info: ', info);

    // Send error details to API
    Vue.axios.post('/api/logs', {
        type: 'error',
        message: err.message,
        stack: err.stack,
        component: vm.$options.name || 'Anonymous Component',
        info: info,
        url: window.location.href,
    }).then(response => {
        //console.log('Error sent to the API:', response.data);
    }).catch(error => {
        //console.error('Error sending error to API:', error);
    });
};

Vue.config.warnHandler = function (msg, vm, trace) {
    // Log warning to the console for debugging
    //console.warn('Warning caught: ', msg);
    //console.log('Component: ', vm);
    //console.log('Trace: ', trace);

    // Send warning details to API
    Vue.axios.post('/api/logs', {
        type: 'warning',
        message: msg,
        trace: trace,
        component: vm.$options.name || 'Anonymous Component',
        url: window.location.href,
    }).then(response => {
        //console.log('Warning sent to the API:', response.data);
    }).catch(error => {
        //console.error('Error sending warning to API:', error);
    });
};

new Vue({
    vuetify,
    router,
    acl,
    store,
    render: h => h(App)
}).$mount('#app')
