<template>
  <div>
      <!-- <Navigation /> -->
      <v-main>
        <v-container fluid fill-height >
          <v-layout>
              <v-col cols="12">
                  <v-card
                  class="mx-auto my-6 rounded-xl"
                  max-width="360"
                  outlined
                  rounded>
                    <!-- <v-text-field v-model="degustCode" label="Entrer le code de dégustation partagée" class="mx-2"/>
                    <v-btn v-on:click="loadDegustCode()">Charger le code de dégustation</v-btn> -->
                    <v-form v-on:submit.prevent="doNewUserAndConnect()" class="my-2" v-if="degustFound != null">
                      <v-text-field v-model="degustatorFirstName" label="Saisir votre nom" filled outlined persistent-hint chips clearable></v-text-field>
                      <v-text-field v-model="degustatorLastName" label="Saisir votre prénom" filled outlined persistent-hint chips clearable></v-text-field>
                      <v-btn block color="primary" type="submit" :disabled="loading" :loading="loading">Commencer la dégustation</v-btn>
                    </v-form>
                  </v-card>
              </v-col>
          </v-layout>
        </v-container>
      </v-main>
  </div>
</template>
<script>
//import Navigation from "../components/Navigation.vue";

export default {
    components: {
        //Navigation,
    },
    data() {
        return {
            degustCode: "",
            degustFound: null,
            degustatorFirstName: "",
            degustatorLastName: "",
            loading: false,
        }
    },
    mounted() {
        this.degustCode = this.$route.params.degustCode;
        this.loadDegustCode();
    },
    methods: {
        async loadDegustCode(){
            let queries = {
                "id": this.degustCode
            }
            await this.$store
                .dispatch("degustLinkRequest", queries)
                .then((result) => {
                    this.$store.commit("setSnack", {
                        type: "success",
                        msg: "Code de dégustation trouvé",
                    });
                    this.degustFound = result;
                })
                .catch((err) => {
                    //console.log("err", err);
                    this.$store.commit("setSnack", { type: "error", msg: "Code de dégustation invalide" });
                });
        },
        async doNewUserAndConnect(){
            let queries = {
                firstname: this.degustatorFirstName,
                lastname: this.degustatorLastName,
                email: this.degustatorFirstName + "." + this.degustatorLastName + "@wdegust.com",
                password: 'UnMDPSecretDe2023/*/',
                password_confirmation: 'UnMDPSecretDe2023/*/',
                role: 'user',
                sites: [this.degustFound.data.site_id],
                clients: [this.degustFound.data.client_id],
            }
            /*if (this.degustFound.client)
                queries.clients = queries.clients.map((c) => c.id);
            if (this.degustFound.site) {
                let tempSites = this.queries.sites.filter((site) => {
                    return this.queries.clients.map((c) => c.id).includes(site.client_id);
                });
                queries.sites = tempSites.map((s) => s.id);
            }*/
            let data = Object.assign({}, queries);
            this.loading = true;
            await this.$store
                .dispatch("userCreateRequest", data)
                .then(async () => {
                    const { email, password } = queries;
                    await this.$store.dispatch('authRequest', { email, password })
                        .then(() => {
                            this.loading = false
                            this.loadDegustMaker();
                        })
                        .catch((err) => {
                            this.loading = false;
                        })
                })
                .catch((err) => {
                    //console.log("err", err);
                    this.$store.commit("setSnack", { type: "error", msg: "Nom ou prénom invalide (contient possiblement des caractères spéciaux)" });
                });
        },
        loadDegustMaker(){
            this.$router.push({name: 'Degustations', params: {
                degustParams: {
                    degustName: this.degustFound.data.degustName,
                    client: this.degustFound.data.client,
                    site: this.degustFound.data.site,
                    visualization: this.degustFound.data.visualization,
                    data: this.degustFound.data.date
                }
            }});
        },
    }
}
</script>