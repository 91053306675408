export function getDates(startDate, stopDate, interval) {
    var dateArray = new Array();
    var currentDate = new Date(startDate);
    if (!currentDate) {
        currentDate = new Date(0);
    }
    while (currentDate.getTime() <= stopDate.getTime()) {
        dateArray.push(new Date(currentDate));
        if (!interval || interval === null || interval === "day")
            currentDate = addDays(currentDate, 1);
        else if (interval === "week")
            currentDate = addDays(currentDate, 7);
        else if (interval === "month")
            currentDate = addMonths(currentDate, 1);
        else if (interval === "year")
            currentDate = addYears(currentDate, 1);
        else if (interval === "hour")
            currentDate.setHours(currentDate.getHours() + 1);
        else if (interval === "minute")
            currentDate.setMinutes(currentDate.getMinutes() + 1);

    }
    return dateArray;
}

export function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function addMonths(date, months) {
    var result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
}

function addYears(date, years) {
    var result = new Date(date);
    result.setFullYear(result.getFullYear() + years);
    return result;
}

export function generateArrayOfYears() {
    let max = new Date().getFullYear() + 1
    let min = max - 80
    let years = []

    for (var i = max; i >= min; i--) {
        years.push("" + i)
    }
    return years
}


export const dateFormatFrenchPretty = "dd-MM-yyyy";
export const dateFormatFrenchPrettyWithHours = "dd-MM-yyyy HH";
export const dateFormatFrenchPrettyWithMinutes = "dd-MM-yyyy HH:mm";

export const dateFormatElasticTimestamp = "YYYY-MM-DDTHH:mm:ss.xxxZ";

export const dateFormatDatePicker = "YYYY-MM-DD";
export const dateFormatFrench = "dd/MM/yyyy";
export const dateFormatEnglish = "yyyy/MM/dd";
export const dayFormatFrench = "dd-MM";

export const getDateFormat = (datestr) => {
    if (typeof datestr != "string") {
        return undefined;
    }
    let splitslash = datestr.split("/");
    let splitminus = datestr.split("-");
    if (splitslash.length > splitminus.length) {
        if (splitslash[0].length == 4) {

            return dateFormatEnglish;
        } else {
            return dateFormatFrench;
        }
    } else {
        if (splitminus[0].length == 4) {
            if (splitminus.length == 3 && splitminus[2].length == 16) {
                return dateFormatElasticTimestamp;
            }
            return dateFormatDatePicker;
        } else {
            return dateFormatFrenchPretty;
        }
    }

}

export const toDate = (datestr, format) => {
    if (typeof datestr != "string") {
        return datestr;
    }
    if (!format) {
        format = getDateFormat(datestr);
    }
    let valueDate = new Date();
    valueDate.setHours(0);
    valueDate.setMinutes(0);
    valueDate.setSeconds(0);
    valueDate.setMilliseconds(0);
    if (format == dateFormatElasticTimestamp) {
        let splitT = datestr.split("T");
        if (splitT.length == 2) {
            datestr = splitT[0];
            let timestr = splitT[1];
            let timesplit = timestr.split(":");
            if (timesplit.length == 3) {
                valueDate.setHours(timesplit[0]);
                valueDate.setMinutes(timesplit[1]);
                valueDate.setSeconds(timesplit[2].substring(0, 2));
                // valueDate.setMilliseconds(0);
            }
        }
    }
    let valuesplit
    if (format === dateFormatFrench || format === dateFormatEnglish) {
        valuesplit = datestr.split("/");
    } else if (format === dateFormatFrenchPretty || format === dateFormatDatePicker || format === dateFormatElasticTimestamp) {
        valuesplit = datestr.split("-");
    }
    if (valuesplit && valuesplit.length !== 3) {
        return undefined;
    }
    if (format === dateFormatFrench || format == dateFormatFrenchPretty) {
        valueDate.setFullYear(valuesplit[2]);
        valueDate.setMonth(valuesplit[1] - 1);
        valueDate.setDate(valuesplit[0]);
    } else {
        valueDate.setFullYear(valuesplit[0]);
        valueDate.setMonth(valuesplit[1] - 1);
        let day = valuesplit[2];
        if (day.length > 2) {
            day = day.substring(0, 2);
        }
        valueDate.setDate(day);
    }
    return valueDate
}

export const dayToDate = (daystr, year) => {
    let valueDate = new Date();
    let valuesplit = daystr.split("-");
    if (valuesplit && valuesplit.length !== 2) {
        return undefined;
    }
    valueDate.setFullYear(year);
    valueDate.setMonth(valuesplit[1] - 1);
    valueDate.setDate(valuesplit[0]);
    return valueDate;
}

function pad(s) { return (s < 10) ? '0' + s : s; }
/*
 Date to date str DD-MM-YYYY
*/
export function convertDate(d) {
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-');
}

/*
 Date to date str DD-MM-YYYY HH:MM
*/
export function convertDateToStringWithMinutes(d) {
    return pad(d.getDate()) + "-" + pad(d.getMonth() + 1) + "-" + d.getFullYear() + " " + pad(d.getHours()) + ":" + pad(d.getMinutes());
}

/*
 Date to date str YYYY-MM-DDTHH:MM:SS:000Z
*/
export function convertDateToElasticTimeStamp(d) {

    return d.getFullYear() + "-" + pad(d.getMonth() + 1) + "-" + pad(d.getDate()) + "T" + pad(d.getHours()) + ":" + pad(d.getMinutes()) + ":00.000Z";
}

/*
 Date to date str DD-MM
*/
export function convertDateToDDMMstr(date) {
    return ("0" + date.getDate()).slice(-2) + "-" + ("0" + (date.getMonth() + 1)).slice(-2)
}
/*
Date picker date str YYYY-MM-DD to DD-MM
*/
export function convertDatePickerToDDMMstr(datestr) {
    return convertDateToDDMMstr(convertDatePickerToDate(datestr));
}

/*
Date picker date str YYYY-MM-DD to date
*/
export function convertDatePickerToDate(datestr) {
    return toDate(datestr, dateFormatDatePicker);
}




// new Date();
//             startDate.setFullYear(this.yearCompareQuery.years[0]);
//             startDate.setMonth(this.yearCompareQuery.from.getMonth());
//             startDate.setDate(this.yearCompareQuery.from.getDate());
//   console.log(convertDate('Mon Nov 19 13:29:40 2012')) // => "19/11/2012"