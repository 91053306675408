import Vue from 'vue'
import Router from 'vue-router'
import store from './vuex/store'
import Login from './components/Login.vue'
import PasswordReset from './components/PasswordReset.vue'
import PasswordEdit from './components/PasswordEdit.vue'
import Profile from './views/Profile.vue'
import Home from './views/Home.vue'
import Users from './views/Users.vue'
import Clients from './views/Clients.vue'
import Sites from './views/Sites.vue'
import Dashboard from './views/Dashboard.vue'
import Unauthorized from './views/Unauthorized.vue'
import SensorTypes from './views/SensorTypes.vue'
import Integrations from './views/Integrations.vue'
import Degustation from './views/Degustation.vue'
import DegustLink from './views/DegustLink.vue'
import AdminDashboard from './views/AdminDashboard.vue'

Vue.use(Router)

const ifAuthenticated = (to, from, next) => {
    // console.log('ifAuthenticated', store.getters.isAuthenticated)
    if (store.getters.isAuthenticated) {
        next()
        return
    }
    next('/login')
}

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next()
        return
    }
    next('/home')
}

export default new Router({
    routes: [
        {
            path: '/unauthorized',
            name: 'Unauthorized',
            component: Unauthorized,
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/',
            redirect: 'login',
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/admin',
            name: 'Admin',
            component: AdminDashboard,
            meta: {
                rule: 'isAdmin'
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            beforeEnter: ifNotAuthenticated,
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/profile',
            name: 'Profile',
            component: Profile,
            meta: {
                rule: 'isUser'
            }
        },
        {
            path: '/home',
            name: 'Home',
            component: Home,
            meta: {
                rule: 'isUser'
            }
        },
        {
            path: '/password/reset',
            name: 'password-reset',
            component: PasswordReset,
            beforeEnter: ifNotAuthenticated,
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/password/reset/:token',
            name: 'password-edit',
            component: PasswordEdit,
            beforeEnter: ifNotAuthenticated,
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/users',
            name: 'Users',
            component: Users,
            meta: {
                rule: 'isAdmin'
            }
        },
        {
            path: '/clients',
            name: 'Clients',
            component: Clients,
            meta: {
                rule: 'isAdmin'
            }
        },
        {
            path: '/sites',
            name: 'Sites',
            component: Sites,
            meta: {
                rule: 'isAdmin'
            }
        },
        {
            path: '/dashboard/:site_id',
            name: 'Dashboard',
            hash: '#laberlu',
            component: Dashboard,
            props: true,
            meta: {
                rule: 'isUser'
            }
        },
        {
            path: '/sensor-types',
            name: 'SensorTypes',
            component: SensorTypes,
            meta: {
                rule: 'isAdmin'
            }
        },
        {
            path: '/integrations',
            name: 'Integrations',
            component: Integrations,
            meta: {
                rule: 'isAdmin'
            }
        },
                {
            path: '/degustations',
            name: 'Degustations',
            component: Degustation,
            props: true,
            meta: {
                rule: 'isUser'
            }
        },
        {
            path: '/degustLink/:degustCode',
            name: 'degustLink',
            component: DegustLink,
            meta: {
                rule: ['*']
            }
        },
    ],
    scrollBehavior: function (to, from, savedPosition) {
        if (to.hash){
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({
                        selector: to.hash,
                        behavior: 'smooth',
                    })
                }, 500)
            })
        } else {
            return { x: 0, y: 0 }
        }
    },
})
