<template>
  <!-- <div class="breadcrumb-dashboard"> -->
  <v-row class="subtitle-2 align-center white--text">
    <template v-if="$clicked_client">
      <v-col cols="auto" class="cursor-pointer" @click="toClients"
        >Liste des clients</v-col
      >
      >
      <v-col cols="auto" class="cursor-pointer" @click="toClient">{{
        $clicked_client.name
      }}</v-col>
      >
      <v-col
        v-if="$clicked_site"
        cols="auto"
        class="cursor-pointer"
        @click="toClientSite"
        >{{ current }}</v-col
      >
      <v-col v-else cols="auto">{{ current }}</v-col>
      >
      <v-col cols="auto">Dashboard</v-col>
    </template>
    <template v-else-if="$clicked_site">
      <v-col cols="auto" class="cursor-pointer" @click="toSites">Liste des Sites</v-col>
      >
      <v-col cols="auto" class="cursor-pointer" @click="toSite">{{ current }}</v-col>
    </template>
    <template v-else-if="$clicked_user">
      <v-col cols="auto" class="cursor-pointer" @click="toUsers"
        >Liste des utilisateurs</v-col
      >
      >
      <v-col cols="auto" class="cursor-pointer" @click="toUser">{{
        $clicked_user.complete_name
      }}</v-col>
      >
      <v-col cols="auto">{{ current }}</v-col>
    </template>
    <template v-else>
      <v-col cols="auto" class="cursor-pointer" @click="toHome">Accueil</v-col>
      >
      <v-col cols="auto">{{ current }}</v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" v-if="$acl.check('isUser')">
        <v-btn @click="$router.go(-1)"> Retour </v-btn>
      </v-col>
    </template>
  </v-row>
  <!-- </div> -->
</template>

<script>
export default {
  props: {
    current: { type: [String], required: true },
  },
  mounted() {
    //
  },
  methods: {
    toClients() {
      this.$clicked_client = null;
      this.$clicked_site = null;
      this.$router.push({ name: "Clients" });
    },
    toClient() {
      this.$clicked_site = null;
      this.$router.push({ name: "Clients" });
    },
    toClientSite() {
      this.$router.push({ name: "Clients" });
    },
    toSites() {
      this.$clicked_site = null;
      this.$router.push({ name: "Sites" });
    },
    toSite() {
      this.$router.push({ name: "Sites" });
    },
    toUsers() {
      this.$clicked_user = null;
      this.$clicked_client = null;
      this.$clicked_site = null;
      this.$router.push({ name: "Users" });
    },
    toUser() {
      this.$router.push({ name: "Users" });
    },
    toProfile() {
      this.$router.push({ name: "Profile" });
    },
    toHome() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
