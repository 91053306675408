var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$isMobile())?_c('v-list-item',{staticClass:"clickable-rows",on:{"click":function($event){return _vm.$router.push({
      name: 'Dashboard',
      params: { site_id: _vm.value.id },
    })}}},[_c('v-row',[_c('v-col',{staticClass:"site_icon"},[(_vm.value.logo_url)?_c('v-list-item-avatar',{attrs:{"color":"white","size":"46"}},[_c('img',{attrs:{"alt":"","src":_vm.value.logo_url}})]):_vm._e()],1),_c('v-col',{staticClass:"text-center div-centered",attrs:{"data-label":"Nom","id":"site_row_name"}},[_vm._v(_vm._s(_vm.value.name))]),_c('v-responsive',{attrs:{"width":"100%"}}),_c('v-col',{staticClass:"text-right"},[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                  name: 'Dashboard',
                  params: { site_id: _vm.value.id },
                })}}},[_vm._v("dashboard")]),_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.showItem()}}},[_vm._v("info")]),_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.applySiteID()}}},[_vm._v("preview")]),(_vm.user.role == 'admin' || _vm.user.role == 'user_admin')?[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.editItem()}}},[_vm._v("edit")]),_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem()}}},[_vm._v("delete")])]:_vm._e()],2)],1)],1):_c('v-list-item',{staticClass:"clickable-rows-desktop",on:{"click":function($event){return _vm.$router.push({
            name: 'Dashboard',
            params: { site_id: _vm.value.id },
          })}}},[_c('v-row',[_c('v-col',{staticClass:"site_icon"},[(_vm.value.logo_url)?_c('v-list-item-avatar',{attrs:{"color":"white","size":"46"}},[_c('img',{attrs:{"alt":"","src":_vm.value.logo_url}})]):_vm._e()],1),_c('v-col',{staticClass:"text-center div-centered",attrs:{"data-label":"Nom","id":"site_row_name"}},[_vm._v(_vm._s(_vm.value.name))]),_c('v-col',{staticClass:"text-right"},[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                  name: 'Dashboard',
                  params: { site_id: _vm.value.id },
                })}}},[_vm._v("dashboard")]),_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.showItem()}}},[_vm._v("info")]),_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.applySiteID()}}},[_vm._v("preview")]),(_vm.user.role == 'admin' || _vm.user.role == 'user_admin')?[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.editItem()}}},[_vm._v("edit")]),_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem()}}},[_vm._v("delete")])]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }