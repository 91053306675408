<template>
  <v-list style="width: 100%;">
    <v-list-group v-if="hasChildren(node)" no-action style="width: 100%;">
      <v-list-item slot="activator">
        <SiteRow
            v-if="node"
            v-model="node"
            @editItem="editItem"
            @showItem="showItem"
            @deleteItem="deleteItem"
        />
      </v-list-item>
      <TreeNodeContents
        v-if="node && hasChildren(node)"
        :node="node"
        :datas="datas"
        @editItem="editItem"
        @showItem="showItem"
        @deleteItem="deleteItem"
      />
    </v-list-group>

    <v-list-item v-else>
      <SiteRow
          v-if="node"
          v-model="node"
          @editItem="editItem"
          @showItem="showItem"
          @deleteItem="deleteItem"
      />
      <TreeNodeContents
          v-if="node && hasChildren(node)"
          :node="node"
          :datas="datas"
          @editItem="editItem"
          @showItem="showItem"
          @deleteItem="deleteItem"
      />
    </v-list-item>
  </v-list>
</template>

<script>
import TreeNodeContents from "../components/TreeNodeContents";
import SiteRow from "../components/SiteRow";

export default {
  components: { TreeNodeContents, SiteRow },
  props: {
    node: Object,
    datas: Array,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    hasChildren(node) {
      return (
        node.relations &&
        node.relations.children &&
        node.relations.children != null &&
        node.relations.children.length > 0
      );
    },
    editItem(node) {
      this.$emit("editItem", node);
    },
    showItem(node) {
      this.$emit("showItem", node);
    },
    deleteItem(node) {
      this.$emit("deleteItem", node);
    },
  },
};
</script>

