<template>
  <v-form ref="form" class="mb-5">
    <v-card>
      <v-card-title class="primary white--text">
        <span v-if="editable">
          <template v-if="action === 'create'">Création d'un univers</template>
          <template v-if="action === 'update'">Modification d'un univers</template></span
        >
        <span v-else>Information de l'univers</span>
        <v-spacer></v-spacer>
        <v-btn small text dark @click.stop="cancel">Annuler</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row v-if="editable">
          <v-col>
            <v-list-item>
              <v-list-item-avatar color="white" size="62" v-if="value.logo_url">
                <img alt="" :src="value.logo_url" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-text-field
                  v-model="value.name"
                  label="Nom"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-title> Copier la configuration de l'univers : </v-list-item-title>
              <v-select
                :items="user.sites"
                label="Univers"
                item-text="complete_name"
                item-value="id"
                return-object
                clearable
                solo
                filled
                density="comfortable"
                v-model="duplicateSite"
                @change="changeDuplicateSite"
              ></v-select>
            </v-list-item>
            <v-list-item>
              <v-autocomplete
                v-model="value.client_id"
                :items="availableClients"
                label="Client"
                item-text="name"
                item-value="id"
                :rules="[rules.required]"
                solo
                filled
                density="comfortable"
                clearable
                @change="fetchClientSites"
              ></v-autocomplete>
            </v-list-item>
          </v-col>
        </v-row>
        <template v-else>
          <v-row>
            <v-col cols="12" :md="6">
              <v-list-item>
                <v-list-item-avatar color="white" size="62" v-if="value.logo_url">
                  <img alt="" :src="value.logo_url" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-row>
                    <v-col cols="auto">
                      <span class="headline">{{ value.name }}</span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col v-if="!editable" cols="auto">
                      <v-btn
                        color="secondary"
                        fab
                        :to="{
                          name: 'Dashboard',
                          params: { site_id: value.id },
                        }"
                        class="mr-3"
                      >
                        <v-icon>bar_chart</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </template>

        <v-tabs show-arrows>
          <v-tab> Informations générales </v-tab>
          <v-tab> Intégrations </v-tab>
          <v-tab> Relations </v-tab>
          <v-tab v-if="this.user.role === 'admin' || this.user.role === 'user_admin'">
            Options
          </v-tab>
          <v-tab> Données </v-tab>
          <v-tab> Paramètres D'horodatage </v-tab>
          <v-tab> Utilisateurs </v-tab>
          <v-tab> Sécurité CO2 </v-tab>
          <v-tab v-if="!this.value.intSensor"> Paramètres FTP </v-tab>

          <v-tab-item class="pt-2">
            <v-row>
              <v-col cols="12" :md="6">
                <template v-if="!editable && value.client">
                  <v-list-item-title>Client</v-list-item-title>
                  <v-list-item-subtitle>{{ value.client.name }}</v-list-item-subtitle>
                </template>
            <template>
                <v-text-field
                  v-if="editable"
                  v-model="value.address"
                  label="Adresse"
                ></v-text-field>
                <template v-else
                  ><v-list-item-title>Adresse</v-list-item-title>
                  <v-list-item-subtitle>{{
                    value.address
                  }}</v-list-item-subtitle></template
                >
              </template>
                <template>
                  <v-text-field
                      v-if="editable"
                      v-model="value.gps"
                      label="Coordonnées GPS"
                  ></v-text-field>
                  <template v-else>
                    <v-list-item-title>Coordonnées GPS</v-list-item-title>
                    <v-list-item-subtitle>{{ value.gps }}</v-list-item-subtitle></template
                  >
                </template>
                <template>
                  <v-textarea
                      v-if="editable"
                      v-model="value.description"
                      label="Description"
                  ></v-textarea>
                  <template v-else>
                    <v-list-item-title>Description</v-list-item-title>
                    <v-list-item-subtitle>{{ value.description }}</v-list-item-subtitle>
                  </template>
                </template>
              </v-col>
              <v-col cols="12" :md="6">
                <template v-if="editable">
                  <v-list-item class="px-0">
                    <v-list-item-avatar color="white" size="62" v-if="value.logo_url">
                      <img alt="" :src="value.logo_url" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <v-file-input
                              v-model="logo"
                              value="logo"
                              label="Logo"
                              :clearable="false"
                              :rules="[rules.image]"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                    <v-btn
                        v-if="editable && value.logo_url"
                        dark
                        small
                        depressed
                        class="red"
                        @click="deleteLogo"
                    >supprimer</v-btn
                    >
                  </v-list-item>
                </template>
                <v-menu
                    v-if="editable"
                    v-model="menu_installation_date"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        :value="formattedInstallationDate"
                        label="Date d'installation"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                        clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="value.installation_date"
                      @input="menu_installation_date = false"
                      locale="fr-FR"
                  ></v-date-picker>
                </v-menu>
                <template v-else
                >
                  <v-list-item-title>Date d'installation</v-list-item-title>
                  <v-list-item-subtitle v-if="value.installation_date">{{
                      new Date(value.installation_date) | moment("D MMMM Y")
                    }}</v-list-item-subtitle>
                </template>
              </v-col>
            </v-row>
          </v-tab-item>
          <!-- Intégrations -->
          <v-tab-item>
            <v-autocomplete
                v-model="value.integration"
                :items="availableInt"
                item-text="name"
                item-value="id"
                :label="`Intégrations`"
                return-object
                solo
                filled
                clearable
                hint="Veuillez séléctionner l'intégration associé à cet univers"
                v-on:change="recheckAvailableSensors"
            ></v-autocomplete>
            <v-autocomplete
                v-if="value.integration"
                v-model="value.integration_sensor"
                :items="availableIntSensors"
                item-text="name"
                item-value="id"
                :label="`Capteurs ${value.integration.name}`"
                return-object
                solo
                filled
                clearable
                hint="Veuillez séléctionner le capteur associé à cet univers"
                v-on:change="autoFillFTP"
            ></v-autocomplete>
          </v-tab-item>
          <!-- Relations -->
          <v-tab-item class="pt-2">
            <v-list-item>
              <v-autocomplete
                v-if="editable"
                v-model="value.type"
                :items="SITE_TYPES_VALUES"
                label="Type"
                :rules="[rules.required]"
                solo
                filled
                clearable
              ></v-autocomplete>
              <template v-else>
                <v-list-item-title>Type</v-list-item-title>
                <v-list-item-subtitle>{{ value.type }}</v-list-item-subtitle>
              </template>
            </v-list-item>
            <v-list-item>
              <v-autocomplete
                v-if="editable"
                v-model="value.relations.parents"
                :items="availableParents"
                item-text="name"
                label="Univers Parents"
                multiple
                chips
                deletable-chips
                solo
                filled
                return-object
                hint="Veuillez sélectionner les univers parents"
              ></v-autocomplete>
              <template v-else>
                <v-list-item-title>Univers Parents</v-list-item-title>
                <v-list-item-subtitle>
                  <v-chip
                    v-for="site in value.relations.parents"
                    :key="site.id"
                    class="mr-1 mb-1"
                    color="primary"
                    >{{ site.name }}</v-chip
                  ></v-list-item-subtitle
                >
              </template>
            </v-list-item>
            <v-list-item>
              <v-autocomplete
                v-if="editable"
                v-model="value.relations.children"
                :items="availableChildren"
                item-text="name"
                label="Univers Enfant"
                multiple
                chips
                deletable-chips
                solo
                filled
                return-object
                hint="Veuillez sélectionner les univers enfants"
              ></v-autocomplete>
              <template v-else>
                <v-list-item-title>Univers Enfant</v-list-item-title>
                <v-list-item-subtitle
                  ><v-chip
                    v-for="site in value.relations.children"
                    :key="site.id"
                    class="mr-1 mb-1"
                    color="primary"
                    >{{ site.name }}</v-chip
                  ></v-list-item-subtitle
                >
              </template>
            </v-list-item>
          </v-tab-item>
          <!-- Options -->
          <v-tab-item
            v-if="this.user.role === 'admin' || this.user.role === 'user_admin'"
            class="pt-2"
          >
            <v-list-item>
              <!-- <v-col cols="12" :md="6"> -->
              <v-switch
                v-model="value.is_allow_year_compare"
                label="Comparer les millésimes"
                :true-value="parseInt(1)"
                :false-value="parseInt(0)"
                flat
                inset
                :disabled="!editable"
              ></v-switch>
            </v-list-item>
            <template v-if="value.is_allow_year_compare">
              <v-list-item>
                <v-switch
                  v-model="value.is_allow_nearest_years"
                  label="Calculer la proximité des millésimes"
                  :true-value="parseInt(1)"
                  :false-value="parseInt(0)"
                  flat
                  inset
                  :disabled="!editable"
                ></v-switch>
              </v-list-item>
              <template v-if="value.is_allow_nearest_years">
                <v-select
                  label="Précipitations"
                  :items="availableMesures"
                  item-value="csv_column"
                  item-text="csv_column"
                  solo
                  filled
                  v-model="value.precipitations"
                ></v-select>
                <v-select
                  label="Température moyenne"
                  :items="availableMesures"
                  item-value="csv_column"
                  item-text="csv_column"
                  solo
                  filled
                  v-model="value.tmoy"
                ></v-select>
                <v-select
                  label="Température max"
                  :items="availableMesures"
                  item-value="csv_column"
                  item-text="csv_column"
                  solo
                  filled
                  v-model="value.tmax"
                ></v-select>
              </template>
            </template>
            <v-list-item>
              <v-switch
                v-model="value.is_allow_input_value"
                label="Saisir une information"
                :true-value="parseInt(1)"
                :false-value="parseInt(0)"
                flat
                inset
                :disabled="!editable"
              ></v-switch>
            </v-list-item>
          </v-tab-item>
          <v-tab-item class="pt-2 pl-4">
            <v-row class="mb-5">
              <v-col cols="12" background-color="red">
                <SiteCategories
                  v-bind:categories="value.categories.names"
                  v-bind:editable="editable"
                  @add="handleAddCategory"
                  @delete="handleDeleteCategory"
                />
              </v-col>
              <v-col cols="12">
                <span class="subtitle black--text font-size-20">Mesures</span>
                <SiteSensors
                  v-bind:editable="editable"
                  v-bind:sensors="value.sensors"
                  @added="handleAddedSensorForm"
                  @deleted="handleDeletedSensor"
                />
              </v-col>
              <v-col cols="12">
                <SiteEvents
                  v-bind:editable="editable"
                  v-bind:events="value.events.names"
                  @add="handleAddEvent"
                  @delete="handleDeleteEvent"
                />
              </v-col>
              <v-col cols="12">
                <template>
                  <v-text-field
                      v-if="editable"
                      v-model="value.calcForm"
                      label="Formule de calcul (Sous forme: Mesure1;+;Mesure2;/;Mesure3 ...)"
                  ></v-text-field>
                  <template v-else>
                    <v-list-item-title>Formule de calcul</v-list-item-title>
                    <v-list-item-subtitle>{{ value.calcForm }}</v-list-item-subtitle>
                  </template>
                </template>
              </v-col>
              <!-- TODO ALERTS  -->
            </v-row>
          </v-tab-item>

          <v-tab-item class="pt-2">
            <v-card>
              <v-card-title>Horodatage Configuration</v-card-title>
              <v-card-text>
                <v-text-field
                        v-model="value.date_formats.DefaultFormat"
                        label="Format d'horodatage par défaut"
                        solo
                ></v-text-field>

                <v-divider class="my-2"></v-divider>

                  <v-text-field
                          v-model="value.date_formats.FileFormat"
                          label="Format d'horodatage des fichiers"
                          solo
                  ></v-text-field>

                  <v-divider class="my-4"></v-divider>

                <v-data-table
                        :headers="[{ text: 'Depuis', value: 'From', sortable: false },{ text: 'Jusqu\'à', value: 'To', sortable: false },{ text: 'Format d\'horodatage', value: 'Format', sortable: false },{ text: 'Actions', value: 'actions', sortable: false }]"
                        :items="value.date_formats.Overrides"
                        item-value="name"
                        class="elevation-1"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Overrides</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="addOverride">+</v-btn>
                        </v-toolbar>
                    </template>

                    <template v-slot:item.From="{ item }">
                        <v-text-field v-model="item.from" placeholder="Depuis"></v-text-field>
                    </template>

                    <template v-slot:item.To="{ item }">
                        <v-text-field v-model="item.to" placeholder="Jusqu'à"></v-text-field>
                    </template>

                    <template v-slot:item.Format="{ item }">
                        <v-text-field v-model="item.Format" placeholder="Format d'horodatage"></v-text-field>
                    </template>

                    <template v-slot:item.actions="{ item, index }">
                        <v-icon medium @click="removeOverride(index)">delete</v-icon>
                    </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item class="pt-2">
            <v-autocomplete
              v-if="editable"
              v-model="value.users"
              :items="availableUsers"
              item-text="complete_name"
              item-value="id"
              label="Utilisateurs"
              multiple
              chips
              deletable-chips
              solo
              filled
              return-object
              hint="Veuillez sélectionner les utilisateurs ayant accès à cet univers"
            ></v-autocomplete>
            <template v-else>
              <v-chip
                v-for="user in value.users"
                :key="user.id"
                class="mr-1 mb-1"
                color="primary"
                >{{ user.complete_name }}</v-chip
              >
            </template>
            <v-dialog
              v-if="editable"
              v-model="createUserDialogVisible"
              max-width="1400px"
              persistent
              scrollable
            >
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click.stop="createUser" small depressed class="mb-5"
                  >Nouvel utilisateur</v-btn
                >
              </template>
              <v-card>
                <v-card-title class="primary white--text">
                  Création d'un utilisateur
                  <v-spacer></v-spacer>
                  <v-btn small text dark @click="createUserDialogVisible = false"
                    >Annuler</v-btn
                  >
                </v-card-title>
                <v-card-text>
                  <UserForm
                    ref="UserForm"
                    v-model="editedUser"
                    :showEditSites="false"
                    action="create"
                    @savedForm="handleSavedUserForm"
                    @canceledForm="handleCanceledUserForm"
                  />
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-tab-item>
          <v-tab-item class="pt-2">
            <v-row class="mb-5">
              <v-col>
                <v-switch
                label="Utilisation pour la sécurité CO2 ?"
                v-model="value.is_securityCO2"
                />
                <v-switch
                  v-if="value.is_securityCO2"
                  label="Utilisation d'un plan pour la sécurité CO2 ?"
                  v-model="value.use_securityCO2_plan"
                />
                <v-autocomplete
                v-if="value.is_securityCO2"
                label="Sélectionner les mesures concernée"
                :items="this.value.sensors"
                item-text="name"
                item-value="csv_column"
                chips
                deletable-chips
                return-object
                multiple
                solo
                filled
                v-model="value.securityCO2_mesure"
                density="comfortable"
                ></v-autocomplete>
                <thead>
                  <tr>
                    <th>Mesure</th>
                    <th>Nom D'affichage</th>
                    <th v-if="value.use_securityCO2_plan">Position (X)</th>
                    <th v-if="value.use_securityCO2_plan">Position (Y)</th>
                    <!-- <th v-if="value.use_securityCO2_plan">Map</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(sCM, idx) in value.securityCO2_mesure" :key="idx">
                    <td>
                      <v-text-field
                        v-if="value.is_securityCO2"
                        readonly
                        solo
                        filled
                        chips
                        deletable-chips
                        v-model="value.securityCO2_mesure[idx].name"
                        density="comfortable"
                        class="mx-2"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-if="value.is_securityCO2"
                        label="Nom d'affichage"
                        solo
                        filled
                        chips
                        deletable-chips
                        v-model="value.securityCO2_mesure[idx].display_name"
                        density="comfortable"
                        class="mx-2"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-if="value.use_securityCO2_plan && value.is_securityCO2"
                        label="Spécifier la position X (en pixels)"
                        solo
                        filled
                        chips
                        deletable-chips
                        v-model="value.securityCO2_mesure[idx].X"
                        density="comfortable"
                        class="mx-2"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-if="value.use_securityCO2_plan && value.is_securityCO2"
                        label="Spécifier la position Y (en pixels)"
                        solo
                        filled
                        chips
                        deletable-chips
                        v-model="value.securityCO2_mesure[idx].Y"
                        @input="reader()"
                        density="comfortable"
                        class="mx-2"
                      ></v-text-field>
                    </td>
                    <!-- <td>
                      <togglerComp>
                        <template v-slot:activator>
                          <v-btn color="primary">Open Map for Pos</v-btn>
                        </template>
                        <v-card style="position: absolute; left: 0; top: -25%; z-index: 1000; max-width: max-content;">
                          <v-card-text>
                            <img style="width: 90%;" src="@/assets/PlanLatourSecurity.jpg"/>
                          </v-card-text>
                        </v-card>
                      </togglerComp>
                    </td> -->
                  </tr>
                </tbody>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="pt-2" v-if="!this.value.intSensor">
            <v-row class="mb-5">
              <v-col>
                <FtpForm
                  ref="FtpFrom"
                  v-model="value"
                  v-if="action != 'create'"
                  :editable="editable"
                />
                <v-alert type="info" v-else>
                  Vous pourrez saisir les informations FTP une fois l'univers enregistré.
                </v-alert>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
        <v-card-actions v-if="editable">
          <v-spacer></v-spacer>
          <v-btn @click.stop="cancel" text color="secondary">Annuler</v-btn>
          <v-btn color="primary" @click.stop="save" :disabled="loading" :loading="loading"
            >Sauvegarder</v-btn
          >
        </v-card-actions>
        <v-card-actions v-if="!editable">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click.stop="cancel">Fermer</v-btn>
          <v-btn v-if="canModify()" color="primary" @click.stop="edit">Modifier</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card></v-form
  >
</template>

<script>
import UserForm from "../components/UserForm";
import FtpForm from "../components/FtpForm";
import SiteSensors from "../components/SiteSensors";
import SiteCategories from "../components/SiteCategories";
import SiteEvents from "../components/SiteEvents";
import togglerComp from "../components/togglers/togglerComp.vue"
import { buildSaveSiteRequest } from "@/vuex/modules/site";
import { SITE_TYPES_VALUES, DEFAULT_SITE_TYPE } from "@/const/sitetypes";
import { getSitePromise } from "@/vuex/modules/site";

import * as MathLive from "@/mathlib/mathlive.min.mjs";
import VueMathfield from "@/mathlib/vue-mathlive.mjs";
import Vue from "vue";
Vue.use(VueMathfield, MathLive);

export default {
  components: {
    UserForm,
    FtpForm,
    SiteSensors,
    SiteCategories,
    SiteEvents
  },
  props: {
    value: Object,
    action: {
      type: String,
      default: "update",
    },
    editable: { type: Boolean, default: false },
  },
  data() {
    let user = this.$store.state.profile.user;
    this.initSiteEmptyData();
    return {
      duplicateSite: -1,
      user,
      loading: false,
      calcForm: null,
      rules: {
        required: (value) => !!value || "Champ requis",
        image: (value) =>
          !value ||
          value.type === "image/jpeg" ||
          value.type === "image/png" ||
          value.type === "image/gif" ||
          "Fichier de type image (.jpg, .png) requis.",
      },
      availableUsers: [],
      availableClients: [],
      availableInt: [],
      availableIntSensors: [],
      createUserDialogVisible: false,
      editedUser: {},
      formula: null,
      defaultUser: {
        lastname: "",
        firstname: "",
        email: "",
        role: "",
        sites: [],
      },
      menu_installation_date: false,
      clientSites: [],
      SITE_TYPES_VALUES,
      childIdsToName: null,
      availableMesures: [],
      date_formats: {
          DefaultFormat: '',
          Overrides: []
      },
    };
  },
  computed: {
    logo: {
      get: function () {
        if (typeof this.value.logo == "string" || !this.value.logo) {
          return null;
        }
        return this.value.logo;
      },
      set: function (val) {
        if (val instanceof File) this.value.logo_url = URL.createObjectURL(val);
        this.value.logo = val;
        this.$forceUpdate();
      },
    },
    formattedInstallationDate() {
      return this.value.installation_date
        ? this.$moment(this.value.installation_date).format("DD/MM/YYYY")
        : "";
    },
    availableParents() {
      let availableParents = [...this.clientSites].filter((site) => {
        return (
          this.value.id != site.id &&
          (!this.value.relations ||
            !this.value.relations.children ||
            !this.value.relations.children.map((item) => item.id).includes(site.id))
        );
      });
      return availableParents;
    },
    availableChildren() {
      let availableChildren = [...this.clientSites].filter((site) => {
        return (
          this.value.id != site.id &&
          (!this.value.relations ||
            !this.value.relations.parents ||
            !this.value.relations.parents.map((item) => item.id).includes(site.id))
        );
      });
      return availableChildren;
    },
  },
  watch: {
    value: {
      handler() {
        this.$refs.form.resetValidation();
        this.initSiteEmptyData();
      },
      deep: true,
    },
    createUserDialogVisible() {
      if (this.createUserDialogVisible) this.$resetDialogScroll();
    },
  },
  async mounted() {
    this.initSiteEmptyData();
    this.fetchUsers();
    this.fetchClients();
    this.fetchClientSites();
    this.fetchIntConfigs();
    this.childIdsToName = await this.getChildIdsToName();
     console.log("Site Form childIdsToName :" + this.childIdsToName);
    this.availableMesures = await this.getAvailableMesures();

    if(this.value.securityCO2_mesure === null){
      this.value.securityCO2_mesure = [];
    } else {
      this.value.securityCO2_mesure = JSON.parse(this.value.securityCO2_mesure);
    }

    if(this.value.date_formats === null || this.value.date_formats == "null"){
        this.value.date_formats = {
            DefaultFormat: '',
            FileFormat: '',
            Overrides: []
        };
    } else {
        this.value.date_formats = JSON.parse(this.value.date_formats);
    }

    /*if(this.value.securityCO2_mesure !== null){
      this.value.securityCO2_mesure = this.value.securityCO2_mesure.split(',');
    }
    if(this.value.securityCO2_position !== null){
      this.value.securityCO2_position = this.value.securityCO2_position.split(',');
    }
   console.log("Site Form availableMesures :" + this.availableMesures);*/
  },
  updated() {
    // console.log("Site Form sensors :" + this.value.sensors);
    // console.log("Site Form (event):" + this.value.events);
  },
  methods: {
    reader(){
      console.log(this.value.securityCO2_mesures_temp);
    },
    addOverride() {
        this.value.date_formats.Overrides.push({ From: '', To: '', Format: '' });
    },
    removeOverride(index) {
        this.value.date_formats.Overrides.splice(index, 1);
    },
    async getChildIdsToName() {
      let childIdsToName = {};
      if (this.value.relations && this.value.relations.children)
        for (let child of this.value.relations.children) {
          let childSite = await this.getChildSite(child.id);
          childIdsToName[child.id] = childSite.name;
        }
      return childIdsToName;
    },
    async getChildSite(child_id) {
      let childSite;
      let filteredSites = this.clientSites.filter((s) => s.id == child_id);
      if (filteredSites.length == 1) {
        childSite = filteredSites[0];
      } else if (filteredSites == 0 && this.$store.state.profile.user.role == "admin") {
        childSite = await getSitePromise(this.$store, child_id);
      } else {
        console.log("Error : filter give ", filteredSites);
      }
      return childSite;
    },

    async getAvailableMesures() {
      let availableMesures = [];
      if (this.value.sensors) {
        availableMesures = [...this.value.sensors];
        if (this.childIdsToName) {
          for (let child of Object.keys(this.childIdsToName)) {
            let childSite = await this.getChildSite(child);
            availableMesures = [...availableMesures, ...childSite.sensors];
          }
        }
      }
      return availableMesures;
    },
    /**
     * duplicate site configuration !
     */
    changeDuplicateSite() {
      this.value.client_id = this.duplicateSite.client_id;
      this.value.type = this.duplicateSite.type;
      this.value.is_allow_year_compare = this.duplicateSite.is_allow_year_compare;
      this.value.is_allow_input_value = this.duplicateSite.is_allow_input_value;
      this.value.logo = this.duplicateSite.logo;
      this.value.logo_url = this.duplicateSite.logo_url;
      this.value.users = this.duplicateSite.users;
      //this.value.date_formats = this.duplicateSite.date_formats;
      this.value.sensors = [];
      for (let sensor of this.duplicateSite.sensors) {
        let duplicateSensor = {};
        duplicateSensor.name = sensor.name;
        duplicateSensor.csv_column = sensor.csv_column;
        duplicateSensor.unit_of_measure = sensor.unit_of_measure;
        this.value.sensors.push(duplicateSensor);
      }
      this.value.visualizations = [];
      this.value.events = this.duplicateSite.events;
      for (let v of this.duplicateSite.visualizations) {
        let dv = {};
        dv.axes = v.axes;
        dv.name = v.name;

        this.value.visualizations.push(dv);
      }
      if (this.duplicateSite.relations) {
        this.value.relations = Object.assign(this.duplicateSite.relations);
      }
      this.fetchClientSites();
    },
    canModify() {
      return this.user.role == "user_admin" || this.user.role == "admin";
    },

    initSiteEmptyData() {
      let user = this.$store.state.profile.user;
      if (!this.value.relations) {
        this.value.relations = {};
      }
      if (!this.value.relations.children || this.value.relations.children == null) {
        this.value.relations.children = [];
      }
      if (!this.value.relations.parents || this.value.relations.parents == null) {
        this.value.relations.parents = [];
      }
      if (!this.value.users || this.value.users == null || this.value.users.length == 0) {
        this.value.users = [user];
      }
      // if (!this.value.sensors || this.value.sensors == null)
      //   this.value.sensors = [];
      if (!this.value.type || this.value.type == null || this.value.type == "") {
        this.value.type = DEFAULT_SITE_TYPE;
      }
      // if (!this.value.events) {
      //   this.value.events = []
      // }
    },
    showErr(err) {
      this.$store.commit("setSnack", {
        type: "red",
        err,
      });
    },
    saveSitePromise(site) {
      return new Promise((resolve, reject) => {
        let build = buildSaveSiteRequest(site, this.action !== "update");
        console.log("save", this.value);
        this.$store
          .dispatch(build.request, build.queries)
          .then((site) => {
            this.$store.commit("setSnack", {
              type: "success",
              msg: "Univers enregistré !",
            });
            resolve(site);
          })
          .catch((err) => {
            this.saveErr(err);
          });
      });
      //TODO
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        this.saveSitePromise(this.value)
          .then((site) => {
            this.$refs.form.resetValidation();
            this.loading = false;
            this.$emit("savedForm", site);
          })
          .catch((err) => {
            this.saveErr(err);
          });
      }
    },
    saveErr(err) {
      this.loading = false;
      let msg = err;
      if (err.response && err.response.status != 403) msg = err.response.data;
      this.showErr(msg);
    },

    cancel() {
      this.$refs.form.resetValidation();
      this.$emit("canceledForm");
    },
    edit() {
      this.$emit("editForm");
    },
    deleteLogo() {
      this.value.logo_url = null;
      this.value.logo = null;
      this.value.remove_logo = true;
      this.$forceUpdate();
    },
    fetchUsers() {
      if (this.user.role == "admin") {
        this.$store
          .dispatch("usersRequest", {})
          .then((result) => {
            this.availableUsers = result.data;
          })
          .catch((err) => this.showErr(err));
      } else {
        this.availableUsers = [...this.user.users];
        this.availableUsers.push(this.user);
        if (this.value && this.value.users) {
          for (let user of this.value.users) {
            this.availableUsers.push(user);
          }
        }
        return;
      }
    },
    fetchClients() {
      if (this.user.role == "admin") {
        this.$store
          .dispatch("clientsRequest", {})
          .then((result) => {
            this.availableClients = result.data;
          })
          .catch((err) => this.showErr(err));
      } else {
        this.availableClients = [...this.user.clients];
        return;
      }
    },
    fetchClientSites() {
      if (this.user.role == "admin") {
        this.$store
          .dispatch("sitesRequest", {})
          .then((result) => {
            this.clientSites = result.data.filter((site) => {
              return this.value.client_id == site.client_id;
            });
          })
          .catch((err) => this.showErr(err));
      } else {
        this.clientSites = this.user.sites.filter((site) => {
          return this.value.client_id == site.client_id;
        });
      }
    },

    createUser() {
      this.editedUser = Object.assign({}, this.defaultUser);
      this.createUserDialogVisible = true;
    },
    handleSavedUserForm(user) {
      //   this.value.users.push(user);
      this.fetchUsers();
      this.createUserDialogVisible = false;
    },
    handleCanceledUserForm() {
      this.createUserDialogVisible = false;
    },
    // handleSavedVisitForm(visit) {
    //   this.value.visits.push(this.visitCreated);
    //   this.visitModal = false;
    //   this.visitCreated = {};
    // },
    handleAddedSensorForm(sensor) {
      if (!this.value.sensors) {
        this.value.sensors = [];
      }
      //   this.value.sensors = [...this.value.sensors];
      this.value.sensors.push(sensor);
      this.$forceUpdate();
    },
    handleDeletedSensor(sensor) {
      this.value.sensors = this.value.sensors.filter((row) => row != sensor);
      this.$forceUpdate();
    },
    handleAddEvent(event) {
      this.value.events.names.push(event);
    },

    handleDeleteEvent(event) {
      this.value.events.names = this.value.events.names.filter((row) => row != event);
    },

    handleAddCategory(category) {
      this.value.categories.names.push(category);
    },

    handleDeleteCategory(category) {
      this.value.categories.names = this.value.categories.names.filter(
        (row) => row != category
      );
    },
    fetchIntConfigs() {
      let i = 0;
      this.$store.dispatch('integrationsRequest', {})
          .then((result) => {
            this.availableInt = result;

            this.availableIntSensors = [];

            this.availableInt.filter((int) => int.id == this.value.integration.id)[0].integration_confs.forEach((intConf) => {
              intConf.integration_sensors.forEach((sensor) => {
                this.availableIntSensors.push(sensor);
              });
            });

            this.availableIntSensors = this.availableIntSensors.filter((intSensor) => ((intSensor.client_id === this.value.client_id && intSensor.client_id != null) || intSensor.is_allowed_for_demo == true))
          })
          .catch((err) => {
          });
    },
    recheckAvailableSensors(){
      this.availableIntSensors = [];

      this.value.integration.integration_confs.forEach((intConf) => {
          intConf.integration_sensors.forEach((sensor) => {
            this.availableIntSensors.push(sensor);
          });
      });

      this.availableIntSensors = this.availableIntSensors.filter((intSensor) => ((intSensor.client_id === this.value.client_id && intSensor.client_id != null) || intSensor.is_allowed_for_demo == true))
    },
    autoFillData(){
      if(this.value.integration){
        this.value.sensors = JSON.parse(this.value.integration.csv_data);
      }
    },
    autoFillFTP(){
      if(this.value.integration_sensor){
        this.value.ftp_host = "172.20.6.12";
        this.value.ftp_user = "mtp";
        this.value.ftp_pwd = "WelcomeToWinery";
        this.value.ftp_path = "/" + this.value.integration.name + "/" + this.value.integration_sensor.name + "/";

        this.autoFillData();
      }
    },
  },
};
</script>
