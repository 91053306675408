<template>
  <div class="home">
    <Navigation />
    <v-main>
      <v-card fluid fill-height :loading="loading">
        <v-layout v-if="this.$store.getters.getProfile.homeType === 'S1'">
          <template v-if="!$isMobile()">
            <v-col cols="12" :md="6">
              <UserAdminSites @ready="userSitesReady" />
            </v-col>
            <v-col cols="12" :md="6" v-if="site != null && visualization != null" style="position: relative;">
              <v-container style="position: sticky; top: 25vh; left: 0; width: 100%;">
                <h2 class="text-center">{{ visualization.name }}</h2>
                <Visualization
                    :site="site"
                    :visualization="visualization"
                    :query="query"
                    :yearCompareQuery="null"
                    :reload="true"
                    :childIdsToName="childIdsToName"
                    v-bind:key="delayedReset"
                ></Visualization>
              </v-container>
            </v-col>
          </template>
          <template v-if="$isMobile()">
            <v-col cols="24" :md="6">
              <UserAdminSites @ready="userSitesReady" />
            </v-col>
          </template>
        </v-layout>
        <v-layout v-if="this.$store.getters.getProfile.homeType === 'S2'">
            {{ this.$router.push({ path: '/degustations' }) }}
        </v-layout>
        <template v-if="this.$store.getters.getProfile.homeType === 'S3'">
          <SecurityCO2/>
        </template>
      </v-card>
    </v-main>
  </div>
</template>
<script>
import Navigation from "../components/Navigation.vue";
import UserAdminSites from "../components/UserAdminSites.vue";
import Map from "../components/Map.vue";
import Visualization from "@/components/Visualization";
import SecurityCO2 from "@/components/SecurityCO2";
import {getSitePromise, getUserSite} from "@/vuex/modules/site";
import {EventBus} from "@/event-bus";
import {generateArrayOfYears} from "@/utils/dates";
import Vue from "vue";
import Degustation from "./Degustation.vue";
import snackbar from "../components/Snackbar";

const YEARS = generateArrayOfYears();
const YEAR_COMPARE_QUERY_DEFAULT = {
  from: "2010-01-01",
  to: "2010-12-31",
  years: ["2010", "2011"],
  interval: "month",
};

Vue.use(require('vue-moment'));

const TIMERANGE_DEFAULT = [0, 48];
const QUERY_DEFAULT = {
  from: "2022-01-01",
  to: Vue.moment().format("YYYY-MM-DD"),
  timerange: TIMERANGE_DEFAULT,
  interval: "month",
  yearCompareQuery: YEAR_COMPARE_QUERY_DEFAULT,
};

export default {
  components: {
    UserAdminSites,
    Navigation,
    Visualization,
    SecurityCO2,
  },
  data() {
    return {
      profile: this.$store.state.profile.user,
      loading: true,
      site: null,
      query: QUERY_DEFAULT,
      visualization: null,
      childIdsToName: null,
      clientSites: null,
      delayedReset: 0,
      styleIfTrue: {
        display: 'none'
      },
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm
    }
  },
  mounted() {
    this.$nextTick(function () {
      //return nothing
    });
  },
  methods: {
    async userSitesReady(siteID) {
      EventBus.$on('applySiteID', this.applySiteID);
      await this.applySiteID(siteID);
      this.loading = false;
    },
    async getChildIdsToName() {
      let childIdsToName = {};
      if (this.site.relations && this.site.relations.children)
        for (let child of this.site.relations.children) {
          let childSite = await this.getChildSite(child.id);
          childIdsToName[child.id] = childSite.name;
        }
      return childIdsToName;
    },
    async getChildSite(child_id) {
      let childSite;
      let filteredSites = this.clientSites.filter((s) => s.id == child_id);
      if (filteredSites.length == 1) {
        childSite = filteredSites[0];
      } else if (
          filteredSites == 0 &&
          this.$store.state.profile.user.role == "admin"
      ) {
        childSite = await getSitePromise(this.$store, child_id);
      } else {
        console.log("Error : filter give ", filteredSites);
      }
      return childSite;
    },
    fetchClientSites(client_id, callback) {
      this.updateUser(() => {
        this.clientSites = this.getClientSites();
        this.ready = true;
        if (callback) {
          callback();
        }
      });
    },
    getClientSites(client_id) {
      return this.$store.state.profile.user.sites.filter((site) => {
        return site.client_id == client_id;
      });
    },
    async applySiteID (siteID) {
      if (this.$isMobile() === false) {
        let user = this.$store.state.profile.user;
        this.site = getUserSite(user, siteID);
        this.visualization = this.site.visualizations[0];
        //this.query = this.$store.state.profile.user.visualization[this.site.id];
        this.childIdsToName = await this.getChildIdsToName();

        let viz = this.$store.state.profile.user.visualizations;
        if (viz && viz[this.site.id] && viz[this.site.id].from) {
          this.query = Object.assign(viz[this.site.id]);
          if (!this.query.yearCompareQuery) {
            this.query.yearCompareQuery = Object.assign(YEAR_COMPARE_QUERY_DEFAULT);
          }
        }
        if (!this.query.timerange || !Array.isArray(this.query.timerange)) {
          this.query.timerange = TIMERANGE_DEFAULT;
        }

        this.delayedReset += 1;
      }
    },
  },
};

</script>
