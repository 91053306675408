<template>
  <div class="sites">
    <Navigation />
    <v-main>
      <v-container fluid fill-height>
        <v-layout v-resize="onResize">
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-row class="pb-4 text-center" align="center">
                  <v-col cols="12" md="auto">
                    <h2 class="ml-2 headline">Liste des univers</h2>
                  </v-col>

                  <v-spacer></v-spacer>

                  <v-col cols="12" md="auto">
                    <v-text-field
                      v-model="search"
                      clearable
                      hide-details
                      solo-inverted
                      flat
                      prepend-inner-icon="search"
                      label="Rechercher"
                      @input="refreshPagination"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>

                  <v-col cols="12" md="auto">
                    <!-- CREATE/EDIT/SHOW DIALOG -->
                    <v-dialog
                      v-model="dialogShow"
                      max-width="1400px"
                      persistent
                      scrollable
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          color="primary"
                          v-on="on"
                          @click.stop="createItem"
                          >Nouvel univers</v-btn
                        >
                      </template>
                      <SiteForm
                        :editable="dialogEdit"
                        ref="SiteForm"
                        v-model="site"
                        :action="action"
                        :key="resettedKey"
                        @savedForm="handleSavedSiteForm"
                        @canceledForm="handleCanceledSiteForm"
                        @editForm="editItem"
                      />
                    </v-dialog>
                  </v-col>
                </v-row>

                <v-data-table
                  :items="sites"
                  :options.sync="pagination"
                  :items-per-page="rowsPerPageItems"
                  :server-items-length="total"
                  :loading="loading"
                  :headers="headers"
                  :class="{ mobile: isMobile }"
                  :footer-props="{
                    itemsPerPageText: 'Ligne par page',
                  }"
                >
                  <template v-slot:item="props">
                    <tr @click.stop="showItem(props.item)">
                      <td data-label="Client">{{ props.item.client.name }}</td>
                      <td data-label="Nom">{{ props.item.name }}</td>
                      <td data-label="Utilisateurs">
                        <v-chip
                          v-for="user in props.item.users"
                          :key="user.id"
                          class="mr-1 mb-1"
                          color="primary"
                          >{{ user.complete_name }}</v-chip
                        >
                      </td>
                      <td class="text-right">
                        <v-icon
                          small
                          class="mr-2"
                          @click.stop="showItem(props.item)"
                          >remove_red_eye</v-icon
                        >
                        <v-icon
                          small
                          class="mr-2"
                          @click.stop="editItem(props.item)"
                          >edit</v-icon
                        >
                        <v-icon small @click.stop="deleteItem(props.item)"
                          >delete</v-icon
                        >
                      </td>
                    </tr>
                  </template>
                  <template v-slot:no-data> Aucun site </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SiteForm from "../components/SiteForm";
import { SITE_DEFAULT } from "@/const/sitetypes";

export default {
  components: {
    SiteForm
  },
  data() {
    return {
      ready: false,
      sites: [],
      allSites: undefined,
      action: "create",
      site: {},
      defaultSite: {
        client_id: null,
        name: "",
        description: "",
        lagoon_volume: "",
        logo: null,
        logo_url: "",
        sensors: [],
        blower_model: "",
        maintenance: 0,
        comment: "",
        installation_date: "",
        visits: [],
        dco_min: "",
        dco_max: "",
        dbo_min: "",
        dbo_max: "",
        mes_min: "",
        mes_max: "",
        ftp_host: "",
        ftp_user: "",
        ftp_pwd: "",
        ftp_path: "",
      },
      pagination: {
        page: 1,
      },
      search: "",
      total: 0,
      rowsPerPageItems: 10,
      resettedKey: 0,
      headers: [
        {
          text: "Client",
          align: "left",
          value: "clients.name",
        },
        {
          text: "Nom",
          align: "left",
          value: "name",
        },
        {
          text: "Utilisateurs",
          align: "left",
          value: "user",
          sortable: false,
        },
        {
          text: "",
          value: "",
          sortable: false,
        },
      ],
      dialogEdit: false,
      dialogShow: false,
      isMobile: false,
      loading: false,
    };
  },
  mounted() {
    //
  },
  methods: {
    onResize() {
      this.isMobile = this.$vuetify.breakpoint.smAndDown;
    },
    getSites(queries) {
      if (!this.allSites) {
        this.$store
          .dispatch("sitesRequest", queries)
          .then((result) => {
            this.allSites = result.data;
            this.total = this.allSites.length;
            this.sites = result.data;
            const start =
              (this.pagination.page - 1) * this.pagination.itemsPerPage;
            let end = Math.min(
              this.sites.length,
              this.pagination.page * this.pagination.itemsPerPage
            );
            if (this.pagination.itemsPerPage == -1) {
              end = this.sites.length + 1;
            }
            this.sites = this.sites.slice(start, end);

            this.loading = false;
            if (!this.ready)
              this.$checkHistory("site", (site) => this.showItem(site));
            this.ready = true;
          })
          .catch(() => {
            //
          });
      } else {
        if (this.search) {
          this.sites = this.allSites.filter((site) =>
            site.complete_name.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '').includes(this.search.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, ''))
          );
        } else {
          this.sites = this.allSites;
        }
        this.total = this.sites.length;
        const start = (this.pagination.page - 1) * this.pagination.itemsPerPage;
        let end = Math.min(
          this.sites.length,
          this.pagination.page * this.pagination.itemsPerPage
        );
        if (this.pagination.itemsPerPage == -1) {
          end = this.sites.length + 1;
        }
        this.sites = this.sites.slice(start, end);

        this.loading = false;
        if (!this.ready)
          this.$checkHistory("site", (site) => this.showItem(site));
        this.ready = true;
      }
    },
    handleSavedSiteForm(site) {
      this.refreshPagination();
      this.dialogShow = false;
      this.dialogEdit = false;
    },
    handleCanceledSiteForm() {
      this.dialogShow = false;
      this.dialogEdit = false;
    },

    editItem(site) {
      this.action = "update";
      //   this.site = Object.assign({}, site);
      if (site) this.site = site;
      this.dialogEdit = true;
      this.dialogShow = true;
    },
    showItem(site) {
      this.action = "update";
      this.site = site;
      this.dialogEdit = false;
      this.dialogShow = true;
    },
    createItem() {
      this.action = "create";
      this.site = Object.assign({}, SITE_DEFAULT);
      this.dialogEdit = true;
      this.dialogShow = true;
      this.resettedKey = this.resettedKey + 1;
      this.$resetDialogScroll();
    },
    deleteItem(site) {
      if (confirm("Êtes-vous sûr de vouloir supprimer ce site ?")) {
        this.$store
          .dispatch("siteDeleteRequest", { id: site.id })
          .then((result) => {
            this.$store.commit("setSnack", {
              type: "success",
              msg: "Site supprimé !",
            });
            this.refreshPagination();
          })
          .catch((err) => {
            this.$store.commit("setSnack", {
              type: "red",
              msg: err.response.data,
            });
          });
      }
    },
    refreshPagination() {
      const queries = {
        // page: this.pagination.page,
        // per_page: this.pagination.itemsPerPage,
        sort: this.pagination.sortBy,
        direction: this.pagination.sortDesc[0] ? "desc" : "asc",
        // search: this.search,
      };

      this.loading = true;
      this.getSites(queries);
    },
  },
  watch: {
    pagination: {
      handler() {
        this.refreshPagination();
      },
      deep: true,
    },
    search() {
      this.pagination.page = 1;
    },
    dialogShow() {
      if (this.dialogShow) this.$clicked_site = this.shownSite;
      else this.$clicked_site = null;

      if (this.dialogShow) this.$resetDialogScroll();
    },
    dialogEdit() {
      if (this.dialogEdit) this.$resetDialogScroll();
    },
  },
};
</script>
