<template>
  <div>
    <v-row>
    <v-col cols="6">
      <span class="subtitle black--text font-size-20">Catégories</span>
      <v-text-field v-model="newCategory" required label="Nouvelle catégorie" ref="catégorie"></v-text-field>
      <v-col cols="6" class="d-flex flex-column align-start pl-0">
      <v-btn v-if="editable" small depressed @click.stop="addCategory"
        ><v-icon left>check</v-icon>Valider la catégorie</v-btn
      >
    </v-col>
      <v-row
        v-if="
          categories &&
          categories.length > 0
        "
      >
        <v-col>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <p>Liste des catégories :</p>
                <tr v-for="(category, i) in categories" :key="i">
                  <td>
                    {{ category }}
                  </td>
                  <td class="text-right" v-if="editable">
                    <v-icon @click.stop="deleteCategory(category)"
                      >close</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-col>
    <!-- <v-col cols="6" class="d-flex flex-column align-center pt-14">
      <v-btn v-if="editable" small depressed @click.stop="addCategory"
        ><v-icon left>add</v-icon>Valider la catégorie</v-btn
      >
    </v-col> -->
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    categories: Array,
    editable: { type: Boolean, default: false },
  },
  data() {
    return {
      newCategory: "",
    }
  },
  methods: {
    deleteCategory(category) {
      if (confirm("Êtes-vous sûr de vouloir supprimer cette catégorie ?")) {
        this.$emit("delete",category);
      }
    },
    addCategory() {
      if (this.newCategory.length > 0 ) {
        this.$emit("add",this.newCategory);
        this.newCategory="";
      }
      else {
        window.alert("La catégorie doit être remplie");
      }
    },
  }
}

</script>
