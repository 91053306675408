import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth'
import snackbar from './modules/snackbar'
import profile from './modules/profile'
import user from './modules/user'
import password from './modules/password'
import site from './modules/site'
import client from './modules/client'
import ftp from './modules/ftp'
import sensorType from './modules/sensorType'
import visualization from './modules/visualization'
import elastic from './modules/elastic'
import integrationsConf from "@/vuex/modules/integrationsConf";
import degustation from "@/vuex/modules/degustation";
import degustLink from "@/vuex/modules/degustLink";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        snackbar,
        profile,
        user,
        password,
        site,
        client,
        ftp,
        sensorType,
        visualization,
        elastic,
        integrationsConf,
        degustation,
        degustLink
    }
});