<template>
  <v-flex xs12 sm6 offset-sm3>
    <v-card class="margin-top">
      <v-card-text>
        <v-list-item>
          <v-list-item-avatar color="white" rounded>
            <img alt="" src="@/assets/logosvg.svg">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">Login</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-form
          ref="form"
          @keyup.native.enter="login"
        >
          <v-text-field
            v-model="email"
            label="E-mail"
            :rules="[rules.required]"
          ></v-text-field>

          <v-text-field
            v-model="password"
            label="Password"
            :rules="[rules.required]"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>

          <v-switch
            v-model="tos"
            :rules="[rules.required]"
            label=""
            flat
            inset
          >
            <template v-slot:label>
              <div>
                <span>Je reconnais avoir lu et compris les </span>
                <a @click.stop="showTos">CGU</a>
                <span> et je les accepte</span>
                <v-dialog v-model="tosModal" scrollable>
                  <Tos @close="tosModal=false"/>
                </v-dialog>
              </div>
            </template>
          </v-switch>

          <v-btn
            block
            depressed
            color="primary"
            @click="login"
            :disabled="!tos"
            :loading="loading"
          >Login</v-btn>

        </v-form>

        <v-btn
          block
          text
          small
          color="secondary"
          :to="{ name: 'password-reset' }"
          class="mt-4"
        >Mot de passe oublié ?</v-btn>

      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
  import Tos from '@/components/Tos'
  export default {
    components: {
        Tos
    },
    data(){
        return {
            email: '',
            password: '',
            showPassword: false,
            loading: false,
            tos: false,
            tosModal: false,
            rules: {
                required: value => !!value || 'Champ requis',
            },
        }
    },
    methods: {
      showTos (e){
        e.preventDefault()
        this.tosModal = true
        return false
      },
      login () {
        if (this.$refs.form.validate()) {
          this.loading = true
          const { email, password } = this;
          this.$store.dispatch('authRequest', { email, password })
              .then(() => {
                  this.loading = false
                  this.$router.push('/home')
              })
              .catch((err) => {
                  this.loading = false;
              })
          }
      }
    }
  }
</script>
<style scoped>
.margin-top{
   top: 50%;
}
</style>
