import Vue from 'vue'
import Errors from './../../errors';

const state = {
    access_token: localStorage.getItem('access_token') || '',
    status: '',
    hasLoadedOnce: false,
    errors: new Errors()
}

const getters = {
    isAuthenticated: state => !!state.access_token,
    authStatus: state => state.status,
    authErrors: state => state.errors,
}

const actions = {
    authRequest: ({commit, dispatch}, payload) => {
        let actionUrl = '/oauth/token';
        let data = {
            'username': payload.email,
            'password': payload.password,
            'grant_type': 'password',
            'client_id': process.env.VUE_APP_API_CLIENT_ID,
            'client_secret': process.env.VUE_APP_API_CLIENT_SECRET
        }
        if (payload.action == 'password-reset') {
            actionUrl = '/password/reset';
            data = {
                'token': payload.token,
                'email': payload.email,
                'password': payload.password,
                'password_confirmation': payload.password_confirmation
            }
        }
        return new Promise((resolve, reject) => {
            commit('authRequest')
            Vue.axios.post(actionUrl, data)
                .then(async (resp) => {
                    let access_token = 'Bearer ' + resp.data.access_token
                    localStorage.setItem('access_token',access_token)
                    Vue.axios.defaults.headers.common['Authorization'] = access_token

                    commit('authSuccess', access_token)
                    await dispatch('profileRequest')
                    commit('setSnack', {'type': 'success', 'msg': 'Bienvenue !'})
                    resolve(access_token)
                })
                .catch((err) => {
                    console.log(err)
                    commit('authError', err.response.data)
                    localStorage.removeItem('access_token')
                    commit('setSnack', {'type': 'error', 'msg': 'Identifiants incorrects'})
                    reject(err)
                })
        })
    },
    authLogout: ({commit}) => {
        return new Promise((resolve, reject) => {
            Vue.axios.post('api/logout')
                .then(() => {
                    localStorage.removeItem('access_token');
                    commit('authLogout')
                    commit('profileRemove')
                    commit("setSnack", {
                      type: "#972B34",
                      msg: "Vous avez été déconnecté",
                    });
                    resolve()
                })
                .catch((err) => {
                    commit('authError', err.response.data)
                    reject(err)
                });
        })
    }
}

const mutations = {
    authRequest: (state) => {
        state.status = 'loading';
    },
    authSuccess: (state, access_token) => {
        state.status = 'success'
        state.access_token = access_token
        state.hasLoadedOnce = true
    },
    authError: (state, err) => {
        let errors = err.errors ? err.errors : {}
        if(err.error == "invalid_credentials") {
            errors.invalid_credentials = ['The user credentials were incorrect.']
        }
        state.status = 'error'
        state.hasLoadedOnce = true
        state.errors.record(errors)
    },
    authLogout: (state) => {
        state.access_token = ''
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
