<template>
  <ul>
    <li v-for="child in node.relations.children" :key="getKey(child)" class="li-site" style="padding-left: 65px;">
      <TreeNode
        v-if="getNodeData(child.id) && hasChildren(getNodeData(child.id))"
        :node="getNodeData(child.id)"
        :datas="datas"
        @editItem="editItem"
        @showItem="showItem"
        @deleteItem="deleteItem"
      />
      <template v-else>
        <SiteRow
          v-if="getNodeData(child.id)"
          :value="getNodeData(child.id)"
          @editItem="editItem"
          @showItem="showItem"
          @deleteItem="deleteItem"
      /></template>
    </li>
  </ul>
</template>
<style scoped>
.li-site {
  list-style-type: none;
}
</style>
<script>
export default {
  components: {
    TreeNode: () => import("../components/TreeNode"),
    SiteRow: () => import("../components/SiteRow"),
  },
  props: {
    node: Object,
    datas: Array,
  },
  mounted() {},
  methods: {
    getNodeData(id) {
      let nodeData;
      for (let data of this.datas) {
        if (data.id == id) {
          nodeData = data;
          break;
        }
      }
      if (!nodeData) {
        console.log("Error : site id not found ", id);
      }
      return nodeData;
    },
    hasChildren(node) {
      return (
        node.relations &&
        node.relations.children &&
        node.relations.children != null &&
        node.relations.children.length > 0
      );
    },
    editItem(site) {
      this.$emit("editItem", site);
    },
    showItem(site) {
      this.$emit("showItem", site);
    },
    deleteItem(site) {
      this.$emit("deleteItem", site);
    },
    getKey(child) {
      return this.node.id + "_" + child.id;
    },
  },
};
</script>
