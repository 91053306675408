<template>
  <div>
    <v-row>
      <v-dialog v-model="sensorModal" max-width="360px">
        <v-card v-if="editable">
          <v-form v-on:submit.prevent="handleSavedSensorForm" ref="form">
            <v-card-title>
              <span class="headline">Ajouter une mesure</span>
            </v-card-title>
            <v-card-text v-if="ready">
              <v-select
                :items="sensorTypes"
                label="Type de mesure"
                item-text="name"
                item-value="id"
                return-object
                clearable
                v-model="sensorCreated.type"
                @change="changeType"
              ></v-select>

              <v-text-field
                v-model="sensorCreated.name"
                label="Intitulé"
              ></v-text-field>

              <v-text-field
                v-model="sensorCreated.csv_column"
                label="Colonne CSV"
              ></v-text-field>

              <v-text-field
                v-model="sensorCreated.unit_of_measure"
                label="Unité de mesure"
              ></v-text-field>

              <v-text-field
                v-model="sensorCreated.min"
                label="Valeur minimale pour alerte"
              ></v-text-field>

              <v-text-field
                v-model="sensorCreated.max"
                label="Valeur maximale pour alerte"
              ></v-text-field>

              <v-text-field
                  v-model="sensorCreated.calcForm"
                  label="Formule de calcul pour cette mesure"
              ></v-text-field>
            </v-card-text>
            <v-card-text v-else class="text-center">
              <v-progress-circular indeterminate />
            </v-card-text>
            <v-card-actions>
              <v-btn depressed @click="cancel">Annuler</v-btn>
              <v-spacer></v-spacer>
              <v-btn depressed color="primary" @click="handleSavedSensorForm"
                >Ajouter</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
    <v-spacer></v-spacer>
    <v-btn class="mt-6" v-if="editable" small depressed @click.stop="sensorModal = true">
      <v-icon left>add</v-icon> ajouter une mesure
    </v-btn>
    <v-simple-table v-if="sensors && sensors.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Intitulé</th>
            <th>Colonne CSV</th>
            <th>Unité</th>
            <th>Min</th>
            <th>Max</th>
            <th>Formule de calcul</th>
            <th v-if="editable"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(sensor, i) in sensors" :key="i">
            <td>
              <v-text-field
                v-if="editable"
                class="my-2"
                hide-details
                dense
                outlined
                v-model="sensor.name"
              ></v-text-field>
              <template v-else>
                {{ sensor.name }}
              </template>
            </td>
            <td>
              <v-text-field
                v-if="editable"
                class="my-2"
                hide-details
                dense
                outlined
                v-model="sensor.csv_column"
              ></v-text-field>
              <template v-else>
                {{ sensor.csv_column }}
              </template>
            </td>
            <td>
              <v-text-field
                v-if="editable"
                class="my-2"
                hide-details
                dense
                outlined
                v-model="sensor.unit_of_measure"
              ></v-text-field>
              <template v-else>
                {{ sensor.unit_of_measure }}
              </template>
            </td>
            <td>
              <v-text-field
                v-if="editable"
                class="my-2"
                hide-details
                dense
                outlined
                v-model="sensor.min"
              ></v-text-field>
              <template v-else>
                {{ sensor.min }}
              </template>
            </td>
            <td>
              <v-text-field
                v-if="editable"
                class="my-2"
                hide-details
                dense
                outlined
                v-model="sensor.max"
              ></v-text-field>
              <template v-else>
                {{ sensor.max }}
              </template>
            </td>
            <td>
              <v-text-field
                  v-if="editable"
                  class="my-2"
                  hide-details
                  dense
                  outlined
                  v-model="sensor.calcForm"
              ></v-text-field>
              <template v-else>
                {{ sensor.calcForm }}
              </template>
            </td>
            <td v-if="editable" class="text-right">
              <v-icon @click.stop="deleteSensor(sensor)">close</v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
export default {
  props: {
    sensors: { type: Array, default: () => [] },
    editable: { type: Boolean, default: true },
  },
  data() {
    return {
      ready: false,
      sensorTypes: [],
      sensorModal: false,
      sensorCreated: {},
    };
  },
  mounted() {
    this.getSensorTypes().then((result) => {
      this.sensorTypes = result.data;
      this.ready = true;
    });
  },
  beforeUpdate() {
    // console.log("BeforeUpdate Site sensors :" + this.sensors);
  },
  updated() {
    // console.log("Site sensors :" + this.sensors);
  },
  methods: {
    changeType(sensorType) {
      this.sensorCreated.name = sensorType ? sensorType.name : "";
      this.sensorCreated.csv_column = sensorType ? sensorType.csv_column : "";
      this.sensorCreated.unit_of_measure = sensorType
        ? sensorType.unit_of_measure
        : "";
      this.sensorCreated.min = sensorType ? sensorType.min : "";
      this.sensorCreated.max = sensorType ? sensorType.max : "";
      this.sensorCreated.calcForm = sensorType ? sensorType.calcForm : "";
    },
    handleSavedSensorForm() {
      let newSensor = Object.assign({}, this.sensorCreated);
      //update current component
      //this.sensors.push(newSensor)
      //update parent component
      this.$emit("added", newSensor);
      this.sensorModal = false;
      this.$refs.form.reset();
    },
    cancel() {
      this.$refs.form.reset();
      this.sensorModal = false;
    },
    getSensorTypes() {
      return this.$store.dispatch("sensorTypesRequest", {});
    },
    deleteSensor(sensor) {
      if (confirm("Êtes-vous sûr de vouloir supprimer ce capteur ?")) {
        this.$emit("deleted", sensor);
      }
    },
  },
};
</script>
